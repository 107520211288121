.form-group {
    margin-bottom: 1rem;
  }
  .form-control{
      display: block;
      width: 100%;
      height: 40px;
      padding: .375rem .75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .error{
      border:1px solid red;
  }
  .errorText{
    //display: none;
    width: 100%;
    margin-top: .25rem;
    font-size:14px;
    color: red;
  }
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 40px;
    margin-bottom: 0;
}
.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 40px;
    margin: 0;
    opacity: 0;
}
.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}
.custom-file-input.error + label{
    border:1px solid red;
}
.custom-file-input.error + label:after{
    background:red;
    color:white;
}
.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}
  

  .applyForm{
      display: none;
      @include respond-to(small){
          margin-left:23px;
      }
  }
  .apply-btn{
      margin-bottom: 25px;
  }
  .submit-btn{
      background: $blue;
      padding:11px 20px;
      color:white;
      border:none;
      margin-top: 20px;
      @include border-radius(5px);
      &:hover{
          background:$dark-blue;
      }
  }
  //alert
  .alert-primary {
    color:white;
    background-color:$blue;
    border-color: #b8daff;
}
.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
//loader
.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    position: relative;
    top:7px;
    left:15px;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    -ms-webkit-animation: spinner-border .75s linear infinite;
    -moz-webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
@keyframes spinner-border {
    to { transform: rotate(360deg); }
  }
//process styling
.process-banner {
	padding: 100px 0;
	background-image: url('../images/process-fallback.jpg');
	@include background;
	position: relative;
	@include respond-to(large){
		background-image: inherit;
	}
	@include respond-to(small){
		height: 100vh;
	}
	@include respond-to(large){
		padding:0;
	}
	&:before{
		@include pseudo;
		top:0;
		left:0;
		right:0;
		bottom: 0;
		background:rgba(black,0.8);
		display:block;
		@include respond-to(large){
			display: none;
		}
	}
	&:after {
		
		@include respond-to(large){
@include pseudo;
		background-color: rgba(black, 0.6);
		width: 100%;
		height: 100%;
		top: 0;
		}
	}
	p {
		@include rhythm(20, 30);
	}

	.container {
		@include respond-to(small){
			padding-top: 0;
		padding-bottom: 50px;
		height: auto;
			position: absolute;
		z-index: 1;
		top: 50%;
		left: 0;
		right: 0;
		@include transform(translateY(-50%));
		}
		@include respond-to(x-large) {
			max-width: 1300px;
			padding-bottom: 0;

		}
	}

	.block-media {
		position: relative;
		-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
		display:none;
		@include respond-to(large){
			display: block;
		}
		&:before {
			content: "";
			background: rgba($dark-blue, 0.5);
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}

		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
			-webkit-filter: grayscale(70%);
			filter: grayscale(70%);
		}
	}

	.overlay-image-wrap {
		position: relative;

		@include respond-to(medium) {
			padding-right: 100px;
		}

		@include respond-to(x-large) {
			height: 700px;
			padding-right: 150px;
		}

		h2 {
			@include rhythm(70, 100);
			margin-left: auto;
			margin-right: auto;
			color: $orange;
			text-transform: uppercase;
			margin-bottom: 0;
			@include font-weight(bold);
			position: absolute;
			top: 0;
			bottom: 0;
			opacity: 0.2;
			text-align: right;
			display: none;

			@include respond-to(medium) {
				display: block;
			}

			@include transform(rotate(90deg));

			@include respond-to(x-large) {
				@include rhythm(100, 110);
				padding-top: 35px;
				right: 0;
			}
		}
	}

	.overlay-image {
		background: $dark-blue;
		position: relative;
		height: 100%;

		@include border-radius(5px);

		@include respond-to(medium) {
			@include box-shadow(50px 10px 70px 10px rgba(black, 0.2));
		}

		img {
			max-width: 100%;
			@include border-radius(5px);
		}
	}

	.process-img {
		position: relative;
	}
}

.b-hero-inner {
	&.process-banner {
		h2 {
			color: white;

		}

		p {
			color: rgba(white, 0.7);
		}
	}
}

//expert section
.expert-section {
	padding: 100px 0;

	h2 {
		@include rhythm(30, 35);
		color: $dark-blue;
		@include font-weight(book);
		margin-bottom: 20px;

		@include respond-to(small) {
			@include rhythm(50, 60);
			@include font-weight(medium);
		}

		span {
			@include respond-to(small) {
				opacity: 0.2;
			}
		}
	}

	p {
		@include rhythm(20, 34);
	}
}

//tools
.web-tools {
	padding: 50px 0;
	position: relative;

	@include respond-to(small) {
		padding: 100px 0 100px 0;
	}

	.cf-tools-tagline {
		margin-bottom: 120px;
		text-align: center;

		h2 {
			@include rhythm(40, 50);
			color: white;
			@include font-weight(regular);
			margin: 0px 0 20px 0;
			display: block;
			@include transition(all 0.5s ease-in-out);

			@include respond-to(medium) {
				@include rhythm(60, 70);
			}
		}

		p {
			color: rgba(white, 0.7);
		}

	}

	&.reveal-block {
		&::before {
			background: $dark-blue;
		}
	}

	.small {
		@include rhythm($regular, 20);
	}

	.octa-icon {
		width: 100px;
		height: 110px;
		display: inline-block;
		position: absolute;
		display: none;

		@include respond-to(small) {
			display: block;
		}

		svg {
			width: 80px;
			height: 70px;
			;
			margin: 0 auto;

			#octa,
			#ocata1 {
				opacity: 0.3;
			}
		}
	}

	.tool-box {
		position: relative;
		margin-bottom: 50px;

		@include respond-to(small) {
			margin-bottom: 100px;
		}

		.tool-title {
			h2 {
				@include rhythm(40, 34);
				text-transform: uppercase;
				color: white;

				@include respond-to(small) {
					@include rhythm(50, 34);
				}
			}

			&.tool-creative {
				h2 {
					color: white;
					animation: nudge 5s infinite linear;
				}

				position: absolute;
				left:60px;
				top: -16px;
			}

			&.tool-web {
				@include respond-to(small) {
					position: absolute;
					top: 155px;
					right: -155px;
					@include transform(rotate(-90deg));

					h2 {
						@include rhythm(215, 180);
						opacity: 0.2;
					}
				}

			}

			&.tool-mobile {
				position: relative;
				padding-bottom: 100px;

				&::before {
					@include pseudo;
					background: white;
					width: 1px;
					height: 80px;
					top: 50px;
				}

				h2 {
					@include rhythm(30, 40);
					@include font-weight(book);
					margin: 0;
				}
			}

			&.tool-manage {

				h2 {
					@include rhythm(25, 30);
					color: white;
					margin: 0;
					@include respond-to(medium) {
						@include rhythm(30, 35);
					}
					@include respond-to(large) {
						@include rhythm(40, 40);
					}
				}
			}

			&.tool-data {
				h2 {
					@include rhythm(25, 30);
					margin: 0;
					@include respond-to(medium) {
						@include rhythm(30, 35);
					}
					@include respond-to(large) {
						@include rhythm(40, 40);
					}
				}
			}
		}

		ul {
			list-style-type: none;
			text-align: left;
			padding: 0;

			li {
				@include rhythm(28, 38);
				color: $orange;
			}
		}

		&.creative {
			background: $orange;
			width: 200px;
			height: 200px;
			padding-top: 50px;
			padding-left: 200px;

			.octa-icon {
				left: 0px;

				svg {
					#octa {
						animation: rotation 15s infinite linear;

					}
				}
			}

			ul {
				padding: 0 0 0 10px;
			}
		}

		&.web {
			padding-top: 10px;
			text-align: center;

			@include respond-to(small) {
				text-align: right;
			}

			.octa-icon {
				left: 0px;
				top: 0;
				opacity: 0.3;

				@include respond-to(small) {
					opacity: 1;
					left: -50px;
				}

				svg {
					position: absolute;

					&:nth-of-type(1) {
						left: 40px;

						@include respond-to(small) {
							left: -100px;
						}

						#octa {
							animation: rotation 10s infinite linear;

						}
					}

					&:nth-of-type(2) {
						top: 200px;
						left: 200px;

						@include respond-to(small) {
							left: -250px;
						}
					}

					&:nth-of-type(3) {
						top: 350px;
						left: 150px;

						@include respond-to(small) {
							left: -150px;
						}

						#ocata1 {
							animation: rotation-zoom 5s infinite linear;

						}
					}

					&:nth-of-type(4) {
						top: 250px;

						@include respond-to(small) {
							top: 150px;
						}

						#ocata1 {
							animation: rotation 10s infinite linear;

						}
					}
				}

			}

			ul {

				@include respond-to(small) {
					padding-right: 180px;
				}

				li {
					display: block;
					@include rhythm($medium, 30);
					margin: 10px 0;
					text-align: center;
					color: $orange;

					@include respond-to(small) {
						text-align: right;
					}

					&.tagline {
						@include rhythm(20, 30);
						color: #cccccc;
						padding: 20px 0;

						@include respond-to(small) {
							text-align: right;
						}

						.case-style {
							@include rhythm(30, 40);
							color: white;
						}
					}
				}
			}
		}

		&.mobile {
			margin-top: 100px;

			@include respond-to(small) {
				margin-top: 200px;
			}

			.octa-icon {
				top: 0;

				svg {
					position: absolute;

					&:nth-of-type(1) {
						top: -100px;
						left: 50px;

						@include respond-to(small) {
							top: -200px;
						}

						#octa {
							animation: rotation 10s infinite linear;

						}
					}

					&:nth-of-type(2) {
						top: 100px;
						left: 200px;
					}
				}
			}

			ul {
				li {
					@include rhythm(20, 30);
					color: #cccccc;
				}
			}
		}

		&.javascript {
			.tool-title {
				h2 {
					text-align: right;

					@include respond-to(medium) {
						text-align: left;
					}
				}
			}

			@include respond-to(large) {
				padding-left: 500px;
			}

			.octa-icon {
				left: 0px;

				svg {
					position: absolute;

					&:nth-of-type(1) {
						left: 200px;
						top: 120px;

						#octa {
							animation: rotation-zoom 5s infinite linear;

						}

						#ocata1 {
							animation: rotation 10s infinite linear;

						}
					}

					&:nth-of-type(2) {
						top: 10px;
						left: 400px;

						@include respond-to(large) {
							left: 500px;
						}

						#octa {
							animation: rotation 10s infinite linear;

						}
					}
				}

			}

			ul {
				text-align: right;

				@include respond-to(small) {}

				@include respond-to(medium) {
					text-align: left;
				}

				li {
					@include rhythm(20, 30);
					color: #cccccc;
				}
			}
		}

		&.data {
			margin-bottom: 50px;

			@include respond-to(small) {
				text-align: right;
				margin-bottom: 0;
			}

			ul {
				text-align: left;
				padding-left: 20px;

				@include respond-to(small) {
					text-align: right;
					padding-left: 0;
				}

				li {
					@include rhythm(20, 30);
					color: #cccccc;
					position: relative;

					&:before {
						@include pseudo;
						width: 10px;
						height: 10px;
						border: 1px solid $orange;
						left: -20px;
						top: 10px;
						@include border-radius(10px);

						@include respond-to(small) {
							right: -20px;
							left: inherit;
						}
					}
				}
			}
		}

		&.manage {
			ul {
				padding-left: 20px;

				@include respond-to(small) {
					padding-left: 0;
				}

				li {
					@include rhythm(20, 30);
					color: #cccccc;
					position: relative;

					&:before {
						@include pseudo;
						width: 10px;
						height: 10px;
						border: 1px solid $orange;
						left: -20px;
						top: 10px;
						@include border-radius(10px);
						display: block;

						@include respond-to(small) {
							display: none;
						}
					}
				}
			}
		}
	}

	.javascript-wrap {
		position: relative;

		&:before {

			@include pseudo;
			width: 500px;
			height: 500px;
			top: -210px;
			left: -150px;
			opacity: 0.5;
			animation: nudge 10s infinite linear;
			display: none;

			@include respond-to(large) {
				display: block;
			}
		}

		.tool-img {
			width: 480px;
			position: absolute;
			top: 50px;
			right: 0;
			@include box-shadow(0 0 30px 0 rgba(black, 0.1));
			display: none;

			@include respond-to(medium) {
				display: block;
			}

			@include respond-to(large) {
				left: 0;
				top: 0;

			}

			&:before {
				@include pseudo;
				background: rgba($dark-blue, 0.5);
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}

			video {
				max-width: 100%;
			}
		}
	}

	.tool-list {
		margin: 15px 0 100px 0;
		padding: 0;
		list-style-type: none;

		li {
			display: inline-block;
			margin: 5px;

			img {
				width: 60px;
			}
		}
	}
}

.process-list {
	position: relative;
}

.approach-banner {
	margin-top: 20px;

	img {
		max-width: 100%;
	}
}

.approach-title {
	position: relative;
}

.approach-info {
	padding: 0 15px;

	h2 {
		@include rhythm(30, 40);
		margin-bottom: 50px;

		@include respond-to(small) {
			@include rhythm(50, 60);
			margin-bottom: 100px;
		}

	}
}

//quote section
.quote-section {

	//padding:0px 0 100px 0;
	blockquote {
		position: relative;
		padding: 100px 0 50px 0;

		@include respond-to(large) {
			padding: 150px 0 50px 0;
		}

		h2 {
			@include rhythm(30, 40);
			color: $dark-blue;
			text-align: left;

			@include respond-to(small) {
				@include rhythm(40, 50);
			}
		}
	}
}

//day to day
.day-to-day {
	.activities {
		position: relative;

		@include respond-to(medium) {
			top: 50%;
			@include transform(translateY(-50%));
		}

		.activities-info {
			p {
				@include rhythm(20, 28);
				color: $dark-blue;
			}
		}

		.reg-day {
			//background:white;
			width: 100%;
			padding: 10px 0;
			display: block;
			text-align: left;

			.numbers {
				@include rhythm(30, 35);
				@include font-weight(regular);
				color: $orange;
			}

			p {
				margin: 0;
				color: $dark-blue;
			}
		}
	}
}

//expert team
.expert-team-wrap {
	padding: 0 0 100px 0;

	@include respond-to(small) {
		padding: 0 0 20px 0;
	}

	@include respond-to(medium) {
		padding: 100px 0 130px 0;
	}

	@include respond-to(large) {
		padding: 100px 0 150px 0;
	}

	.agile-team {
		p {
			@include rhythm(20, 30);
			color: white;
		}
	}

	.expert-team-block {
		position: relative;

		&:after {
			@include pseudo;
			background: $orange;
			width: 100%;
			height: 100%;
			top: 100px;
			z-index: -1;

			@include respond-to(small) {
				height: 80%;
				top: 150px;
			}

			@include respond-to(medium) {
				height: 100%;
				;
			}

			@include respond-to(large) {
				top: 160px;
			}
		}

		h2 {
			font-size: 40px;
			text-align: center;
			text-transform: uppercase;
			margin: 0;
			@include font-weight(bold);

			@include respond-to(small) {
				font-size: 6vw;
			}

			span {
				opacity: 0.2;
			}
		}

		.team-image {
			padding-bottom: 50px;

			div {
				padding: 0;
			}

			img {
				max-width: 100%;
			}
		}

		.big-button {
			margin-top: 25px;

			a {
				color: white;
				border-color: white;

				span {
					color: white;
				}
			}
		}
	}

}

//process animation

.circle {
	width: 100px;
	height: 100px;
	border-radius: 100%;
	@include border-gradient($dark-blue, $dark-blue);

	&:nth-of-type(2) {
		@include border-gradient($dark-blue, $dark-blue);

		animation: rotateThis 3s linear infinite;
	}

	&:nth-of-type(3) {
		@include border-gradient($dark-blue, $dark-blue);
	}

	&-multiple {
		width: 100px;
		height: 100px;
	}

	&-multiple & {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;

		&:nth-of-type(1) {
			animation: psycho 4s linear infinite;
		}

		&:nth-of-type(2) {
			animation: psycho 4s linear .1s infinite;
		}

		&:nth-of-type(3) {
			animation: psycho 4s linear .25s infinite;
		}
	}
}

.circles>* {
	display: inline-block;
	vertical-align: top;
	position: relative;
	margin: 0;
}

.circles {
	transform: translateZ(0);
	margin-bottom: 20px;
}
.fixbody{
	.the-process{
		z-index: inherit;
	}
}
//the process
.the-process {
	margin: 100px 0 100px 0;
	overflow: hidden;
	position: relative;
	z-index: 9;
	@include respond-to(small) {
		margin: 200px 0 100px 0;
		padding-bottom:200px ;
	}
	img {
		max-width: 100%;
		height: auto;
	}

	.process-thumb-slider {
		margin: 0 -5px 5px -5px;

		.slick-track {
			transform: translate3d(0px, 0px, 0px) !important;
		}
	}

	.slick-slider {
		margin-bottom: 0px;
	}

	.process-count {
		@include rhythm(14, 14);
		@include font-weight(medium);
		color: $dark-blue;
		position: relative;
		display: block;
	}

	.process-info-block {
		.count-container{
			@include respond-to(large){
				max-width: 960px;
			}
			@include respond-to(mid-large){
				max-width: 1100px;
			}
			@include respond-to(xx-large){
				max-width: 1300px;
			}
		}
		.controls{
			@include rhythm(18, 20);
		@include font-weight(book);
		padding:10px 15px;
		//display: none;
		float: right;
		@include respond-to(small){
			display: none;
		}
		}
		.process-thumb-slider .slick-slider-item {
		padding: 5px;
		&.slick-current{
			h2{
				color:$orange;
			}
			.process-count{
				color: $orange;
			}
		}
		h2{
			@include rhythm(8, 14);
		@include font-weight(book);
		letter-spacing: 0;
		@include respond-to(small-xs){
			@include rhythm(12, 14);
		}
		@include respond-to(small){
			@include rhythm(20, 20);
		}
		}
	}
		h2 {
			@include rhythm(35, 35);
			@include font-weight(medium);
			color: $dark-blue;
			@include respond-to(small) {
				@include rhythm(70, 60);
				letter-spacing: 5px;
				text-transform: uppercase;
			}
			@include respond-to(large){
				@include rhythm(100, 80);
				letter-spacing: 20px;
			}
		}

		p {
			@include rhythm(16, 28);
			@include respond-to(small){
				@include rhythm(18, 32);
			}
		}
		.process-info-content{
			display: block;
			padding-top: 10px;
			@include respond-to(medium){
				padding-top:50px
			}
		}
		.process-item-slider{
			.slick-list{
				@include respond-to(large){
					max-width: 960px;
					margin:0 auto;
					overflow: inherit;
				}
				@include respond-to(mid-large){
					max-width: 1100px;
				}
				@include respond-to(xx-large){
					max-width: 1300px;
					position: relative;
					&:before{
						@include pseudo;
						background:red;
						top:0;
						left:0;
						width:50%;
					}
				}
			}
			.slick-slider-item{
				opacity: 0.1;
				h2{
					color: $orange;
					-webkit-text-fill-color: #fff;
					-webkit-text-stroke-width: 1px;
					-webkit-text-stroke-color: $orange;
				}
				&.slick-current{
					opacity: 1;
					h2{
						color:$orange;
						-webkit-text-fill-color: inherit;
					-webkit-text-stroke-width: inherit;
					-webkit-text-stroke-color: inherit;
					}
				}
			}
			.slick-arrow{
				position: absolute;
				bottom:-45px;
				left:0;
				right: 0;
				width: 40px;
				height: 40px;
				background: white;
				font-size: 0;
				border:none;
				padding:0;
				@include respond-to(small){
					width: 50px;
				height: 50px;
				}
				&.slick-prev{
					background:url('../images/left-arrow.svg') no-repeat;
					display: block;
					left:15px;
					@include respond-to(small){
						left:10%;
					}
					&.slick-disabled{
						display: none;
					}
				}
				&.slick-next{
					background:url('../images/right-arrow.svg') no-repeat;
					display: block;
					left:80px;
					@include respond-to(small){
						left:15%;
					}
					&.slick-disabled{
						display: none;
					}
				}
				
			}
		}
	}

	.process-quote {
		blockquote {
			position: relative;
			padding: 20px 0 20px 0;
			text-align: center;
			@include rhythm(18, 26);
			color: $orange;
			@include respond-to(small){
				@include rhythm(25, 35);
				padding: 50px 0 50px 0;
			}
		}
	}
}

.process {
	padding-top: 50px;
	@include respond-to(small){
		padding-top: 100px;
	}
	&.process-one {
		.circle {
			width: 50px;
			height: 50px;

			&-multiple {
				width: 50px;
				height: 50px;
			}
		}
	}

	&.process-two {
		.circle {
			width: 25px;
			height: 50px;

			&-multiple {
				width: 30px;
				height: 50px;
			}
		}
	}
}


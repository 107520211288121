//servie details style
.b-hero-inner {
    &.service-hero {
        
        padding:200px 0 150px 0;
        position: relative;
        @include respond-to(large){
            padding:12% 0 8% 0; 
        }
        .container{
            position: relative;
        }

        .tagline {
            //margin-bottom: 200px;
            small{
                @include rhythm(16,24);
                @include font-weight(medium);
            }
            h2 {
                @include rhythm(30, 40);
                color: $dark-blue;
                @include font-weight(medium);
                @include respond-to(small){
                    @include rhythm(45, 55);
                }
                @include respond-to(medium){
                    @include rhythm(65, 75);
                }
                span{
                    margin:20px 0;
                    display: block;
                }
            }
            &.service-tagline{
                h3{
                    @include rhythm(18, 26);
                    color: $body-color;
                    @include font-weight(book);
                    @include respond-to(small){
                        @include rhythm(26, 36);
                    }
                }
            }
        }

        .service-scene {
            position: relative;
            opacity: 0;
        }
    }
}
.service-info-block{
    padding:100px 0 100px 0;
    @include respond-to(small){
        padding:250px 0 100px 0;
    }
   .service-info-title{
       position: relative;
       &:before{
           content: "";
           width:200px;
           height: 200px;
           background: $orange;
           position: absolute;
           top:-100px;
           left:-25%;
           z-index: -1;
           @include respond-to(small){
            width:400px;
            height: 400px;
            top:-300px;
           }
       }
   }

}
.service-scene-together{
    width:100%;
    img{
        max-width: 100%;
    }
}
.quote-section{
    &.service-quote{
        .container{
            @include respond-to(mid-large){
                max-width: 1300px;
            }
        }
      blockquote{
        padding:50px 0;
        h2{
            color: $orange;
            text-align: left;
        }
    }  
    }
    
}
.service-home-info{
    @include respond-to(small){
        margin-top: 70px;
    }
}
//services
.service-container {
    margin: 50px 0 50px 0;
    overflow: hidden;
    @include respond-to(small) {
        margin: 150px 0;
    }
    @include respond-to(large) {
        margin: 100px 0;
    }
    h2 {
        @include rhythm(30, 40);
        color: $dark-blue;
        @include font-weight(medium);
        margin: 0px 0 50px 0;
        display: block;
        @include transition(all 0.5s ease-in-out);
        text-align: left;
        @include respond-to(small){
            @include rhythm(40, 50);
            margin: 0px 0 100px 0;
            @include font-weight(bold);
		}
        @include respond-to(medium){
			@include rhythm(50, 55);
        }
        @include respond-to(large){
			@include rhythm(60, 65);
		}
    }
    h3 {
        @include rhythm(24, 34);
        color: $dark-blue;
        @include font-weight(regular);
        margin: 0px 0 10px 0;
        display: block;
        @include transition(all 0.5s ease-in-out);
        
        @include respond-to(small){
          text-align: right;  
          margin: 0px 0 30px 0;
        }
    }
      .container-fluid{
        padding:0 10%;
      }
    .service-detail-info {
        @include respond-to(medium) {
            text-align: left;
        }
        h2 {
            @include rhythm(34, 44);
            color: $dark-blue;
            @include font-weight(regular);
            margin: 0px 0 30px 0;
            display: block;
            @include transition(all 0.5s ease-in-out);
        }

        p {
            @include rhythm(18,30);
        }

        a {
            color: $orange;

            &:hover {
                color: $blue;
                text-decoration: none;
            }
        }
        .big-text{
            @include rhythm(100, 80);
            color: rgba($body-color,0.1);
            text-transform: uppercase;
            @include font-weight(bold);
            white-space: nowrap;
            position: absolute;
            top: 60px;
            letter-spacing: 5px;
        }
        .align-middle{
          margin-bottom: 25px;
          @include respond-to(medium) {
            margin-bottom: 100px;
          }
        }
    }

    &.app-opt {
        .service-details {
            .svg {
                width: 70%;
                height: 350px;

                @include respond-to(medium) {
                    height: auto;
                }
            }
        }
    }
}

.service-details {
    position: relative;
    text-align: center;
    padding-left: 0;
    padding-bottom: 50px;
    @include transition(all 0.5s ease-in-out);

    @include respond-to(medium) {
        text-align: left;
        padding-bottom:0;
    }

    &:last-child {
        margin-bottom: 0;
    }

   &.mob-app-image{
      .svg{
          width: 50%;
          @include respond-to(medium){
              width: 80%;
          }
      }
   }
   &.digital-market-image,
   &.devops-image{
       .svg{
       // width: 50%;
        @include respond-to(medium){
            width: 80%;
        }
       }
   }
    .svg {
        width: 60%;
        margin: 0 auto;

        @include respond-to(medium) {
            width: 100%;
        }

    }

    &:nth-of-type(2n) {
        @include respond-to(small) {
            .service-thumb {
                left: inherit;
                right: 0;
            }

            .service-detail-info {
                padding-left: 0;
                padding-right: 200px;

                &::before {
                    left: inherit;
                    right: 120px;
                }
            }
        }

    }
}
//accordion
.accordion_container {
    position: relative;
    @include respond-to(medium){
        width: 600px; 
        float: right; 
    }
}
.accordion_head {
    @include rhythm(18,24);
    @include font-weight(light);
    color: $dark-blue;
    cursor: pointer;
    margin: 0 0 1px 0;
    padding: 15px 35px 15px 0px;
    position: relative;
    @include respond-to(small){
        @include rhythm(24,34);
    }
    &.open{
        color: $orange;
    }
}
.accordion_body p {
    padding: 18px 5px;
    margin: 0px;
}
.plusminus {
    @include rhythm(24,24);
    @include font-weight(light);
    float:right;
    position: absolute;
    top:25%;
    right:0;
}
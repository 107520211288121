//theme color
$body-color:#666666;
$dark-blue:#191b30;
$orange:#ff6600;
$blue:#004777; 
$green:#1ea807;
$sea:#03c7a2;
$lightest-grey:#fcfbff; 
$bubble-c-1:#bef6f6;
$bubble-c-2:#afe8de;
$lemon-green:#ffcc00;
$light-yellow:#fff09a;
//case home style
.case-text{
    position: absolute;
    top:15px;
    left: 15px;
    right: 0;
    z-index: 2;
    @include rhythm(16,20);
    @include font-weight(light);
    color: $lemon-green;
    //text-decoration: underline;
    max-width: 1300px;
    margin: 0 auto;
    @include respond-to(small){
        top:60px;
        @include rhythm(20,22);
    }
}
.casestudy-wrap {
    position: relative;
    padding: 50px 0;
    @include respond-to(large){
        padding: 250px 0 100px 0;
    }
    .casestudy-heading{
        text-align: left;
        @include respond-to(small){
            text-align: center;
        }
    }
    small {
        @include rhythm($small, 18);
        color: $orange;
        text-transform: uppercase;
    }
}
.case-study-block {
    position: relative;
    padding: 50px 0;
    margin-bottom: 50px;
    @include respond-to(medium) {
        padding: 100px 0;
    }

    @include respond-to(large) {
        padding: 125px 0;
    }

    &:last-child {
        margin-bottom: 0;

        @include respond-to(479px) {
            padding-bottom: 100px;
        }
    }
    img{
        max-width: 100%;
    }
    .desktop-shot-home{
        width:805px;
        margin:0 auto;
        border:10px solid white;
        @include box-shadow(0 0 20px 0 rgba(black,0.2));
        @include border-radius(10px);
    }
    .mobile-shot-home{
        width: 300px;
        position: absolute;
        bottom:-50px;
        border:10px solid white;
        @include box-shadow(0 0 20px 0 rgba(black,0.2));
        @include border-radius(10px);
    }
//case home jobsstudiso
&.case-block-jobs{
    .case-home-job{
        position: relative;
        .desktop-shot-home{
            width:100%;
            height: 350px;
            overflow: hidden;
            margin:0 auto;
            border:10px solid white;
            @include box-shadow(0 0 20px 0 rgba(black,0.2));
            @include border-radius(10px);
            @include respond-to(small){
                width: 80%;
                height: 450px;
                border:20px solid white;
            }
            @include respond-to(medium){
                width: 90%;
            }
            @include respond-to(large){
                height: 500px;
            }
            @include respond-to(x-large){
                height: 600px;
            }
            @include respond-to(xx-large){
                height: 700px;
            }
        }
        .mobile-shot-home{
            width: 30%;
            position: absolute;
            bottom:-50px;
            left: 20px;
            border:10px solid white;
            @include box-shadow(0 0 20px 0 rgba(black,0.2));
            @include border-radius(10px);
            display: none;
            @include respond-to(small){
                display: block;
            }
            @include respond-to(medium){
                
                width:40%;
                left:-25px;
            }
            @include respond-to(large){
                width: 30%;
                left: -10px;
            }
        }
    }
}
&.case-block-99leave{
    overflow: hidden;
    .desktop-shot-home{
        width:100%;
        position: relative;
        top:0;
        right:0;
        border:none;
        @include border-radius(0);
        @include box-shadow(none);
        @include respond-to(medium){
            width: 80%;
        }
    }
}
    .circle-panel {
        width: 80%;
        position: relative;
        float: right;
        @include border-radius(20px);
        @include box-shadow(0 0 20px rgba(black, 0.2));

        &::after {
            @include pseudo;
            width: 100%;
            height: 100%;
            top: 50px;
            left: 50px;
            border: 1px solid rgba($body-color, 0.2);
            @include border-radius(20px);
            @include transform(rotate(-5deg));
        }

        img {
            max-width: 100%;
            position: relative;
            z-index: 1;
            @include border-radius(20px);
        }
    }
}

.case-info {
    h3 {
        @include rhythm($medium, 30);
        color: $orange;
        text-transform: uppercase;
        @include font-weight(regular);
        margin-bottom: 15px;
        @include respond-to(small){
            @include rhythm($large, 36);
        }
    }

    p {
        @include line-height(24);
    }
}



//half section
.case-bg {
    @include background;
    width: 100%;
    height: 900px;
    background-position: top center;
}

.p-container {
    margin-bottom: 45px;

    @include respond-to(small-xs) {
        margin-bottom: 80px;
    }

    @include respond-to(medium) {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin-bottom: 0;
        @include transform(translateY(-50%));
    }
}
//case study
.b-hero-inner {
    &.casestudy-hero {
        padding: 100px 0;
        position: relative;
        @include respond-to(medium) {
            padding: 0;
        }

        .container {
            text-align: center;

            @include respond-to(medium) {
                text-align: left;
                position: relative;
                top: 50%;
                @include transform(translateY(-50%));
            }

            @include respond-to(x-large) {
                max-width: 1300px;
            }

            .project-info {
                h2 {
                    @include rhythm(22, 30);
                    color: $dark-blue;
                    @include font-weight(book);
                    @include respond-to(small){
                        @include rhythm($large, 40);  
                    }
                }

                .project-info-block {
                    @include respond-to(767px) {
                        width: 50%;

                        &:last-child {
                            width: 100%;
                            margin-top: 25px;
                        }
                    }
                }
            }
        }

        .case-anchor {
            padding-left: 0;
            padding-right: 25px;
            
            @include respond-to(medium){
                text-align: left;
            }
            .btn-site {
                &:after {
                    @include pseudo;
                    animation: ripple-blue 0.7s linear infinite;
                    @include transition(all 0.3s ease-in-out);
                }

                &:hover {
                    text-decoration: none;
                    opacity: 1;

                    &::after {
                        right: -15px;
                    }
                }
            }
        }
    }

    &.casestudy-hero {
       
        .container {
            .project-info {
                padding-top:0;
                padding-bottom: 0;
               @media (max-width:767px){
                   position: absolute;
                   top:50%;
                   @include transform(translateY(-50%));
               }
                @include respond-to(small){
                    padding-bottom: 100px;
                padding-top: 100px;
                text-align: left;
                }
                @include respond-to(medium){
                    padding-bottom: 0;
                    padding-top: 0;
                }
                img{
                    width: 100px;
                    margin-bottom: 50px;
                    &.byzkit-logo{
                        width:200px;
                    }
                }
                h2 {
                    @include rhythm(30,40);
                    color: white;
                    @include respond-to(small){
                        @include rhythm(60,75);  
                    }
                }
                .case-style{
                    color:white;
                }
            }
        }
        &.jobs-hero{
            background:black;
            // .container{
            //     .project-info{
            //         img{
            //             width:150px;
            //         }
            //     }
            // }
        }

    }

    &.jobsstudios {
        overflow: hidden;
        height: 100vh;
        .container{
            @include respond-to(991px){
                padding-top: 50px;
                padding-bottom: 50px;
            }
        }
        &:before {
            @include pseudo;
            @include linearGradient(#00c0ff, #d200ff);
            top: -15%;
            right: -5%;
            left: inherit;
            width: 80%;
            height: 30%;
            animation:slideInRight 1s alternate;
            @include border-radius(100%);
            @include respond-to(small){
                width: 60%;
            height: 60%;
            }
            @include respond-to(large){
                width: 800px;
            height: 800px;
            top: -25%;
            }
            @include respond-to(xx-large){
                width: 1000px;
                height: 1000px;
            }
        }
        .color-circle{
            position: absolute;
            left:0;
            right: 0;
            top:0;
            bottom: 0;
            width: 100%;
            height: 100%;
            animation:slideInDown 1s linear;
            &:before {
                @include pseudo;
                @include linearGradient(#005aff, #ff00fc);
                top: 0%;
                right: -10%;
                width: 80%;
                height: 40%;
                @include border-radius(100%);
                opacity: 0.5;
                animation: nudge-up 5s infinite forwards;
                @include respond-to(small){
                    width: 40%;
                height: 70%;
                top: 20%;
                }
            }
            &:after {
                @include pseudo;
                @include linearGradient(#ff00fc, #005aff);
                top: 0%;
                right: -10%;
                width: 80%;
                height: 30%;
                @include border-radius(100%);
                opacity: 0.5;
                animation: nudge 3s infinite linear;
                @include respond-to(small){
                    width: 40%;
                height: 65%;
                top: -20%;
                right: 20%;
                }
            }
        }
        .screen-scroll {
            width:90%;
            @include respond-to(991px){
                background: white;
                width:94%;
                overflow: hidden;
                padding:10px;
                @include border-radius(10px);
            @include box-shadow(0 0 20px 0 rgba(black,0.2));
            }
            @include respond-to(medium){
                width:768px;
            }
            @include respond-to(large){
                width:960px;
            }
            @include respond-to(xx-large){
                width: 1200px;
            }
            @include respond-to(medium) {
                position: absolute;
                top: 55%;
                right: -15%;
                @include transform(translateY(-50%));
            }
            img{
                display: none;
                @include respond-to(medium){
                    display: block;
                }
            }
            .shot-scroll{
                width: 100%;
                height: 300px;
                top: 0;
                position: relative;
                @include respond-to(small){
                    height: 500px;
                }
                @include respond-to(medium){
                    width: 526px;
                    height: 330px;
                    position: absolute;
                    top: 37px;
                }
                @include respond-to(large){
                    width: 660px;
                height: 412px;
                top: 46px;
                }
                @include respond-to(xx-large){
                    width: 824px;
                    height: 514px;
                    top: 58px;
                }
                img{
                    display: block;
                }
            }
        }
    }


}
.parallax-slide {
    @include respond-to(small){
        height: 500px;
    }
   @include respond-to(large){
       height: 100vh;
   }
  }
  .content-slide {
    height: 100vh;
    background: $dark-blue;
    text-align: center;
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        @include respond-to(small-xs){
            height: 90vh;
        }
  }
  .content-slide-white{
    background:white;
}
//   #parallax1 {
//     background: url('../images/99-showcase.png') no-repeat top center;
//     background-size: cover;
//     background-attachment: fixed;
//   }
  .parallax-leave {
    background: url('../images/byzkit-showcase.png') no-repeat top center;
    @include background;
    width: 100%;
    padding-top: 55%;
    @include respond-to(large){
        background-attachment: fixed;
    }
  }
  .parallax-jobs{
    background: url('../images/jobs-showcase.png') no-repeat top center;
    @include background;
    width: 100%;
    padding-top: 55%;
    @include respond-to(large){
        background-attachment: fixed;
    }
  }
.laptop-show{
    position: relative;
    text-align: center;
    img{
        position: relative;
        right: -20%;
        top:-200px;
    }
    &:before{
        @include pseudo;
        top:-30%;
        left:0;
        right: 0;
        width: 960px;
        height: 960px;
        margin:0 auto;
        background:$blue;
        z-index: -1;
        @include border-radius(100%);
    }
}

.case-layout{
    position: relative;
    padding-top: 80px;
    margin-bottom: 120px;
    
    img{
        max-width: 100%;
        margin-bottom: 50px;
    }
    .button-animi{
        width:240px;
        margin:0 auto;
    }
}
.case-buildup {
    position: relative;
    padding-top:50px;
    @include respond-to(small){
        padding-top:100px;
    }
    @include respond-to(large){
        padding-top:250px;
    }
    p {
        margin-bottom: 0;
        @include rhythm($regular, 20);
        .case-style{
            color:$dark-blue;
        }
    }
    .big-button{
        margin:0 auto;
    }
    .case-information {
        padding: 50px 15px;

        @include respond-to(small) {
            padding: 20px 0 100px 0;
        }

        p {
            color: $body-color;
            margin: 0;
            @include rhythm(20, 32);
            @include font-weight(light);
            @include respond-to(small){
@include rhythm(30, 48);
            }  
        }
        ul{
            margin:20px 0 0 30px;
            padding:0;
            li{
                list-style-type:decimal;
                @include rhythm(20, 32);
            @include font-weight(light);
            margin:0 0 10px 0;
            color:$dark-blue;
                @include respond-to(small){
                    @include rhythm(24, 34);
                }
            }
        }

    }

    &.buildup-leave {
        h2 {
            color: $dark-blue;
            @include rhythm(18,24);
            @include font-weight(medium);
        }
    }
}

.build-info {
    width: 100%;
    padding: 50px 15px;
    text-align: center;

    @include respond-to(small) {
        padding: 50px 0 100px 0;
    }
}

.build {
    .build-wrap {
        text-align: left;
        @include respond-to(small-xs) {
            display: flex;
        }

        .build-block {
            width: 100%;
            padding: 20px;

            @include respond-to(small-xs) {
                width: 25%;
            }

            &.role {
                // border-top: 1px solid rgba($body-color, 0.2);
                // border-bottom: 1px solid rgba($body-color, 0.2);

                @include respond-to(small-xs) {
                    width: 50%;
                    // border-left: 1px solid rgba($body-color, 0.2);
                    // border-right: 1px solid rgba($body-color, 0.2);
                    border-top: 0;
                    border-bottom: 0;
                }
            }

            span {
                @include rhythm(20, 24);
                @include font-weight(regular);
                padding-right: 10px;
                color: $green;
                display: block;
                margin-bottom: 15px;

                @include respond-to(small) {
                    @include rhythm($medium, 28);
                    @include font-weight(medium);
                    text-decoration: underline;
                }
            }
        }

        &.buildup-bg {
           // background: #ffcc00;

            .build-block {
                p {
                    color: $body-color;
                }

                span {
                    color: $dark-blue;
                }
            }
        }
    }

    &.build-top {
        h2 {
            margin-bottom: 50px;
        }

        .build-wrap {
            padding-bottom: 30px;
            border: none;
            border-bottom: 1px solid rgba($body-color, 0.1);
            @include border-radius(0);
            @include box-shadow(none);

            .build-block {
                padding: 10px 20px;
            }
        }
    }
}

.color-code {
    h2 {
        text-align: center;
        margin-bottom: 50px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            width: 80px;
            height: 80px;
            margin: 0 20px;
            background: $dark-blue;
            display: inline-block;
            @include border-radius(80px);

            &.blue {
                background: #24a2dc;
            }

            &.yellow {
                background: #ffcc00;
            }

            &.red {
                background: #f74d4d;
            }

            &.green {
                background: #28b779;
            }

            &.grey {
                background: #666666;
            }

            &.dark-blue {
                background: #2B3643;
            }

            &.black {
                background: black;
            }

            &.orange {
                background: $orange;
            }

            &.brown {
                background: #927450;
            }
        }
    }
}

.view-desktop-wrap {
    margin-bottom: 50px;
    padding:0 15px;
    overflow: hidden;
    @include respond-to(small) {
        margin-bottom: 100px;
    }

    h2 {
        @include rhythm(30, 40);
        margin-bottom: 50px;
        @include respond-to(small){
@include rhythm(40, 50);
        }
    }

    .view-desktop {
        position: relative;
        padding: 10px;
        margin-bottom: 25px;

        @include respond-to(small) {
            padding: 50px;
            margin-bottom: 0;
        }

        img {
            max-width: 100%;
            @include box-shadow(0 0 20px 0 rgba(black, 0.2));
            position: relative;
            z-index: 1;
        }

        &::after {
            @include pseudo;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 250px;
            background: $dark-blue;
            @include border-radius(10px);

            @include respond-to(small) {
                height: 600px;
                @include border-radius(20px);
            }

            @include respond-to(large) {
                height: 800px;
            }
        }
    }
}

//mobile view
.mobile-view-wrap {
    position: relative;
    margin: 150px 0 100px 0;

    &.mobile-jobs {
        .mobscreen-view {
            opacity: 0.5;
        }
    }

    &::before {
        @include pseudo;
        width: 100%;
        height: 50%;
       @include linearGradient(#db4471,#ff7454);
        top: 25%;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .mobscreen-view {
        text-align: center;

        @include respond-to(small-xs) {
            padding: 0 50px;
        }

        img {
            max-width: 100%;
        }
    }
}

.mobile-app {
    width: 100%;
    padding: 50px 15px;
    position: relative;

    @include respond-to(small) {
        padding: 100px 0;
    }

    h2 {
        @include rhythm(30, 40);
        margin-bottom: 50px;
        @include respond-to(small){
            @include rhythm(40, 50);
            margin-bottom: 100px;
        }
    }


    .mobile-block {
        width: 100%;
        max-width: 300px;
        margin: 20px auto;

        @include respond-to(small) {
            width: 33%;
            max-width: inherit;
            ;
            float: left;
        }

        img {
            width: 100%;
            max-width: 100%;
            @include border-radius(20px);
            @include box-shadow(0 0 20px rgba(black, 0.2));

            @include respond-to(small) {
                width: 85%;
                margin: 30px 35px;
            }
            @include respond-to(large){
                @include border-radius(40px);
            }
        }

        &.mobile-two {

            @include respond-to(small) {
                position: relative;
                top: 100px;
            }
        }
    }
}
.case-anchor {
    padding-left: 0;
    text-align: center;
    padding-right: 25px;

    a {
        @include rhythm($medium, 34);
        color: $dark-blue;
        opacity: 0.7;
        @include transition(all 0.3s ease-in-out);
    }

    .btn-site {
        position: relative;
        padding-right: 120px;

        &:before {
            @include pseudo;
            width: 100px;
            height: 1px;
            background: $dark-blue;
            top: 12px;
            right: 0;
        }

        &:after {
            @include pseudo;
            top: 10px;
            right: 0;
            background-color: $dark-blue;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            animation: ripple-blue 0.7s linear infinite;
            @include transition(all 0.3s ease-in-out);
        }

        &:hover {
            text-decoration: none;
            opacity: 1;

            &::after {
                right: -15px;
            }
        }
    }
}

//case study listing
.b-hero-inner {
    &.case-page-banner {
        height: auto;
       
        @include respond-to(small){
            padding: 200px 0 100px 0;
        }
        @include respond-to(medium) {
            padding: 300px 0 300px 0;
        }

        .container {
            @include respond-to(large) {
                max-width: 1300px;
            }
        }

        h2 {
            @include rhythm(30, 45);
            @include respond-to(small){
                @include rhythm(40, 50); 
            }
            @include respond-to(medium) {
                @include rhythm(65, 80);
            }
            span{
                color: $orange;
                border-bottom: 5px solid $dark-blue;
            }
        }
    }
}

//case study listing
.case-study-list {
    padding:50px 0;
    position: relative;
    &:after{
        @include pseudo;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        width:100%;
        height: 100%;
        z-index: 1;
        background: black;
        opacity: 0.2;
        @include transition( opacity 0.5s ease-in-out 0.2s)
    }
    @include respond-to(small){
        padding:100px 0;
    }
    &:hover{
        &:after{
            background:transparent;
            opacity: 0;
            z-index: -1;
        }
    }
    &.case-99leave{
        background:#2b509c;
       background-image: radial-gradient(#2d8cff 10%, #2b509c 80%);
       -webkit-background-image: radial-gradient(#2d8cff 10%, #2b509c 80%);
       filter: grayscale(80%);
       -webkit-filter: grayscale(80%);
       @include transition(all 0.3s ease-in-out);
       &:hover{
        filter: grayscale(0%);
        -webkit-filter: grayscale(0%);
       }
    }
    &.case-jobsstudios{
        background:#26ae61;
       background-image: radial-gradient(#26ae61 10%, #156125 90%);
       -webkit-background-image: radial-gradient(#26ae61 10%, #156125 90%);
       filter: grayscale(80%);
       -webkit-filter: grayscale(80%);
       @include transition(all 0.3s ease-in-out);
       &:hover{
        filter: grayscale(0%);
        -webkit-filter: grayscale(0%);
       }
    }
    .container {
        @include respond-to(large) {
            max-width: 1300px;
        }
    }
}

.case-block-wrap {
    .case-blocks {
        position: relative;
        min-height: 500px;

        .case-thumb {
            width: 100%;
            img {
                max-width: 100%;
                @include transition(all 0.3s ease-in-out);
            }
        }

        .case-list-info {
            position: relative;
            padding-top:80px;
            h2 {
                @include rhythm(40, 50);
                color: white;
                margin-bottom: 25px;

                @include respond-to(small) {
                    @include rhythm(50, 60);
                }
            }
            p{
                @include rhythm(20,34);
                @include font-weight(light);
                color: white;
            }
        }

        &.right-case-block {
            .case-thumb {
                @include respond-to(small) {
                    float: right;
                }
            }

            .case-list-info {
                @include respond-to(small) {
                    left: inherit;
                    right: 340px;
                }

                @include respond-to(medium) {
                    right: 430px;
                }

                @include respond-to(large) {
                    right: 450px;
                }
            }
        }
    }
}


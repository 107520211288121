//families
$stolzl: "stolzl",sans-serif; 

//sizes
$small:14;
$regular:16;
$medium:20;
$large:30;
$x-large:40;
$xx-large:80;
$xxx-large:100;

//weight
$regular-weight:400;
$bold:700;

//font-weight-mixin
@mixin font-weight($weight) {
	$weights: (
	  thin: 100,
	  light: 200,
	  book: 300,
	  regular: 400,
	  medium: 500,
	  bold: 900
	);
	$output: $weight;
	@if map-has-key($weights, $weight) {
	  $output: map-get($weights, $weight);
	}
	font-weight: $output;
  }
//headings
h1,
h2,
h3,
h4,
h5{
	font-family: $stolzl;
	@include font-weight(medium);
}



footer{
  position:relative;
  overflow: hidden;
  &:before{
    content: '';
    position: absolute;
    top: -10%;
    left: -12%;
    width:600px;
    height: 600px;
    z-index: 1;
    background: transparent;
    border:1px solid rgba(white,0.2);
    @include border-radius(100%);
  }
  &:after{
    content: '';
    position: absolute;
    top: -12%;
    right: -5%;
    width:500px;
    height: 500px;
    background: transparent;
    z-index: 1;
    border:1px solid rgba(white,0.2);
    @include border-radius(100%);
  }
  @include respond-to(large){
    //position: fixed;
    width: 100%;
  }
  .container{
    @include respond-to(mid-large){
      max-width: 1300px;
    }
  }
}
.footer-info{
  p{
    margin:5px 0;
    color: white;
    @include font-weight(light);
  }
  h2{
    @include font-size(30);
    color:white;
    margin-bottom:10px;
    @include font-weight(regular);
    @include respond-to(small){
      @include font-size(40);
    }
    @include respond-to(medium){
      @include font-size(50);
    }
    span{
      display: block;
    }
  }
  ul{
    list-style-type:none;
    margin:0 0 10px 0;
    padding:0;
    @include respond-to(large){
      text-align: left;
    }
    li{
      display:inline-block;
      margin-right:10px;
      margin-bottom: 15px;
      @include respond-to(large){
        margin-right:15px;
        margin-bottom: 0;
      }
    }
  }
}
.footer-main-wrap{
  position: relative;
  @include respond-to(medium){
    height: 100vh;
  }
  &.reveal-block{
    &::before{
      background:#151515;
    }
  }
}
.footer-wrapper{
  //background: $orange;
  padding:50px 0;
  @include respond-to(small){
    padding:100px 0;
  }
 @include respond-to(medium){
   padding:12.5% 0;
 }
}
.footer-block{
  @include respond-to(767px){
    text-align: center; 
  }
  &.start-section{
    text-align: left;
   margin:25px 0;
    position: relative;
  }
  &.address-section{
    .row{
      .col-md-4{
        &:nth-child(2n){
          @include respond-to(small){
          display: flex;
          justify-content: center;
          }
        }
        &:nth-child(3n){
          @include respond-to(small){
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
    .foot-box{
      margin-bottom: 35px;
      h3{
        @include rhythm($medium,24);
        color: $light-yellow;
       @include font-weight(regular);
        margin-bottom:10px;
      }
      h5{
        @include rhythm(18,24);
        color:white;
        @include font-weight(regular);
      }
      a{
        @include rhythm($regular, 18);
        color: $orange;
        display: inline-block;
        @include respond-to(small){
          @include rhythm($regular, 24);
        }
        &:hover{
          color: $lemon-green;
          opacity:1;
        }
      }
      
    }
  }
 
}
.social{
  @include respond-to(large){
    text-align: right;
  }
  li{
  .svg{
    width:20px;
    height:22px;
    fill:white;
  }
  &:last-child{
    margin-right: 0;
  }
  }
}
.button-animi{
  &.for-footer{
    a{
      color:white;
      svg{
        path{
          fill:white;
        }
      }
      &:before{
        background: rgba($orange, 0.3);
      }
      &:hover{
        &::before{
          background: rgba($orange, 1);
        }
      }
    }
    
  }
}
.footer-info{
  &.bottom-footer{
    //background:#151515;
    text-align: center;
    padding:50px 0 15px 0;
    @include respond-to(small){
       text-align: left;
       padding:100px 0 30px 0;
    }
    @include respond-to(medium){
      position: absolute;
      left:0;
      right: 0;
      bottom: 0;
      margin:0 auto;
    }
    a{
      color:white;
      @include font-weight(book);
      display:block;
      border-bottom: 1px solid $orange;
      &:hover{
        opacity:0.7;
        text-decoration:none;
      }
    }
  }
  .copyright-wrap{
    margin:10px 0;
    @include respond-to(large){
      text-align: right;
    }
  }
.love-np{
 // text-align: center;
  margin-top: 20px;
  @include respond-to(large){
    text-align: right;
  }
  span{
    color:$orange;
    display: inline-block;
  }
}
}
.dots-block{
  position: absolute;
  top:20%;
  left:0%;
  svg{
    width: 500px;
    #dots{
      fill: rgba(255,255,255,0.3);
    }
  }
}
.social-row{
  @include respond-to(small){
    margin-top: 20px;
  }
}
.copyright-dark{
  @include respond-to(small){
    text-align: right;
  }
  .copyright-wrap, .love-np{
    display: inline-block;
    margin: 0 0 10px 0;
  }
  .love-np{
    @include respond-to(small){
      margin-left:5px;
    }
  }
}
//main navigatin
.global-menu {
	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 100;
	
	@include respond-to(767px){
		padding-top:80px;
	}
}

.global-menu__item {
	@include rhythm(20,30);
	@include font-weight(book);
	color:$dark-blue;
	opacity: 0;
	transform: translateY(-100%);
	pointer-events: none;
	display: flex;
	margin: 0.25em 0;
	transition: transform 0.3s, opacity 0.3s;
	transition-timing-function: ease-in;
	@include respond-to(small){
		@include rhythm(50,60);
		margin: 0.25em 0;
		@include font-weight(medium);
	}
	// @include respond-to(large){
	// 	@include rhythm(40,60);
	// }
	&.active{
		color:$orange;
	}
	&:nth-of-type(1) {
		transition-delay: 0s;
	}

	&:nth-of-type(2) {
		transition-delay: 0.05s;
	}

	&:nth-of-type(3) {
		transition-delay: 0.1s;
	}

	&:nth-of-type(4) {
		transition-delay: 0.15s;
	}
	&:nth-of-type(5) {
		transition-delay: 0.20s;
	}
	&:nth-of-type(6) {
		transition-delay: 0.25s;
	}
	&:nth-of-type(7) {
		transition-delay: 0.3s;
	}

	&.is-opened {
		opacity: 1;
		transform: translateY(0) rotate(0);
		pointer-events: auto;
		transition-timing-function: ease;

		&:nth-of-type(1) {
			transition-delay: 0.9s;
		}

		&:nth-of-type(2) {
			transition-delay: 0.85s;
		}

		&:nth-of-type(3) {
			transition-delay: 0.8s;
		}

		&:nth-of-type(4) {
			transition-delay: 0.75s;
		}
		&:nth-of-type(5) {
			transition-delay: 0.7s;
		}
		&:nth-of-type(6) {
			transition-delay: 0.65s;
		}
		&:nth-of-type(7) {
			transition-delay: 0.6s;
		}
	}

	&--cf {
		transition: transform 0.1s, opacity 0.1s;
		transform: translateY(100%);
		transition-timing-function: ease-out;

		&.is-opened {
			transition-duration: 0.3s;

			&:nth-of-type(1) {
				transition-delay: 0.65s;
			}

			&:nth-of-type(2) {
				transition-delay: 0.7s;
			}

			&:nth-of-type(3) {
				transition-delay: 0.75s;
			}

			&:nth-of-type(4) {
				transition-delay: 0.8s;
			}
			&:nth-of-type(5) {
				transition-delay: 0.85s;
			}
			&:nth-of-type(6) {
				transition-delay: 0.9s;
			}
			&:nth-of-type(7) {
				transition-delay: 0.95s;
			}
		}

		&:nth-of-type(1) {
			transition-delay: 0s;
		}

		&:nth-of-type(2) {
			transition-delay: 0s;
		}

		&:nth-of-type(3) {
			transition-delay: 0s;
		}

		&:nth-of-type(4) {
			transition-delay: 0s;
		}
		&:nth-of-type(5) {
			transition-delay: 0s;
		}
		&:nth-of-type(6) {
			transition-delay: 0s;
		}
		&:nth-of-type(7) {
			transition-delay: 0s;
		}
	}

	&:hover {
		//color:$orange;
		text-decoration: none;
		-webkit-text-fill-color: white; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
		span{
			&:before{
				transform: translate3d(100%,0,0) translate3d(1px,0,0);
			}
			// &:after {
			// 	transform: translate3d(0,0,0);
			// }
			
		}
	}
	span{
		position: relative;
		overflow: hidden;
		&:before,
		&:after{
		@include pseudo;
		background:$orange;
		width:100%;
		height: 100%;
		top:0;
		left:0%;
		transform: translate3d(-100%,0,0) translate3d(-1px,0,0);
	transition: transform 0.7s;
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	}
	// &:after{
	// 	top: calc(50% - 0px);
	// 			height: 4px;
	// }
	}
	
}

.shape-overlays {
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 8;
	&.is-opened {
		pointer-events: auto;
	}

	&__path {
		&:nth-of-type(1) {
			fill: $lightest-grey;
		}

		&:nth-of-type(2) {
			fill: white;
		}

		&:nth-of-type(3) {
			fill: $lemon-green;
		}

		&:nth-of-type(4) {
			fill: $sea;
		}
	}
}
.hamburger {
	background: white;
	width: 50px;
	min-height:50px;
	display: block;
	cursor: pointer;
	position: fixed;
	top: 15px;
	right:15px;
	z-index: 10;
	@include font-weight(regular);
	@include border-radius(100%);
	@include transition(all 0.3s ease-in-out);
	@include respond-to(767px){
		@include transform(scale(0.75));
	}
	@include respond-to(small){
		top:30px
	}
	@include respond-to(large){
		right: 5%;
	}
	// &:after {
	// 	@include respond-to(small){
	// 	box-sizing: border-box;
	// 	content: 'MENU';
	// 	display: block;
	// 	position: absolute;
	// 	top: 21px;
	// 	right: 15px;
	// 	color: $dark-blue;
	// 	@include font-weight(book);
	// 	}
	// }

	&__line {
		width: 25px;
		height: 2px;
		overflow: hidden;
		position: absolute;
		z-index: 10;

		&-in {
			width: 84px;
			height: 2px;
			position: absolute;
			top: 0;
			left: 0;
			&::before,
			&::after {
				width: 28px;
				height: 2px;
				content: '';
				display: block;
				position: absolute;
				top: 0;
				background-color: $dark-blue;
			}
		}
	}
	&:hover{
		.hamburger__line--01 {
			top:16px;
		}
		.hamburger__line--03 {
			top: 32px;
		}
	}
}
.hamburger__line-in::before {
	left: -56px;
}

.hamburger__line-in::after {
	left: 0;
}

.hamburger__line--01,
.hamburger__line--02,
.hamburger__line--03,
.hamburger__line--cross01,
.hamburger__line--cross02 {
	left: 12px;
}

.hamburger__line--01 {
	top: 16px;
	// @include respond-to(small){
	// 	top:31px;
	// }
}

.hamburger__line--02,
.hamburger__line--cross01,
.hamburger__line--cross02 {
	width: 25px;
	top: 24px;
}

.hamburger__line--03 {
	top: 32px;
	// @include respond-to(small){
	// 	top:31px;
	// }
}

.hamburger__line--cross01 {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.hamburger__line--cross02 {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.hamburger__line {
	-webkit-transition-duration: 0.6s;
	transition-duration: 0.6s;
	-webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in {
	-webkit-transition-duration: 0.6s;
	transition-duration: 0.6s;
	-webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in::before,
.hamburger__line-in::after {
	-webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hamburger__line-in--cross01,
.hamburger__line-in--cross02 {
	-webkit-transform: translateX(-33.3%);
	transform: translateX(-33.3%);
}

.hamburger__line-in--01 {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.hamburger__line-in--02 {
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.hamburger__line-in--02::before,
.hamburger__line-in--02::after {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.hamburger__line-in--03 {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.hamburger__line-in--03::before,
.hamburger__line-in--03::after {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.hamburger__line-in--cross01 {
	-webkit-transition-delay: 0.0s;
	transition-delay: 0.0s;
}

.hamburger__line-in--cross02 {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.hamburger__line-in--cross02::before,
.hamburger__line-in--cross02::after {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--01,
.hamburger.is-opened-navi .hamburger__line-in--02,
.hamburger.is-opened-navi .hamburger__line-in--03 {
	-webkit-transform: translateX(33.3%);
	transform: translateX(33.3%);
}

.hamburger.is-opened-navi .hamburger__line-in--cross01,
.hamburger.is-opened-navi .hamburger__line-in--cross02 {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.hamburger.is-opened-navi .hamburger__line-in--01 {
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

.hamburger.is-opened-navi .hamburger__line-in--02 {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.hamburger.is-opened-navi .hamburger__line-in--03 {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross01 {
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross02 {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.hamburger:hover .hamburger__line-in::before,
.hamburger:hover .hamburger__line-in::after {
	-webkit-transform: translateX(200%);
	transform: translateX(200%);
}

.hamburger:hover .hamburger__line-in--01::before,
.hamburger:hover .hamburger__line-in--01::after,
.hamburger:hover .hamburger__line-in--02::before,
.hamburger:hover .hamburger__line-in--02::after,
.hamburger:hover .hamburger__line-in--03::before,
.hamburger:hover .hamburger__line-in--03::after {
	-webkit-transition-duration: 1s;
	transition-duration: 1s;
}

.hamburger:hover .hamburger__line-in--cross01::before,
.hamburger:hover .hamburger__line-in--cross01::after,
.hamburger:hover .hamburger__line-in--cross02::before,
.hamburger:hover .hamburger__line-in--cross02::after {
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::after {
	-webkit-transition-duration: 1s;
	transition-duration: 1s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::after {
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
}

//burger menu
	.hamburger__line-in:after, 
	.hamburger__line-in:before{
		content: "";
		background-color:$orange;
	}
	.hamburger:after{
		color:$orange;
	}
	.fixbody{
		.hamburger__line-in:after, 
	.hamburger__line-in:before{
		content: "";
		background-color:$orange;
	}
	}
.contact-menu-block{
	display: none;
	@include respond-to(small){
		position: absolute;
		bottom: -65px;
		display: block;
	}
	h5{
		@include rhythm(20,24);
		@include font-weight(medium);
		margin-top:20px;
		text-decoration: underline;
		@include respond-to(large){
			margin-top:25px;	
		}
	}
	a{
		@include rhythm(16,24);
		color:$orange;
		margin-bottom: 15px;
	}
	p{
		@include rhythm(16,24);
	}
	.global-menu__item{
		&:hover{
			-webkit-text-fill-color: inherit; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: inherit;
        -webkit-text-stroke-color: inherit;
		}
	}
	.social{
		margin:0;
			padding:0;
		list-style-type: none;
		li{
			margin:0 20px 10px 0;
			a{
				background:$orange;
				padding:10px;
				@include border-radius(100%);
				&:hover{
					background: $dark-blue;
				}
			}
		}
	}
}
.fixbody{
	.hamburger{
		border:1px solid $orange;
	}
}
:root {
  font-size: 16px; }

@-webkit-keyframes intervalHamburgerBorder {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  80% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6); }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.6);
    transform: scale(1.6); } }

@keyframes intervalHamburgerBorder {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); }
  80% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6); }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.6);
    transform: scale(1.6); } }

@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-3px); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(3px); } }

@keyframes shake {
  0%,
  100% {
    transform: translate(0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-3px); }
  20%,
  40%,
  60%,
  80% {
    transform: translate(3px); } }

@-webkit-keyframes shake-h {
  0%,
  100% {
    -webkit-transform: translate(0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate(-1px); }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate(0px); } }

@keyframes shake-h {
  0%,
  100% {
    transform: translate(0); }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-1px); }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(0px); } }

@keyframes wave-front {
  100% {
    transform: translate(-50%, 0); } }

@-webkit-keyframes wave-front {
  100% {
    transform: translate(-50%, 0); } }

@-moz-keyframes wave-front {
  100% {
    transform: translate(-50%, 0); } }

@-ms-keyframes wave-front {
  100% {
    transform: translate(-50%, 0); } }

@keyframes wave-back {
  100% {
    transform: translate(50%, 0); } }

@-webkit-keyframes wave-back {
  100% {
    transform: translate(50%, 0); } }

@-moz-keyframes wave-back {
  100% {
    transform: translate(50%, 0); } }

@-ms-keyframes wave-back {
  100% {
    transform: translate(50%, 0); } }

@keyframes pulse {
  0%,
  100% {
    background-color: #ff6600; }
  50% {
    background-color: #1ea807; } }

@keyframes pulse-1 {
  0%,
  100% {
    background-color: #004777; }
  50% {
    background-color: #03c7a2; } }

@keyframes nudge {
  0%,
  100% {
    transform: translate(0, 0); }
  50% {
    transform: translate(10px, 0); }
  80% {
    transform: translate(-10px, 0); } }

@keyframes nudge-up {
  0%,
  100% {
    transform: translate(0, 0); }
  50% {
    transform: translate(0, 10px); }
  80% {
    transform: translate(0, -10px); } }

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3), 0 0 0 7px rgba(255, 255, 255, 0.3), 0 0 0 9px rgba(255, 255, 255, 0.3), 0 0 0 11px rgba(255, 255, 255, 0.3); }
  100% {
    box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.3), 0 0 0 9px rgba(255, 255, 255, 0.3), 0 0 0 11px rgba(255, 255, 255, 0.3), 0 0 0 13px rgba(255, 255, 255, 0); } }

@keyframes ripple-blue {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 71, 119, 0.3), 0 0 0 7px rgba(0, 71, 119, 0.3), 0 0 0 9px rgba(0, 71, 119, 0.3), 0 0 0 11px rgba(0, 71, 119, 0.3); }
  100% {
    box-shadow: 0 0 0 7px rgba(0, 71, 119, 0.3), 0 0 0 9px rgba(0, 71, 119, 0.3), 0 0 0 11px rgba(0, 71, 119, 0.3), 0 0 0 13px rgba(0, 71, 119, 0); } }

@keyframes ripple-green {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 168, 7, 0.3), 0 0 0 7px rgba(30, 168, 7, 0.3), 0 0 0 9px rgba(30, 168, 7, 0.3), 0 0 0 11px rgba(30, 168, 7, 0.3); }
  100% {
    box-shadow: 0 0 0 7px rgba(30, 168, 7, 0.3), 0 0 0 9px rgba(30, 168, 7, 0.3), 0 0 0 11px rgba(30, 168, 7, 0.3), 0 0 0 13px rgba(30, 168, 7, 0); } }

@-moz-keyframes sparkle {
  100% {
    fill: powderblue; } }

@-webkit-keyframes sparkle {
  100% {
    fill: powderblue; } }

@keyframes sparkle {
  100% {
    fill: powderblue; } }

/*bulb*/
@-moz-keyframes bulb {
  100% {
    fill: #fff09a; } }

@-webkit-keyframes bulb {
  100% {
    fill: #fff09a; } }

@keyframes bulb {
  100% {
    fill: #fff09a; } }

@keyframes desktopscroll {
  10% {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%); }
  50% {
    -webkit-transform: translateY(calc(-50% + 257px));
    -ms-transform: translateY(calc(-50% + 257px));
    transform: translateY(calc(-50% + 257px)); }
  100% {
    -webkit-transform: translateY(calc(-100% + 514px));
    -ms-transform: translateY(calc(-100% + 514px));
    transform: translateY(calc(-100% + 514px)); } }

@-webkit-keyframes scrollCoverBrowser {
  10% {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%); }
  50% {
    -webkit-transform: translateY(calc(-50% + 250px));
    -ms-transform: translateY(calc(-50% + 250px));
    transform: translateY(calc(-50% + 250px)); }
  100% {
    -webkit-transform: translateY(calc(-100% + 900px));
    -ms-transform: translateY(calc(-100% + 900px));
    transform: translateY(calc(-100% + 900px)); } }

/*topToBottom Animation*/
@-moz-keyframes topToBottom {
  0% {
    opacity: 0; }
  5% {
    opacity: 0;
    -moz-transform: translateY(-50px); }
  10% {
    opacity: 1;
    -moz-transform: translateY(0px); }
  25% {
    opacity: 1;
    -moz-transform: translateY(0px); }
  30% {
    opacity: 0;
    -moz-transform: translateY(50px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes topToBottom {
  0% {
    opacity: 0; }
  5% {
    opacity: 0;
    -webkit-transform: translateY(-50px); }
  10% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  30% {
    opacity: 0;
    -webkit-transform: translateY(50px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes topToBottom {
  0% {
    opacity: 0; }
  5% {
    opacity: 0;
    -ms-transform: translateY(-50px); }
  10% {
    opacity: 1;
    -ms-transform: translateY(0px); }
  25% {
    opacity: 1;
    -ms-transform: translateY(0px); }
  30% {
    opacity: 0;
    -ms-transform: translateY(50px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes topToBottom {
  0% {
    opacity: 0; }
  5% {
    opacity: 0;
    -webkit-transform: translateY(-50px); }
  10% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  30% {
    opacity: 0;
    -webkit-transform: translateY(50px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes c-fill {
  0%,
  100% {
    fill: #ff6600; }
  50% {
    fill: #1ea807; } }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes ProgressDots {
  from {
    width: 0px; }
  to {
    width: 100%; } }

@keyframes ProgressDots {
  from {
    width: 0px; }
  to {
    width: 100%; } }

@keyframes blink {
  90% {
    transform: none;
    animation-timing-function: ease-in; }
  93% {
    transform: translate(0px) scaleY(0.8); }
  100% {
    animation-timing-function: ease-out; } }

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform-origin: center center; }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform-origin: center center; } }

@keyframes rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform-origin: center center; }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform-origin: center center; } }

@-webkit-keyframes rotation-zoom {
  from {
    transform: rotate(0deg) scale(0);
    -webkit-transform: rotate(0deg) scale(0);
    transform-origin: center center;
    transition: all 1s ease-in-out; }
  to {
    transform: rotate(360deg) scale(1);
    -webkit-transform: rotate(360deg) scale(1);
    transform-origin: center center;
    transition: all 1s ease-in-out; } }

@keyframes rotation-zoom {
  from {
    transform: rotate(0deg) scale(0);
    -webkit-transform: rotate(0deg) scale(0);
    transform-origin: center center;
    transition: all 1s ease-in-out; }
  to {
    transform: rotate(360deg) scale(1);
    -webkit-transform: rotate(360deg) scale(1);
    transform-origin: center center;
    transition: all 1s ease-in-out; } }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes animate-arrow-1 {
  0% {
    transform: translateY(-50px);
    opacity: 0; }
  50%,
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@-webkit-keyframes animate-arrow-1 {
  0% {
    transform: translateY(-50px);
    opacity: 0; }
  50%,
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@-moz-keyframes animate-arrow-1 {
  0% {
    transform: translateY(-50px);
    opacity: 0; }
  50%,
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes animate-arrow-2 {
  0% {
    transform: translateY(-10px);
    opacity: 0; }
  80%,
  100% {
    transform: translateY(0px);
    opacity: 1; } }

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0.0; }
  50% {
    opacity: 1.0; }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0; } }

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(0); }
  60% {
    opacity: 1;
    transform: translateY(30px) rotate(0); }
  80% {
    transform: translateY(-10px) rotate(0); }
  100% {
    transform: translateY(0) rotate(0); } }

/* Buzz Out */
@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg); }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg); }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg); }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg); }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0); }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0); } }

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes rotateThis {
  from {
    transform: rotate(0deg) scale(1); }
  to {
    transform: rotate(360deg) scale(1); } }

@keyframes psycho {
  0% {
    transform: rotate(0deg) scale(1) translate(0, 0); }
  33% {
    transform: rotate(360deg) scale(1) translate(5px, 5px); }
  66% {
    transform: rotate(720deg) scale(1) translate(-5px, -5px); }
  100% {
    transform: rotate(1080deg) scale(1) translate(0, 0); } }

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.text-center {
  text-align: center !important; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important; }

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important; }

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.form-group {
  margin-bottom: 1rem; }

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.error {
  border: 1px solid red; }

.errorText {
  width: 100%;
  margin-top: .25rem;
  font-size: 14px;
  color: red; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  opacity: 0; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

.custom-file-input.error + label {
  border: 1px solid red; }

.custom-file-input.error + label:after {
  background: red;
  color: white; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0; }

.applyForm {
  display: none; }
  @media (min-width: 768px) {
    .applyForm {
      margin-left: 23px; } }

.apply-btn {
  margin-bottom: 25px; }

.submit-btn {
  background: #004777;
  padding: 11px 20px;
  color: white;
  border: none;
  margin-top: 20px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px; }
  .submit-btn:hover {
    background: #191b30; }

.alert-primary {
  color: white;
  background-color: #004777;
  border-color: #b8daff; }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  position: relative;
  top: 7px;
  left: 15px;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  -ms-webkit-animation: spinner-border .75s linear infinite;
  -moz-webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.circle-shape {
  background: #004777;
  width: 35px;
  height: 35px;
  margin: 0;
  animation: pulse 5s linear infinite alternate;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px; }
  .circle-shape.circle-shape-circle-1 {
    top: 10%;
    left: 25%;
    background: transparent;
    animation: pulse-1 5s linear infinite alternate; }
  .circle-shape.circle-shape-circle-2 {
    background: #03c7a2;
    top: 85%;
    left: 80%;
    background: transparent;
    animation: pulse 5s linear infinite alternate; }
  .circle-shape.circle-shape-circle-3 {
    background: #1ea807;
    top: 75%;
    left: 20%;
    animation: nudge-up 5s linear infinite alternate; }
  .circle-shape.circle-shape-circle-4 {
    background: #ff6600;
    top: 15%;
    left: 80%;
    animation: nudge-up 5s linear infinite alternate; }

[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s; }

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s; }

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s; }

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s; }

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s; }

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s; }

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s; }

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s; }

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s; }

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s; }

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s; }

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s; }

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s; }

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s; }

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s; }

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s; }

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s; }

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s; }

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear],
body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease],
body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in],
body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-out],
body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing=ease-in-out],
body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing=ease-in-back],
body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-out-back],
body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing=ease-in-out-back],
body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing=ease-in-sine],
body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing=ease-out-sine],
body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing=ease-in-out-sine],
body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing=ease-in-quad],
body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quad],
body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quad],
body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-cubic],
body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-cubic],
body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic],
body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-quart],
body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quart],
body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quart],
body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity, transform; }

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0); }

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0); }

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0); }

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0); }

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0); }

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0); }

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0); }

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity, transform; }

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1); }

[data-aos=zoom-in] {
  transform: scale(0.6); }

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos=zoom-out] {
  transform: scale(1.2); }

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform; }

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0); }

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0); }

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0); }

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0); }

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0); }

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition-property: transform; }

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg); }

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg); }

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg); }

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg); }

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0); }

h1,
h2,
h3,
h4,
h5 {
  font-family: "stolzl", sans-serif;
  font-weight: 500; }

@media (min-width: 1200px) {
  * {
    cursor: none; } }

body,
html {
  height: 100%; }

:focus {
  outline: none; }

body {
  background: white;
  font-family: "stolzl", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  color: #666666;
  font-weight: 300;
  margin: 0;
  padding: 0;
  transition: all 200ms;
  will-change: background; }
  body.fixbody {
    overflow: hidden; }

p {
  font-family: "stolzl", sans-serif;
  line-height: 24px;
  line-height: 1.5rem; }

section:after {
  content: "";
  display: table;
  clear: both; }

button:focus {
  border: none;
  outline: none; }

.button-animi {
  position: relative;
  margin-top: 40px; }
  .button-animi button {
    background: none;
    border: none;
    padding: 0; }
  .button-animi a, .button-animi button {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 35px;
    line-height: 2.1875rem;
    font-weight: 500;
    color: #191b30;
    letter-spacing: 0px;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; }
    @media (min-width: 480px) {
      .button-animi a, .button-animi button {
        font-size: 16px;
        font-size: 1rem;
        line-height: 35px;
        line-height: 2.1875rem;
        letter-spacing: 1px; } }
    .button-animi a:focus, .button-animi button:focus {
      outline: none; }
    .button-animi a:before, .button-animi button:before {
      content: "";
      display: block;
      position: absolute;
      background: rgba(102, 102, 102, 0.3);
      width: 35px;
      height: 35px;
      -moz-border-radius: 35px;
      -webkit-border-radius: 35px;
      -ms-border-radius: 35px;
      border-radius: 35px;
      -webkit-transition: width 0.5s ease-in-out;
      -moz-transition: width 0.5s ease-in-out;
      -ms-transition: width 0.5s ease-in-out;
      transition: width 0.5s ease-in-out;
      -webkit-animation-name: shake;
      animation-name: shake;
      -webkit-animation-duration: 8s;
      animation-duration: 8s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite; }
    .button-animi a span, .button-animi button span {
      position: relative;
      z-index: 2;
      padding-left: 37px; }
    .button-animi a svg, .button-animi button svg {
      width: 35px;
      left: 10px;
      position: relative;
      z-index: 2; }
    .button-animi a:hover, .button-animi button:hover {
      padding: 0 37px 0 0px;
      text-decoration: none;
      color: white;
      display: inline-block; }
      .button-animi a:hover:before, .button-animi button:hover:before {
        background: #191b30;
        width: 100%;
        -webkit-transition: width 0.5s ease-in-out;
        -moz-transition: width 0.5s ease-in-out;
        -ms-transition: width 0.5s ease-in-out;
        transition: width 0.5s ease-in-out;
        animation-name: none;
        -webkit-animation-name: none; }
      .button-animi a:hover svg, .button-animi button:hover svg {
        left: 20px;
        -webkit-transition: all 0.8s ease-in-out 0s;
        -moz-transition: all 0.8s ease-in-out 0s;
        -ms-transition: all 0.8s ease-in-out 0s;
        transition: all 0.8s ease-in-out 0s; }
        .button-animi a:hover svg path, .button-animi button:hover svg path {
          fill: white; }

.pages-link {
  padding: 50px 0 60px 0; }
  @media (min-width: 1440px) {
    .pages-link .container {
      max-width: 1300px; } }
  .pages-link .container .col-md-12 p {
    color: #191b30;
    font-weight: 400; }
  @media (max-width: 767px) {
    .pages-link .container .col-md-6:nth-child(1n) {
      margin: 15px 0; } }
  .pages-link .big-button-text a {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem;
    color: #666666;
    font-weight: 300; }
    .pages-link .big-button-text a span {
      position: relative; }
      .pages-link .big-button-text a span:before {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 30px;
        height: 5px;
        background: #666666;
        margin: 0 auto;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
    .pages-link .big-button-text a:hover {
      text-decoration: none;
      color: #ff6600; }
      .pages-link .big-button-text a:hover span:before {
        width: 100%;
        background: #ff6600;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }

.heading-2 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  color: #191b30;
  margin-bottom: 20px; }
  @media (min-width: 480px) {
    .heading-2 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 36px;
      line-height: 2.25rem; } }
  @media (min-width: 768px) {
    .heading-2 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 48px;
      line-height: 3rem; } }

.highlight {
  padding: 20px 0;
  color: #191b30;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 45px;
  line-height: 2.8125rem;
  display: block; }

.big-button {
  padding: 50px 0 10px 0;
  display: block;
  text-align: center; }
  @media (min-width: 1200px) {
    .big-button {
      padding: 100px 0 10px 0; } }
  .big-button a {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem;
    color: #191b30;
    border: 5px solid #191b30;
    padding: 20px 20px 25px 20px;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    @media (min-width: 480px) {
      .big-button a {
        padding: 20px 30px 25px 30px; } }
    .big-button a span {
      width: 40px; }
      .big-button a span svg {
        width: 40px; }
    .big-button a:hover {
      background: #191b30;
      text-decoration: none;
      color: white; }
      .big-button a:hover span {
        text-decoration: none;
        color: white; }

@media (min-width: 1200px) {
  .main-front {
    position: relative; } }

@media (min-width: 1200px) {
  .footer-transparent-space {
    height: 100%; } }

.hidden {
  display: none; }

.case-style {
  font-weight: 500;
  color: #191b30; }

@media (max-width: 1199px) {
  .my-cursor,
  .follow {
    display: none; } }

@media (min-width: 1200px) {
  .my-cursor {
    position: absolute;
    background-color: #ff6600;
    width: 10px;
    height: 10px;
    text-align: center;
    border-radius: 100%;
    z-index: 1;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    transform: scale(1); }
  .my-cursor.active {
    opacity: 0.5;
    transform: scale(0); }
  .my-cursor.hovered {
    opacity: 0.08; }
  .follow {
    position: absolute;
    background-color: transparent;
    border: 1px solid #666666;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    z-index: 1;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    transform: translate(2px, 2px); }
  .follow.active {
    background: rgba(102, 102, 102, 0.1);
    border-color: rgba(102, 102, 102, 0.2);
    opacity: 0.7;
    transform: scale(3); }
  .follow.hovered {
    opacity: 0.08; } }

.b-hero-inner {
  padding: 150px 0 50px 0;
  position: relative; }
  @media (min-width: 768px) {
    .b-hero-inner {
      padding: 15% 0 7% 0; } }
  .b-hero-inner h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 50px;
    line-height: 3.125rem;
    color: #191b30;
    margin-bottom: 20px; }
    @media (min-width: 480px) {
      .b-hero-inner h2 {
        font-size: 60px;
        font-size: 3.75rem;
        line-height: 80px;
        line-height: 5rem;
        margin-bottom: 40px; } }
    @media (min-width: 1200px) {
      .b-hero-inner h2 {
        font-size: 80px;
        font-size: 5rem;
        line-height: 100px;
        line-height: 6.25rem; } }
    @media (min-width: 1300px) {
      .b-hero-inner h2 {
        font-size: 100px;
        font-size: 6.25rem;
        line-height: 120px;
        line-height: 7.5rem; } }

.line {
  width: 100%;
  padding: 1px 0px; }

.circle {
  border-radius: 50%; }

.triangle {
  pointer-events: none;
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 36px 46px 36px;
  border-color: transparent transparent currentColor transparent; }

.button-back {
  width: 100%;
  margin: 50px 0;
  text-align: center; }
  .button-back a {
    color: #191b30;
    font-weight: 900;
    padding-left: 50px;
    position: relative; }
    .button-back a img {
      position: absolute;
      left: 0;
      top: -16px;
      width: 50px;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .button-back a:hover {
      text-decoration: none; }
      .button-back a:hover img {
        left: -10px; }

.loaded .loader {
  -webkit-animation: animLoadedHeader 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation: animLoadedHeader 1s cubic-bezier(0.7, 0, 0.3, 1) forwards;
  animation-delay: 3s;
  -webkit-animation-delay: 3s; }

@-webkit-keyframes animLoadedHeader {
  to {
    -webkit-transform: translate3d(0, -100%, 0); } }

@keyframes animLoadedHeader {
  to {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); } }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }
  .loader .loader-fill {
    max-width: 600px;
    height: 280px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .loader .loader-fill h2 {
      color: white;
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 30px;
      line-height: 1.875rem;
      font-weight: 300;
      padding: 25px 15px 0 15px;
      margin: 0;
      opacity: 0;
      position: absolute;
      bottom: -60px;
      left: 0;
      right: 0; }
      .loader .loader-fill h2.slideDown {
        animation: slideInDown 3s alternate;
        opacity: 1; }

.loader {
  position: fixed;
  left: 0;
  top: 0;
  background: #ff6600;
  width: 100%;
  height: 100vh; }
  .loader.loaded {
    opacity: 0;
    z-index: -1;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }

.load-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: fit-content;
  display: flex; }

.loaded-text {
  transform: scale(1.5);
  animation: scaleText .5s linear .5s forwards; }
  .loaded-text svg {
    width: 65px; }

.loading-text {
  width: 0;
  overflow: hidden;
  margin: 12px 0 0 10px;
  animation: expand .5s linear 1.5s forwards; }
  .loading-text svg {
    width: 190px; }

.abrand {
  width: 300px;
  position: absolute;
  top: 60px;
  left: 0;
  font-size: 20px;
  opacity: 0;
  display: block;
  margin-top: 20px;
  overflow: hidden;
  text-align: center;
  color: white;
  transition: all 1s ease-in-out;
  animation: expands 1s linear 2.5s forwards; }
  .abrand span {
    font-weight: 900; }

@keyframes scaleText {
  from {
    transform: scale(1.5); }
  to {
    transform: scale(1); } }

@keyframes expand {
  from {
    width: 0; }
  to {
    width: 190px; } }

@keyframes expands {
  from {
    transform: translateY(-100%);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes backgroundChange {
  from {
    background-color: black; }
  to {
    background-color: black; } }

@keyframes colorChange {
  from {
    color: black; }
  to {
    color: white; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    z-index: -1; } }

.puff-in-center {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both; }

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@media (min-width: 768px) {
  .blub-animation {
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
    animation: morphing 10s infinite;
    overflow: hidden; } }

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2); }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%; }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2); }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%; } }

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both; }

.mainContent {
  width: 100%;
  position: relative; }

@media (min-width: 992px) {
  .sidebar {
    max-width: 400px;
    position: absolute; } }

@media (min-width: 992px) {
  .sidebar.fixed {
    position: fixed;
    top: 0; } }

.sidebar .cf-slogan-block {
  padding: 80px 0 0px 0; }

.content {
  float: right;
  width: 70%;
  background: #666c82;
  height: 1200px;
  padding: 45px 15px; }

.join-career {
  background: #f2f2f2;
  padding: 50px 0; }
  @media (min-width: 480px) {
    .join-career {
      text-align: center; } }
  @media (min-width: 480px) {
    .join-career .button-animi {
      display: inline-block; } }
  @media (min-width: 1440px) {
    .join-career .container {
      max-width: 1300px; } }
  .join-career h2 {
    font-size: 25px;
    font-size: 1.5625rem;
    line-height: 35px;
    line-height: 2.1875rem;
    font-weight: 300;
    color: #191b30; }
    @media (min-width: 992px) {
      .join-career h2 {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 40px;
        line-height: 2.5rem; } }
    @media (min-width: 1200px) {
      .join-career h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 50px;
        line-height: 3.125rem; } }

.back-btn {
  position: relative;
  margin-top: -20px;
  margin-bottom: 30px; }
  @media (min-width: 768px) {
    .back-btn {
      margin-top: -50px;
      margin-bottom: 30px; } }
  .back-btn a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 35px;
    line-height: 2.1875rem;
    font-weight: 500;
    color: #191b30;
    letter-spacing: 0px;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; }
    @media (min-width: 480px) {
      .back-btn a {
        font-size: 16px;
        font-size: 1rem;
        line-height: 35px;
        line-height: 2.1875rem;
        letter-spacing: 1px; } }
    .back-btn a:focus {
      outline: none; }
    .back-btn a span {
      position: relative;
      z-index: 2;
      padding-left: 5px; }
    .back-btn a svg {
      width: 35px;
      left: -5px;
      top: -2px;
      position: relative;
      z-index: 2;
      -moz-transform: rotate(-180deg);
      -o-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg); }
    .back-btn a:hover {
      padding: 0 37px 0 0px;
      text-decoration: none;
      color: #ff6600;
      display: inline-block; }
      .back-btn a:hover svg path {
        fill: #ff6600; }

header {
  padding: 15px 0 0 0; }
  @media (min-width: 768px) {
    header {
      padding: 20px 0; } }
  header.light .cf-logo svg path {
    fill: #ff6600; }
  header.dark .cf-logo svg path {
    fill: white; }
  header .container-fliud {
    position: relative;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 1200px) {
      header .container-fliud {
        padding-left: 5%;
        padding-right: 5%; } }

#logo {
  position: absolute;
  left: 15px;
  z-index: 9; }
  @media (min-width: 768px) {
    #logo {
      position: fixed;
      left: inherit; } }

.logo-slidedown {
  animation: fadeInDown 1s alternate; }

.slide-in-top {
  -webkit-animation: slide-in-top 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.c-block {
  width: 24px;
  float: left; }

.l-block {
  width: 14px;
  float: left;
  margin: 0 -1px 0 3px; }

.o-block {
  width: 23px;
  float: left;
  margin-top: 6px; }

.u-block {
  width: 21px;
  float: left;
  margin-top: 6px;
  margin-left: 2px; }

.d-block {
  width: 23px;
  float: left;
  margin-left: 3px; }

.y-block {
  width: 22px;
  float: left;
  margin-left: 4px;
  position: relative;
  top: 9px; }

.f-block {
  width: 19px;
  float: left;
  margin-left: 2px; }

.oo-block {
  width: 23px;
  float: left;
  margin-top: 6px; }

.x-block {
  width: 26px;
  float: left;
  margin-top: 6px; }

.cf-logo {
  display: inline-block; }
  .cf-logo svg path {
    fill: white; }
  .cf-logo.cf-icon-block {
    width: 60px;
    position: absolute;
    left: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition-delay: 1s;
    -moz-transition-delay: 1s;
    -ms-transition-delay: 1s;
    transition-delay: 1s; }
    @media (max-width: 767px) {
      .cf-logo.cf-icon-block {
        transform: translateX(0px) translateZ(0px) !important;
        -webit-transform: translateX(0px) translateZ(0px) !important;
        opacity: 1 !important;
        width: 40px; } }
    .cf-logo.cf-icon-block.join {
      -webkit-transition: all 0.2s ease-in-out 0s;
      -moz-transition: all 0.2s ease-in-out 0s;
      -ms-transition: all 0.2s ease-in-out 0s;
      transition: all 0.2s ease-in-out 0s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      transition-delay: 0s; }
  @media (max-width: 767px) {
    .cf-logo.cf-cloudy-block {
      display: none !important; } }
  .cf-logo.cf-cloudy-block div {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .cf-logo.cf-cloudy-block div:nth-child(1) {
      -moz-transition-delay: 0.1s;
      -webkit-transition-delay: 0.1s;
      -ms-transition-delay: 0.1s;
      transition-delay: 0.1s; }
    .cf-logo.cf-cloudy-block div:nth-child(2) {
      -webkit-transition-delay: 0.2s;
      -moz-transition-delay: 0.2s;
      -ms-transition-delay: 0.2s;
      transition-delay: 0.2s; }
    .cf-logo.cf-cloudy-block div:nth-child(3) {
      -webkit-transition-delay: 0.3s;
      -moz-transition-delay: 0.3s;
      -ms-transition-delay: 0.3s;
      transition-delay: 0.3s; }
    .cf-logo.cf-cloudy-block div:nth-child(4) {
      -webkit-transition-delay: 0.4s;
      -moz-transition-delay: 0.4s;
      -ms-transition-delay: 0.4s;
      transition-delay: 0.4s; }
    .cf-logo.cf-cloudy-block div:nth-child(5) {
      -webkit-transition-delay: 0.5s;
      -moz-transition-delay: 0.5s;
      -ms-transition-delay: 0.5s;
      transition-delay: 0.5s; }
    .cf-logo.cf-cloudy-block div:nth-child(6) {
      -webkit-transition-delay: 0.6s;
      -moz-transition-delay: 0.6s;
      -ms-transition-delay: 0.6s;
      transition-delay: 0.6s; }
    .cf-logo.cf-cloudy-block div:nth-child(7) {
      -webkit-transition-delay: 0.7s;
      -moz-transition-delay: 0.7s;
      -ms-transition-delay: 0.7s;
      transition-delay: 0.7s; }
    .cf-logo.cf-cloudy-block div:nth-child(8) {
      -webkit-transition-delay: 0.8s;
      -moz-transition-delay: 0.8s;
      -ms-transition-delay: 0.8s;
      transition-delay: 0.8s; }
    .cf-logo.cf-cloudy-block div:nth-child(9) {
      -webkit-transition-delay: 0.9s;
      -moz-transition-delay: 0.9s;
      -ms-transition-delay: 0.9s;
      transition-delay: 0.9s; }
  .cf-logo.cf-cloudy-block svg {
    margin-top: 5px; }
    .cf-logo.cf-cloudy-block svg path, .cf-logo.cf-cloudy-block svg polygon {
      fill: #ffffff; }

.cf-light .cf-logo {
  display: inline-block; }
  .cf-light .cf-logo svg path {
    fill: #ff6600; }
  .cf-light .cf-logo.cf-cloudy-block svg {
    margin-top: 5px; }
    .cf-light .cf-logo.cf-cloudy-block svg path, .cf-light .cf-logo.cf-cloudy-block svg polygon {
      fill: #004777; }

.cf-dark .cf-logo {
  display: inline-block; }
  .cf-dark .cf-logo svg path {
    fill: #ff6600; }
  .cf-dark .cf-logo.cf-cloudy-block svg {
    margin-top: 5px; }
    .cf-dark .cf-logo.cf-cloudy-block svg path, .cf-dark .cf-logo.cf-cloudy-block svg polygon {
      fill: white; }

header {
  position: absolute;
  left: 0;
  top: 0;
  right: 0; }
  @media (min-width: 1300px) {
    header .container {
      max-width: 1300px; } }

.fixbody header #logo {
  z-index: 9; }
  .fixbody header #logo svg path, .fixbody header #logo svg polygon {
    fill: #ff6600; }
  .fixbody header #logo svg .cf_icon {
    display: block; }
  .fixbody header #logo svg .cf_text {
    display: none; }

.casestudy-detail #logo {
  display: none; }

.global-menu {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 100; }
  @media (max-width: 767px) {
    .global-menu {
      padding-top: 80px; } }

.global-menu__item {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-weight: 300;
  color: #191b30;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;
  display: flex;
  margin: 0.25em 0;
  transition: transform 0.3s, opacity 0.3s;
  transition-timing-function: ease-in; }
  @media (min-width: 768px) {
    .global-menu__item {
      font-size: 50px;
      font-size: 3.125rem;
      line-height: 60px;
      line-height: 3.75rem;
      margin: 0.25em 0;
      font-weight: 500; } }
  .global-menu__item.active {
    color: #ff6600; }
  .global-menu__item:nth-of-type(1) {
    transition-delay: 0s; }
  .global-menu__item:nth-of-type(2) {
    transition-delay: 0.05s; }
  .global-menu__item:nth-of-type(3) {
    transition-delay: 0.1s; }
  .global-menu__item:nth-of-type(4) {
    transition-delay: 0.15s; }
  .global-menu__item:nth-of-type(5) {
    transition-delay: 0.20s; }
  .global-menu__item:nth-of-type(6) {
    transition-delay: 0.25s; }
  .global-menu__item:nth-of-type(7) {
    transition-delay: 0.3s; }
  .global-menu__item.is-opened {
    opacity: 1;
    transform: translateY(0) rotate(0);
    pointer-events: auto;
    transition-timing-function: ease; }
    .global-menu__item.is-opened:nth-of-type(1) {
      transition-delay: 0.9s; }
    .global-menu__item.is-opened:nth-of-type(2) {
      transition-delay: 0.85s; }
    .global-menu__item.is-opened:nth-of-type(3) {
      transition-delay: 0.8s; }
    .global-menu__item.is-opened:nth-of-type(4) {
      transition-delay: 0.75s; }
    .global-menu__item.is-opened:nth-of-type(5) {
      transition-delay: 0.7s; }
    .global-menu__item.is-opened:nth-of-type(6) {
      transition-delay: 0.65s; }
    .global-menu__item.is-opened:nth-of-type(7) {
      transition-delay: 0.6s; }
  .global-menu__item--cf {
    transition: transform 0.1s, opacity 0.1s;
    transform: translateY(100%);
    transition-timing-function: ease-out; }
    .global-menu__item--cf.is-opened {
      transition-duration: 0.3s; }
      .global-menu__item--cf.is-opened:nth-of-type(1) {
        transition-delay: 0.65s; }
      .global-menu__item--cf.is-opened:nth-of-type(2) {
        transition-delay: 0.7s; }
      .global-menu__item--cf.is-opened:nth-of-type(3) {
        transition-delay: 0.75s; }
      .global-menu__item--cf.is-opened:nth-of-type(4) {
        transition-delay: 0.8s; }
      .global-menu__item--cf.is-opened:nth-of-type(5) {
        transition-delay: 0.85s; }
      .global-menu__item--cf.is-opened:nth-of-type(6) {
        transition-delay: 0.9s; }
      .global-menu__item--cf.is-opened:nth-of-type(7) {
        transition-delay: 0.95s; }
    .global-menu__item--cf:nth-of-type(1) {
      transition-delay: 0s; }
    .global-menu__item--cf:nth-of-type(2) {
      transition-delay: 0s; }
    .global-menu__item--cf:nth-of-type(3) {
      transition-delay: 0s; }
    .global-menu__item--cf:nth-of-type(4) {
      transition-delay: 0s; }
    .global-menu__item--cf:nth-of-type(5) {
      transition-delay: 0s; }
    .global-menu__item--cf:nth-of-type(6) {
      transition-delay: 0s; }
    .global-menu__item--cf:nth-of-type(7) {
      transition-delay: 0s; }
  .global-menu__item:hover {
    text-decoration: none;
    -webkit-text-fill-color: white;
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black; }
    .global-menu__item:hover span:before {
      transform: translate3d(100%, 0, 0) translate3d(1px, 0, 0); }
  .global-menu__item span {
    position: relative;
    overflow: hidden; }
    .global-menu__item span:before, .global-menu__item span:after {
      content: "";
      display: block;
      position: absolute;
      background: #ff6600;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0%;
      transform: translate3d(-100%, 0, 0) translate3d(-1px, 0, 0);
      transition: transform 0.7s;
      transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1); }

.shape-overlays {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8; }
  .shape-overlays.is-opened {
    pointer-events: auto; }
  .shape-overlays__path:nth-of-type(1) {
    fill: #fcfbff; }
  .shape-overlays__path:nth-of-type(2) {
    fill: white; }
  .shape-overlays__path:nth-of-type(3) {
    fill: #ffcc00; }
  .shape-overlays__path:nth-of-type(4) {
    fill: #03c7a2; }

.hamburger {
  background: white;
  width: 50px;
  min-height: 50px;
  display: block;
  cursor: pointer;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 10;
  font-weight: 400;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  @media (max-width: 767px) {
    .hamburger {
      -moz-transform: scale(0.75);
      -o-transform: scale(0.75);
      -ms-transform: scale(0.75);
      -webkit-transform: scale(0.75);
      transform: scale(0.75); } }
  @media (min-width: 768px) {
    .hamburger {
      top: 30px; } }
  @media (min-width: 1200px) {
    .hamburger {
      right: 5%; } }
  .hamburger__line {
    width: 25px;
    height: 2px;
    overflow: hidden;
    position: absolute;
    z-index: 10; }
    .hamburger__line-in {
      width: 84px;
      height: 2px;
      position: absolute;
      top: 0;
      left: 0; }
      .hamburger__line-in::before, .hamburger__line-in::after {
        width: 28px;
        height: 2px;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        background-color: #191b30; }
  .hamburger:hover .hamburger__line--01 {
    top: 16px; }
  .hamburger:hover .hamburger__line--03 {
    top: 32px; }

.hamburger__line-in::before {
  left: -56px; }

.hamburger__line-in::after {
  left: 0; }

.hamburger__line--01,
.hamburger__line--02,
.hamburger__line--03,
.hamburger__line--cross01,
.hamburger__line--cross02 {
  left: 12px; }

.hamburger__line--01 {
  top: 16px; }

.hamburger__line--02,
.hamburger__line--cross01,
.hamburger__line--cross02 {
  width: 25px;
  top: 24px; }

.hamburger__line--03 {
  top: 32px; }

.hamburger__line--cross01 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.hamburger__line--cross02 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.hamburger__line {
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger__line-in {
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger__line-in::before,
.hamburger__line-in::after {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.hamburger__line-in--cross01,
.hamburger__line-in--cross02 {
  -webkit-transform: translateX(-33.3%);
  transform: translateX(-33.3%); }

.hamburger__line-in--01 {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s; }

.hamburger__line-in--02 {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s; }

.hamburger__line-in--02::before,
.hamburger__line-in--02::after {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

.hamburger__line-in--03 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s; }

.hamburger__line-in--03::before,
.hamburger__line-in--03::after {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.hamburger__line-in--cross01 {
  -webkit-transition-delay: 0.0s;
  transition-delay: 0.0s; }

.hamburger__line-in--cross02 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

.hamburger__line-in--cross02::before,
.hamburger__line-in--cross02::after {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.hamburger.is-opened-navi .hamburger__line-in--01,
.hamburger.is-opened-navi .hamburger__line-in--02,
.hamburger.is-opened-navi .hamburger__line-in--03 {
  -webkit-transform: translateX(33.3%);
  transform: translateX(33.3%); }

.hamburger.is-opened-navi .hamburger__line-in--cross01,
.hamburger.is-opened-navi .hamburger__line-in--cross02 {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

.hamburger.is-opened-navi .hamburger__line-in--01 {
  -webkit-transition-delay: 0s;
  transition-delay: 0s; }

.hamburger.is-opened-navi .hamburger__line-in--02 {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s; }

.hamburger.is-opened-navi .hamburger__line-in--03 {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.hamburger.is-opened-navi .hamburger__line-in--cross01 {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s; }

.hamburger.is-opened-navi .hamburger__line-in--cross02 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s; }

.hamburger:hover .hamburger__line-in::before,
.hamburger:hover .hamburger__line-in::after {
  -webkit-transform: translateX(200%);
  transform: translateX(200%); }

.hamburger:hover .hamburger__line-in--01::before,
.hamburger:hover .hamburger__line-in--01::after,
.hamburger:hover .hamburger__line-in--02::before,
.hamburger:hover .hamburger__line-in--02::after,
.hamburger:hover .hamburger__line-in--03::before,
.hamburger:hover .hamburger__line-in--03::after {
  -webkit-transition-duration: 1s;
  transition-duration: 1s; }

.hamburger:hover .hamburger__line-in--cross01::before,
.hamburger:hover .hamburger__line-in--cross01::after,
.hamburger:hover .hamburger__line-in--cross02::before,
.hamburger:hover .hamburger__line-in--cross02::after {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::after {
  -webkit-transition-duration: 1s;
  transition-duration: 1s; }

.hamburger.is-opened-navi:hover .hamburger__line-in--01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::after {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.hamburger__line-in:after,
.hamburger__line-in:before {
  content: "";
  background-color: #ff6600; }

.hamburger:after {
  color: #ff6600; }

.fixbody .hamburger__line-in:after,
.fixbody .hamburger__line-in:before {
  content: "";
  background-color: #ff6600; }

.contact-menu-block {
  display: none; }
  @media (min-width: 768px) {
    .contact-menu-block {
      position: absolute;
      bottom: -65px;
      display: block; } }
  .contact-menu-block h5 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    line-height: 1.5rem;
    font-weight: 500;
    margin-top: 20px;
    text-decoration: underline; }
    @media (min-width: 1200px) {
      .contact-menu-block h5 {
        margin-top: 25px; } }
  .contact-menu-block a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 1.5rem;
    color: #ff6600;
    margin-bottom: 15px; }
  .contact-menu-block p {
    font-size: 16px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 1.5rem; }
  .contact-menu-block .global-menu__item:hover {
    -webkit-text-fill-color: inherit;
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: inherit;
    -webkit-text-stroke-color: inherit; }
  .contact-menu-block .social {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .contact-menu-block .social li {
      margin: 0 20px 10px 0; }
      .contact-menu-block .social li a {
        background: #ff6600;
        padding: 10px;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        -ms-border-radius: 100%;
        border-radius: 100%; }
        .contact-menu-block .social li a:hover {
          background: #191b30; }

.fixbody .hamburger {
  border: 1px solid #ff6600; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }
  .slick-slider:focus {
    outline: none; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }
  .slick-track:focus {
    outline: none; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  overflow: hidden;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block;
    overflow: hidden; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .slick-dots li {
    list-style-type: none;
    display: inline-block;
    margin: 5px 20px 5px 0; }
    .slick-dots li button {
      width: 15px;
      height: 15px;
      position: relative;
      font-size: 0px;
      font-size: 0rem;
      line-height: 0px;
      line-height: 0rem;
      color: rgba(255, 255, 255, 0.3);
      border: none;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      -ms-border-radius: 100%;
      border-radius: 100%;
      opacity: 0.5; }
    .slick-dots li.slick-active button {
      opacity: 1;
      background-color: black;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.7); }

.bg {
  width: 100%;
  min-height: 568px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  @media (min-width: 768px) {
    .bg {
      min-height: 650px; } }
  @media (min-width: 1200px) {
    .bg {
      min-height: auto; } }
  @media (min-width: 1200px) {
    .bg {
      height: 100vh; } }
  @media (min-width: 1700px) {
    .bg {
      height: auto; } }
  .bg1 {
    background: #2c9dd7;
    /* Old browsers */
    background: -moz-linear-gradient(left, #2c9dd7 0%, #54c4fe 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2c9dd7), color-stop(100%, #54c4fe));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #2c9dd7 0%, #54c4fe 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #2c9dd7 0%, #54c4fe 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #2c9dd7 0%, #54c4fe 100%);
    /* IE10+ */
    background: linear-gradient(to right, #2c9dd7 0%, #54c4fe 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */ }
  @media (max-width: 1199px) {
    .bg2 {
      background: #8b3fae;
      /* Old browsers */
      background: -moz-linear-gradient(left, #8b3fae 0%, #3f02a8 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #8b3fae), color-stop(100%, #3f02a8));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left, #8b3fae 0%, #3f02a8 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left, #8b3fae 0%, #3f02a8 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(left, #8b3fae 0%, #3f02a8 100%);
      /* IE10+ */
      background: linear-gradient(to right, #8b3fae 0%, #3f02a8 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
      /* IE6-9 */ } }
  @media (max-width: 1199px) {
    .bg3 {
      background: #300094;
      /* Old browsers */
      background: -moz-linear-gradient(left, #300094 0%, #b012e8 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #300094), color-stop(100%, #b012e8));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left, #300094 0%, #b012e8 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left, #300094 0%, #b012e8 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(left, #300094 0%, #b012e8 100%);
      /* IE10+ */
      background: linear-gradient(to right, #300094 0%, #b012e8 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
      /* IE6-9 */ } }
  .bg svg {
    height: 100%;
    display: none; }
    @media (min-width: 1200px) {
      .bg svg {
        display: block; } }

.block-media {
  width: 100%;
  height: 100%;
  position: relative; }
  .block-media .slick-arrow {
    position: absolute;
    display: none;
    top: 40%; }
  .block-media .slick-dots {
    position: absolute;
    height: 3px;
    background: rgba(255, 255, 255, 0.2);
    bottom: 0px;
    width: 100%;
    top: 0;
    left: 0px;
    padding: 0px;
    margin: 0px;
    list-style-type: none; }
  .block-media .slick-dots li button {
    display: none; }
  .block-media .slick-dots li {
    float: left;
    width: 0px;
    height: 3px;
    background: rgba(25, 27, 48, 0.6);
    position: absolute;
    left: 0px;
    top: 0px; }
  .block-media .slick-dots li.slick-active {
    width: 100%;
    animation: ProgressDots 11s both; }

.block-text {
  width: 200px;
  background-color: #2c3e50;
  position: absolute;
  top: 30%;
  right: 20%;
  margin: 0 auto;
  z-index: 3; }

.carousel-bg .slick-list {
  padding: 0 !important; }

.carousel-text {
  font-weight: 100;
  padding: 20px; }
  .carousel-text .title {
    color: #bdc3c7;
    font-size: 1.05em;
    font-weight: 300;
    text-transform: uppercase; }
  .carousel-text .headline {
    color: #fff;
    text-align: justify; }

.banner-wrapper {
  width: 100%; }
  @media (min-width: 992px) {
    .banner-wrapper {
      height: 100vh;
      min-height: auto;
      padding-bottom: 0; } }
  @media (min-width: 1200px) {
    .banner-wrapper {
      height: 100%; } }

.hero-banner {
  height: 100vh;
  position: relative;
  overflow: hidden; }
  @media (min-width: 992px) {
    .hero-banner {
      padding-bottom: 0; } }
  .hero-banner .block-media {
    position: relative;
    display: none; }
    @media (min-width: 1200px) {
      .hero-banner .block-media {
        display: block; } }
    .hero-banner .block-media:before {
      content: "";
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    .hero-banner .block-media video {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .hero-banner .container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    @media (min-width: 1300px) {
      .hero-banner .container {
        max-width: 1300px; } }
  .hero-banner .cta {
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0; }
    @media (min-width: 480px) {
      .hero-banner .cta {
        padding-top: 5%; } }
    @media (min-width: 992px) {
      .hero-banner .cta {
        padding-top: 0; } }
    .hero-banner .cta .we {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 30px;
      line-height: 1.875rem;
      color: #fc0;
      padding-bottom: 5px;
      font-weight: 300;
      display: block; }
      @media (min-width: 480px) {
        .hero-banner .cta .we {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 40px;
          line-height: 2.5rem;
          font-weight: 500; } }
      @media (min-width: 768px) {
        .hero-banner .cta .we {
          font-size: 40px;
          font-size: 2.5rem;
          line-height: 40px;
          line-height: 2.5rem; } }
    .hero-banner .cta h2 {
      font-size: 35px;
      font-size: 2.1875rem;
      line-height: 40px;
      line-height: 2.5rem;
      color: white;
      font-weight: 500;
      margin-bottom: 20px; }
      @media (min-width: 480px) {
        .hero-banner .cta h2 {
          font-size: 60px;
          font-size: 3.75rem;
          line-height: 70px;
          line-height: 4.375rem;
          margin-bottom: 40px; } }
      @media (min-width: 1200px) {
        .hero-banner .cta h2 {
          font-size: 65px;
          font-size: 4.0625rem;
          line-height: 70px;
          line-height: 4.375rem; } }
      @media (min-width: 1700px) {
        .hero-banner .cta h2 {
          font-size: 80px;
          font-size: 5rem;
          line-height: 90px;
          line-height: 5.625rem; } }
    @media (min-width: 992px) {
      .hero-banner .cta.caseshape h2 {
        font-size: 58px;
        font-size: 3.625rem;
        line-height: 70px;
        line-height: 4.375rem; } }

.hero-content-wrap {
  width: 95%;
  height: auto;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media (min-width: 768px) {
    .hero-content-wrap {
      width: 500px;
      height: 500px;
      text-align: center; } }
  @media (min-width: 1200px) {
    .hero-content-wrap {
      width: 560px;
      height: 560px;
      top: 25%; } }
  .hero-content-wrap::before {
    content: "";
    width: 560px;
    height: 560px;
    display: block;
    animation: vanishIn 2s ease-in-out; }
  .hero-content-wrap h1 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 50px;
    line-height: 3.125rem;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0; }
    @media (min-width: 768px) {
      .hero-content-wrap h1 {
        font-size: 50px;
        font-size: 3.125rem;
        line-height: 70px;
        line-height: 4.375rem; } }
    .hero-content-wrap h1 span {
      text-decoration: underline;
      font-weight: 900;
      color: white; }
  .hero-content-wrap .slogan {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 26px;
    line-height: 1.625rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 15px; }
    @media (min-width: 768px) {
      .hero-content-wrap .slogan {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem; } }
  .hero-content-wrap .hero-content {
    position: absolute;
    top: 52%;
    left: 0;
    right: 0;
    padding: 0 15px;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    @media (min-width: 768px) {
      .hero-content-wrap .hero-content {
        padding: 0 45px; } }
    .hero-content-wrap .hero-content .hero-content-block {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      padding: 0 15px;
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); }
      @media (min-width: 768px) {
        .hero-content-wrap .hero-content .hero-content-block {
          padding: 0 45px; } }
    .hero-content-wrap .hero-content .button-animi {
      margin: 10px 0; }
      .hero-content-wrap .hero-content .button-animi a:before {
        background: transparent; }
      .hero-content-wrap .hero-content .button-animi a:hover::before {
        width: 75px;
        background: rgba(255, 102, 0, 0.5); }
      .hero-content-wrap .hero-content .button-animi a svg path {
        fill: white; }
  .hero-content-wrap .slick-active .cta .we,
  .hero-content-wrap .slick-active .cta h1 {
    animation: fadeInDown 1s both 1s; }
  .hero-content-wrap .slick-active .shapes {
    transform: scale(1);
    opacity: 1; }
    @media (min-width: 480px) {
      .hero-content-wrap .slick-active .shapes {
        width: 100%; } }
  .hero-content-wrap .slick-active .button-animi {
    animation: fadeInUp 1s both;
    animation-delay: 1s; }

.image-section {
  height: 100%;
  display: none; }
  @media (min-width: 992px) {
    .image-section {
      display: block; } }
  @media (max-width: 767px) {
    .image-section {
      position: relative;
      height: auto; } }
  .image-section .w-100 {
    height: 100%; }
    @media (min-width: 992px) {
      .image-section .w-100 {
        padding-left: 10%; } }
  @media (max-width: 991px) {
    .image-section.case-shapes svg .circle {
      display: none; } }
  .image-section.case-shapes svg .circle path {
    animation: bulb 3s infinite linear; }
  .image-section.case-shapes svg .dots path:nth-of-type(1n) {
    animation: nudge-up 5s infinite alternate; }
  .image-section.case-shapes svg .head {
    animation: shake-h 8s infinite alternate; }
  .image-section.case-shapes svg .rajan {
    animation: nudge 7s infinite; }
  .image-section.service-banner svg .head {
    animation: shake-h 8s infinite alternate; }
  .image-section.service-banner svg .highlight {
    animation: nudge-up 5s infinite linear; }
  .image-section.service-banner svg .code-line path:nth-of-type(2n) {
    animation: nudge 5s infinite linear; }
  .image-section.service-banner svg .code-line path:nth-of-type(1) {
    animation: nudge 5s infinite linear; }
  .image-section.impact svg {
    width: 90%; }
    .image-section.impact svg .block-2 {
      animation: nudge-up 5s infinite linear; }
    .image-section.impact svg .head {
      animation: shake-h 8s infinite alternate; }

.slider-image {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0; }
  .slider-image .slick-list {
    height: 100%; }
    .slider-image .slick-list .slick-track {
      height: 100%; }

.screens {
  position: absolute;
  top: 65%;
  left: 0%;
  width: 90%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-60%); }
  @media (min-width: 480px) {
    .screens {
      width: 80%; } }
  @media (min-width: 992px) {
    .screens {
      width: 100%;
      right: inherit; } }
  .screens img {
    max-width: 100%;
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    display: inline-block;
    transition: all 500ms ease-in-out;
    transform: scale(1.25, 1.25);
    animation-delay: 5s; }
  .screens .screen-slide {
    max-width: 250px;
    background: black;
    overflow: hidden;
    position: absolute;
    top: -150px;
    z-index: 1;
    left: 25%;
    padding: 55% 40%;
    left: 0;
    right: 0;
    margin: 0 auto;
    -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    transform: translate3d(0, 0, 0); }
    @media (min-width: 480px) {
      .screens .screen-slide {
        max-width: 280px;
        top: -10%;
        padding: 30% 24%; } }
    @media (min-width: 992px) {
      .screens .screen-slide {
        padding: 38% 25%;
        max-width: 300px; } }
    @media (min-width: 1200px) {
      .screens .screen-slide {
        max-width: 320px;
        padding: 31% 20%; } }
    @media (min-width: 1700px) {
      .screens .screen-slide {
        max-width: 375px;
        padding: 31% 20%; } }
    .screens .screen-slide > div {
      width: 100%;
      position: absolute;
      top: 0%;
      left: 0;
      transform: translate3d(0, 0, 0); }
    .screens .screen-slide .mover {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      animation: moveSlideshow 7s linear infinite alternate;
      animation-delay: 1s; }
      .screens .screen-slide .mover img {
        max-width: 100%; }
  .screens .screen-thumb1 {
    width: 35%;
    position: relative;
    left: 0%;
    top: 50px;
    display: none; }
    @media (min-width: 480px) {
      .screens .screen-thumb1 {
        width: 30%;
        top: 35px;
        display: inline-block; } }
    @media (min-width: 992px) {
      .screens .screen-thumb1 {
        width: 35%;
        top: 50px; } }
    @media (min-width: 1200px) {
      .screens .screen-thumb1 {
        width: 30%;
        left: 10%; } }
  .screens .screen-thumb2 {
    width: 35%;
    position: absolute;
    right: 0;
    top: -30%;
    display: none; }
    @media (min-width: 480px) {
      .screens .screen-thumb2 {
        width: 30%;
        display: inline-block; } }
    @media (min-width: 992px) {
      .screens .screen-thumb2 {
        width: 35%;
        right: 7%; } }
    @media (min-width: 1200px) {
      .screens .screen-thumb2 {
        width: 30%;
        right: 15%; } }
  .screens .screen99 {
    width: 200px;
    margin: 0 auto;
    position: relative;
    left: 0%;
    top: 50px; }
    @media (min-width: 480px) {
      .screens .screen99 {
        width: 220px;
        top: 35px; } }
    @media (min-width: 992px) {
      .screens .screen99 {
        width: 250px; } }
    @media (min-width: 1200px) {
      .screens .screen99 {
        width: 280px;
        top: 0; } }
    @media (min-width: 1700px) {
      .screens .screen99 {
        width: 350px;
        left: -5%; } }
    .screens .screen99 img {
      max-width: 100%;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      -ms-border-radius: 30px;
      border-radius: 30px; }

.slick-slide.slick-active .screens img {
  transform: scale(1, 1); }

.product-slider-wrapper {
  background: #ff6600;
  padding: 100px 0;
  overflow: hidden;
  position: relative; }
  @media (min-width: 1200px) {
    .product-slider-wrapper {
      height: 100vh; } }
  .product-slider-wrapper h2 {
    color: white;
    display: block; }
    .product-slider-wrapper h2.big-text {
      font-size: 50px;
      font-size: 3.125rem;
      line-height: 50px;
      line-height: 3.125rem;
      font-weight: 900;
      color: rgba(255, 255, 255, 0.2);
      position: relative;
      left: 0;
      top: 10px; }
      @media (min-width: 768px) {
        .product-slider-wrapper h2.big-text {
          font-size: 70px;
          font-size: 4.375rem;
          line-height: 60px;
          line-height: 3.75rem; } }
      @media (min-width: 1200px) {
        .product-slider-wrapper h2.big-text {
          font-size: 80px;
          font-size: 5rem;
          line-height: 70px;
          line-height: 4.375rem; } }
      @media (min-width: 1440px) {
        .product-slider-wrapper h2.big-text {
          font-size: 130px;
          font-size: 8.125rem;
          line-height: 120px;
          line-height: 7.5rem;
          left: -14%; } }
      @media (min-width: 1700px) {
        .product-slider-wrapper h2.big-text {
          font-size: 200px;
          font-size: 12.5rem;
          line-height: 160px;
          line-height: 10rem;
          top: -10px;
          left: -20%; } }
      .product-slider-wrapper h2.big-text br {
        display: none; }
        @media (min-width: 992px) {
          .product-slider-wrapper h2.big-text br {
            display: block; } }
  .product-slider-wrapper small {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 20px;
    line-height: 1.25rem;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    width: 100%; }
  @media (min-width: 1200px) {
    .product-slider-wrapper .slick-initialized .slick-slide {
      overflow: inherit; } }
  @media (min-width: 1200px) {
    .product-slider-wrapper .slick-slider .slick-list {
      max-width: 1170px;
      margin: 0 auto;
      overflow: inherit;
      padding: 0 15px !important; } }
  @media (min-width: 1200px) {
    .product-slider-wrapper .container.container-product {
      max-width: 100%; } }
  .product-slider-wrapper .product-slider {
    padding-top: 30px; }
    .product-slider-wrapper .product-slider .slick-dots {
      max-width: 1170px;
      margin: 25px auto 0 auto;
      display: none;
      opacity: 0; }
    .product-slider-wrapper .product-slider .thumb-img {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 200px;
      margin: 0 5px;
      display: block;
      position: relative;
      filter: grayscale(0.5);
      -webkit-filter: grayscale(0.7); }
      @media (min-width: 768px) {
        .product-slider-wrapper .product-slider .thumb-img {
          height: 380px; } }
      @media (min-width: 992px) {
        .product-slider-wrapper .product-slider .thumb-img {
          width: 540px;
          height: 330px;
          margin: 0;
          box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.4); } }
      .product-slider-wrapper .product-slider .thumb-img:before {
        content: "";
        background-color: rgba(25, 27, 48, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: background-color 0.3s ease-in-out;
        -moz-transition: background-color 0.3s ease-in-out;
        -ms-transition: background-color 0.3s ease-in-out;
        transition: background-color 0.3s ease-in-out; }
    .product-slider-wrapper .product-slider figcaption {
      color: White;
      margin-top: -80px; }
      @media (min-width: 992px) {
        .product-slider-wrapper .product-slider figcaption {
          margin-left: 15px;
          margin-top: 0; } }
      .product-slider-wrapper .product-slider figcaption h2 {
        position: relative;
        font-size: 50px;
        font-size: 3.125rem;
        line-height: 60px;
        line-height: 3.75rem;
        margin-top: 20px; }
        @media (min-width: 768px) {
          .product-slider-wrapper .product-slider figcaption h2 {
            font-size: 80px;
            font-size: 5rem;
            line-height: 70px;
            line-height: 4.375rem;
            letter-spacing: 10px; } }
        @media (min-width: 992px) {
          .product-slider-wrapper .product-slider figcaption h2 {
            font-size: 80px;
            font-size: 5rem;
            line-height: 70px;
            line-height: 4.375rem;
            letter-spacing: 10px;
            left: -55%;
            margin-top: 0;
            text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.4);
            -webkit-text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.4); } }
  .product-slider-wrapper figure:hover .thumb-img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0); }
    .product-slider-wrapper figure:hover .thumb-img:before {
      background-color: transparent; }

.case-slider-wrapper {
  background: rgba(102, 102, 102, 0.05);
  padding: 100px 0;
  overflow: hidden;
  position: relative; }
  @media (min-width: 1200px) {
    .case-slider-wrapper .slick-initialized .slick-slide {
      overflow: inherit; } }
  @media (min-width: 1200px) {
    .case-slider-wrapper .slick-slider .slick-list {
      max-width: 1170px;
      margin: 0 auto;
      overflow: inherit;
      padding: 0 15px !important; } }
  @media (min-width: 1200px) {
    .case-slider-wrapper .container.container-casestudy {
      max-width: 100%; } }
  .case-slider-wrapper .case-slider {
    padding-top: 30px; }
    .case-slider-wrapper .case-slider .slick-dots {
      max-width: 1170px;
      margin: 25px auto 0 auto; }
    .case-slider-wrapper .case-slider .thumb-img {
      margin: 0 auto; }
      .case-slider-wrapper .case-slider .thumb-img img {
        max-width: 100%; }
  .case-slider-wrapper .slick-dots li button {
    background: grey; }

.index-fallback {
  background-image: url("../images/home-fallback.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  .index-fallback:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8); }

.about-wrap {
  padding: 100px 0; }
  @media (min-width: 1440px) {
    .about-wrap .container {
      max-width: 1300px; } }
  .about-wrap .cf-info-front h2 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem;
    font-weight: 300;
    color: #191b30;
    margin-bottom: 20px; }
    .about-wrap .cf-info-front h2 span {
      color: #ff6600;
      font-weight: 500; }
  .about-wrap .cf-info-front p {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 35px;
    line-height: 2.1875rem;
    font-weight: 200; }
    @media (min-width: 768px) {
      .about-wrap .cf-info-front p {
        font-size: 35px;
        font-size: 2.1875rem;
        line-height: 55px;
        line-height: 3.4375rem; } }
  .about-wrap.about-inner {
    background: white; }

.cf-info {
  padding: 50px 15px 0 15px; }
  @media (min-width: 768px) {
    .cf-info {
      padding: 50px 0 0 0; } }
  @media (min-width: 992px) {
    .cf-info {
      padding: 120px 0 0 15px; } }
  .cf-info small {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    line-height: 1.125rem;
    color: #ff6600;
    text-transform: uppercase; }
  .cf-info h2 {
    font-family: "stolzl", sans-serif;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    line-height: 1.5rem;
    color: #191b30;
    margin: 5px 0 40px 0; }
    .cf-info h2 span {
      font-weight: 300; }
      .cf-info h2 span strong {
        color: #ff6600;
        text-decoration: underline;
        font-weight: 900;
        text-transform: uppercase; }
    @media (min-width: 480px) {
      .cf-info h2 {
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 28px;
        line-height: 1.75rem; } }
    @media (min-width: 768px) {
      .cf-info h2 {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 36px;
        line-height: 2.25rem; } }
  .cf-info.about-info {
    text-align: left;
    padding: 0 15px; }
    .cf-info.about-info h2 {
      margin-bottom: 40px;
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 40px;
      line-height: 2.5rem; }
      @media (min-width: 768px) {
        .cf-info.about-info h2 {
          font-size: 50px;
          font-size: 3.125rem;
          line-height: 60px;
          line-height: 3.75rem; } }
    .cf-info.about-info p {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 30px;
      line-height: 1.875rem; }
    .cf-info.about-info .tagline {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 24px;
      line-height: 1.5rem;
      margin-bottom: 50px; }
  @media (min-width: 768px) {
    .cf-info.service-info {
      padding: 0; } }
  .cf-info.service-info h2 {
    margin-bottom: 20px;
    font-size: 45px;
    font-size: 2.8125rem;
    line-height: 45px;
    line-height: 2.8125rem; }
    @media (min-width: 992px) {
      .cf-info.service-info h2 {
        font-size: 80px;
        font-size: 5rem;
        line-height: 80px;
        line-height: 5rem; } }
    .cf-info.service-info h2 span {
      display: block;
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 50px;
      line-height: 3.125rem; }
  .cf-info.service-info p {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 26px;
    line-height: 1.625rem;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .cf-info.service-info p {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem; } }
  .cf-info .info-style {
    padding-left: 0;
    position: relative;
    padding-bottom: 25px; }
    @media (min-width: 1200px) {
      .cf-info .info-style .button-animi {
        text-align: left;
        margin-right: 2px; } }

@media (min-width: 1200px) {
  .cf-studio-slide {
    padding-right: 75px; } }

.cf-studio-slide .gif-block {
  margin-top: -30px; }
  .cf-studio-slide .gif-block img {
    max-width: 100%; }

.cf-studio-slide .studio-text {
  font-size: 80px;
  font-size: 5rem;
  line-height: 80px;
  line-height: 5rem;
  color: #191b30;
  font-weight: 900;
  position: relative;
  top: -50px;
  right: -35%; }

.service-wrap {
  position: relative;
  padding: 50px 0 100px 0; }
  @media (min-width: 1200px) {
    .service-wrap {
      padding: 0 0 50px 0; } }
  @media (min-width: 1200px) {
    .service-wrap.service-wrap-home {
      height: 100vh; } }
  .service-wrap.service-wrap-home .service-info {
    text-align: left;
    padding: 0 15px; }
    @media (min-width: 1200px) {
      .service-wrap.service-wrap-home .service-info {
        position: relative;
        top: 50%;
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); } }
    .service-wrap.service-wrap-home .service-info .service-title small {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 30px;
      line-height: 1.875rem;
      font-weight: 200; }
    .service-wrap.service-wrap-home .service-info .service-title h2 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 50px;
      line-height: 3.125rem;
      margin-bottom: 25px; }
      @media (min-width: 768px) {
        .service-wrap.service-wrap-home .service-info .service-title h2 {
          font-size: 80px;
          font-size: 5rem;
          line-height: 80px;
          line-height: 5rem;
          margin-bottom: 0; } }
    .service-wrap.service-wrap-home .service-info p {
      margin-bottom: 30px; }
      @media (min-width: 768px) {
        .service-wrap.service-wrap-home .service-info p {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 30px;
          line-height: 1.875rem; } }
      .service-wrap.service-wrap-home .service-info p strong {
        color: #191b30; }
  @media (max-width: 767px) {
    .service-wrap {
      overflow: hidden; } }
  .service-wrap .service-info {
    text-align: center;
    padding-top: 50px; }
    .service-wrap .service-info .service-title small {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 40px;
      line-height: 2.5rem;
      font-weight: 500; }
    .service-wrap .service-info h2 {
      font-size: 60px;
      font-size: 3.75rem;
      line-height: 60px;
      line-height: 3.75rem;
      font-weight: 500;
      margin-bottom: 50px; }
      .service-wrap .service-info h2 span {
        color: #ff6600; }
    .service-wrap .service-info p {
      margin-bottom: 70px; }
      @media (min-width: 1200px) {
        .service-wrap .service-info p {
          font-size: 22px;
          font-size: 1.375rem;
          line-height: 34px;
          line-height: 2.125rem; } }
    .service-wrap .service-info .triangle-big-shape {
      position: absolute;
      left: 0%;
      top: 0%;
      z-index: -1;
      display: block;
      width: 300px; }
      @media (min-width: 768px) {
        .service-wrap .service-info .triangle-big-shape {
          width: auto;
          top: -90%; } }
      .service-wrap .service-info .triangle-big-shape img {
        max-width: 100%; }
    .service-wrap .service-info .triangle-big-shape2 {
      display: none;
      position: absolute;
      left: 70%;
      bottom: 20%;
      z-index: -1; }
      @media (min-width: 1440px) {
        .service-wrap .service-info .triangle-big-shape2 {
          display: block; } }

.services-shapes {
  width: 96%;
  height: 550px;
  position: absolute;
  top: 25%;
  z-index: -1;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none; }
  @media (min-width: 768px) {
    .services-shapes {
      display: block;
      top: -15%;
      height: 550px;
      left: 0;
      right: 0; } }
  @media (min-width: 1200px) {
    .services-shapes {
      left: -2%;
      top: -30%;
      right: inherit; } }
  @media (min-width: 1200px) {
    .services-shapes {
      left: inherit;
      top: -6%; } }
  @media (min-width: 1700px) {
    .services-shapes {
      width: 80%; } }

.service-list {
  position: relative; }
  .service-list small {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 30px;
    line-height: 1.875rem;
    color: #292929; }
  .service-list ul {
    margin: 20px 0 0 0;
    padding: 0;
    list-style-type: none; }
    .service-list ul li {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 30px;
      line-height: 1.875rem;
      font-weight: 500;
      color: #292929;
      margin: 20px 0;
      float: left; }
      .service-list ul li img {
        width: 40px;
        margin-right: 10px; }

.service-block {
  width: 100%;
  display: inline-block;
  position: relative;
  margin: 15px 0 20px 0;
  text-align: center;
  padding: 20px 10px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s; }
  @media (min-width: 768px) {
    .service-block {
      width: 47%; } }
  @media (min-width: 1200px) {
    .service-block {
      margin: 15px 0 20px 0;
      padding: 35px 20px; } }
  @media (min-width: 1700px) {
    .service-block {
      margin: 15px 0 35px 0;
      padding-right: 5%; } }
  .service-block:nth-of-type(2n) {
    position: relative; }
    @media (min-width: 768px) {
      .service-block:nth-of-type(2n) {
        margin-left: 5%;
        top: 30px; } }
    @media (min-width: 1200px) {
      .service-block:nth-of-type(2n) {
        margin-left: 20px; } }
    @media (min-width: 1700px) {
      .service-block:nth-of-type(2n) {
        margin-left: 45px; } }
  .service-block h3 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    line-height: 1.5rem;
    font-weight: 300;
    color: white; }
  .service-block.svc-1 {
    background: #10bfeb;
    /* Old browsers */
    background: -moz-linear-gradient(left, #10bfeb 0%, #10b5eb 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #10bfeb), color-stop(100%, #10b5eb));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #10bfeb 0%, #10b5eb 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #10bfeb 0%, #10b5eb 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #10bfeb 0%, #10b5eb 100%);
    /* IE10+ */
    background: linear-gradient(to right, #10bfeb 0%, #10b5eb 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */ }
  .service-block.svc-2 {
    background: #e810eb;
    /* Old browsers */
    background: -moz-linear-gradient(left, #e810eb 0%, #f82bfa 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e810eb), color-stop(100%, #f82bfa));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #e810eb 0%, #f82bfa 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #e810eb 0%, #f82bfa 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #e810eb 0%, #f82bfa 100%);
    /* IE10+ */
    background: linear-gradient(to right, #e810eb 0%, #f82bfa 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */ }
  .service-block.svc-3 {
    background: #edaf1a;
    /* Old browsers */
    background: -moz-linear-gradient(left, #edaf1a 0%, #fcc133 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #edaf1a), color-stop(100%, #fcc133));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #edaf1a 0%, #fcc133 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #edaf1a 0%, #fcc133 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #edaf1a 0%, #fcc133 100%);
    /* IE10+ */
    background: linear-gradient(to right, #edaf1a 0%, #fcc133 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */ }
  .service-block.svc-4 {
    background: #34bc0a;
    /* Old browsers */
    background: -moz-linear-gradient(left, #34bc0a 0%, #298f09 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #34bc0a), color-stop(100%, #298f09));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #34bc0a 0%, #298f09 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #34bc0a 0%, #298f09 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #34bc0a 0%, #298f09 100%);
    /* IE10+ */
    background: linear-gradient(to right, #34bc0a 0%, #298f09 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */ }
  .service-block .svg {
    width: 100px;
    height: 60px;
    margin-bottom: 15px; }

.work-with {
  position: relative;
  padding: 50px 0;
  overflow: hidden; }
  @media (min-width: 1200px) {
    .work-with {
      padding: 200px 0 150px 0; } }
  @media (min-width: 1200px) {
    .work-with .container {
      max-width: 1300px; } }
  .work-with h2 {
    color: #191b30;
    margin: 20px 0; }
  .work-with .triangles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center; }
    .work-with .triangles .svg {
      max-width: 100%;
      opacity: 0.03; }
  .work-with .whowe {
    text-align: center;
    position: relative;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .work-with .whowe {
        margin-bottom: 80px; } }
    @media (min-width: 992px) {
      .work-with .whowe {
        padding: 0 10%; } }
  .work-with .partner-list {
    text-align: center; }
  .work-with .partner {
    width: 150px;
    text-align: center;
    display: inline-block;
    margin: 10px 0;
    padding: 20px;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
    @media (min-width: 768px) {
      .work-with .partner {
        width: 200px; } }
    .work-with .partner:before {
      pointer-events: none;
      position: absolute;
      z-index: -1;
      content: '';
      top: 100%;
      left: 5%;
      height: 10px;
      width: 90%;
      opacity: 0.5;
      background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: transform, opacity;
      transition-property: transform, opacity;
      -webkit-transform: translateY(5px);
      transform: translateY(5px); }
    @media (min-width: 768px) {
      .work-with .partner {
        margin: 20px 50px;
        padding: 15px 10px 10px 10px; } }
    @media (min-width: 1200px) {
      .work-with .partner {
        padding: 20px 15px 10px 15px; } }
    .work-with .partner img {
      max-width: 100%; }

div#parallax-bg-4 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 92%; }
  @media (min-width: 1700px) {
    div#parallax-bg-4 {
      margin-top: 110%; } }

.arrow-cog {
  position: absolute;
  top: 40%;
  right: 0;
  width: 100%;
  overflow: hidden; }
  @media (min-width: 768px) {
    .arrow-cog {
      top: 20%; } }
  .arrow-cog svg {
    height: 300px;
    margin-left: -150px; }
    @media (min-width: 768px) {
      .arrow-cog svg {
        height: 500px; } }
    @media (min-width: 992px) {
      .arrow-cog svg {
        height: auto; } }
  .arrow-cog path {
    fill: #004777; }
  .arrow-cog .dashes {
    animation: bulb 5s infinite; }
  .arrow-cog .smalldot {
    animation: bulb 5s infinite; }
  .arrow-cog .circle-border {
    -webkit-animation: sparkle 7s linear infinite;
    animation: sparkle 7s linear infinite; }
  .arrow-cog .small-circle {
    animation: nudge 5s infinite; }
  .arrow-cog .small-circle1 {
    animation: bulb 5s infinite; }
  .arrow-cog .small-circle2 {
    -webkit-animation: sparkle 5s infinite;
    animation: sparkle 5s infinite; }

.testimonial-wrap {
  background: #ff6600;
  position: relative; }
  @media (min-width: 992px) {
    .testimonial-wrap {
      height: 100vh; } }
  .testimonial-wrap h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 40px;
    line-height: 2.5rem;
    font-weight: 900;
    color: white;
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .testimonial-wrap h2 {
        font-size: 80px;
        font-size: 5rem;
        line-height: 80px;
        line-height: 5rem; } }
    @media (min-width: 992px) {
      .testimonial-wrap h2 {
        font-size: 100px;
        font-size: 6.25rem;
        line-height: 100px;
        line-height: 6.25rem;
        text-align: right;
        margin-bottom: 0; } }
  .testimonial-wrap .testimonial-content {
    padding: 100px 0 50px 0; }
    @media (min-width: 992px) {
      .testimonial-wrap .testimonial-content {
        position: relative;
        top: 50%;
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); } }
    .testimonial-wrap .testimonial-content .testimonial-title {
      position: relative; }

.testimonial {
  position: relative; }
  .testimonial p {
    font-size: 16px;
    font-size: 1rem;
    line-height: 26px;
    line-height: 1.625rem;
    color: white; }
  .testimonial .testimonial-post {
    position: relative; }
    @media (min-width: 768px) {
      .testimonial .testimonial-post {
        margin: 0px 0 40px 0; } }
    .testimonial .testimonial-post .slick-list {
      padding: 0 !important; }
    .testimonial .testimonial-post button {
      position: absolute;
      border: none; }
      .testimonial .testimonial-post button.slick-prev {
        left: -50px;
        top: 20px;
        opacity: 0.5;
        background: url("../images/left-arrow.svg") no-repeat;
        display: block;
        width: 50px;
        height: 50px;
        font-size: 0; }
        @media (min-width: 992px) {
          .testimonial .testimonial-post button.slick-prev {
            left: -170px; } }
        .testimonial .testimonial-post button.slick-prev:hover {
          opacity: 1; }
      .testimonial .testimonial-post button.slick-next {
        right: -50px;
        top: 20px;
        opacity: 0.5;
        background: url("../images/right-arrow.svg") no-repeat;
        display: block;
        width: 50px;
        height: 50px;
        font-size: 0; }
        @media (min-width: 992px) {
          .testimonial .testimonial-post button.slick-next {
            right: -170px; } }
        .testimonial .testimonial-post button.slick-next:hover {
          opacity: 1; }
    .testimonial .testimonial-post .company-name {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 30px;
      line-height: 1.875rem;
      font-weight: 500;
      display: block;
      color: white;
      margin: 20px 0 20px 0; }
    .testimonial .testimonial-post .client-name {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 20px;
      line-height: 1.25rem;
      font-weight: 500;
      display: block;
      color: black;
      margin: 50px 0 5px 0; }
    .testimonial .testimonial-post .designation {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 24px;
      line-height: 1.5rem;
      font-weight: 400;
      opacity: 0.9;
      color: rgba(0, 0, 0, 0.9); }
  .testimonial .slick-dots {
    margin: 25px 0 0 0;
    padding: 0;
    list-style-type: none; }
    .testimonial .slick-dots li {
      list-style-type: none;
      display: inline-block;
      margin: 5px; }
      .testimonial .slick-dots li button {
        width: 15px;
        height: 15px;
        position: relative;
        font-size: 0px;
        font-size: 0rem;
        line-height: 0px;
        line-height: 0rem;
        color: white;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        -ms-border-radius: 100%;
        border-radius: 100%;
        opacity: 0.5; }
      .testimonial .slick-dots li.slick-active button {
        opacity: 1; }

.reveal-block {
  position: relative;
  background: white; }

.reveal-block::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ff6600;
  transition-property: transform;
  transition-duration: 0.5s; }

.reveal-block.right::before {
  transform: scaleX(0);
  transform-origin: 0% 100%; }

.reveal-block.right.aos-animate::before {
  transform: scaleX(1); }

.about-section {
  background: white; }
  .about-section .header .container {
    position: relative; }
    @media (min-width: 1300px) {
      .about-section .header .container {
        max-width: 1300px; } }

.who-we-hero {
  padding: 150px 0 150px 0;
  position: relative; }
  @media (min-width: 768px) {
    .who-we-hero {
      height: 100vh; } }
  .who-we-hero:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -200px;
    background: white;
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .who-we-hero .about-intro {
    position: relative; }
    @media (min-width: 768px) {
      .who-we-hero .about-intro {
        top: 50%;
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); } }
    .who-we-hero .about-intro .about-tagline {
      position: relative; }
      .who-we-hero .about-intro .about-tagline:before {
        content: "";
        display: block;
        position: absolute;
        width: 300px;
        height: 300px;
        background: white;
        top: -40%;
        right: 0%;
        left: 0;
        bottom: 0;
        z-index: -1;
        margin: 0 auto;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        -ms-border-radius: 100%;
        border-radius: 100%;
        -moz-box-shadow: 0 40px 30px 0 rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 40px 30px 0 rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 40px 30px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 40px 30px 0 rgba(0, 0, 0, 0.2);
        -webkit-animation: slide-in-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-bottom 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
        @media (min-width: 768px) {
          .who-we-hero .about-intro .about-tagline:before {
            width: 400px;
            height: 400px; } }
        @media (min-width: 992px) {
          .who-we-hero .about-intro .about-tagline:before {
            display: block;
            right: -10%;
            left: inherit; } }
        @media (min-width: 1200px) {
          .who-we-hero .about-intro .about-tagline:before {
            top: -30%;
            width: 500px;
            height: 500px; } }
    .who-we-hero .about-intro .big-text {
      font-size: 120px;
      font-size: 7.5rem;
      line-height: 180px;
      line-height: 11.25rem;
      font-weight: 900;
      position: absolute;
      top: -50%;
      color: #f1f1f1;
      text-align: right;
      opacity: 0.5;
      z-index: -2;
      display: none; }
    .who-we-hero .about-intro h2 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 40px;
      line-height: 2.5rem;
      font-weight: 500; }
      @media (min-width: 992px) {
        .who-we-hero .about-intro h2 {
          font-size: 40px;
          font-size: 2.5rem;
          line-height: 60px;
          line-height: 3.75rem;
          text-align: right; } }
      @media (min-width: 1200px) {
        .who-we-hero .about-intro h2 {
          font-size: 50px;
          font-size: 3.125rem;
          line-height: 80px;
          line-height: 5rem; } }
      .who-we-hero .about-intro h2 span {
        display: block;
        color: #ff6600; }
        @media (min-width: 768px) {
          .who-we-hero .about-intro h2 span {
            font-size: 80px;
            font-size: 5rem;
            line-height: 80px;
            line-height: 5rem; } }
    .who-we-hero .about-intro .about-slogan {
      padding-top: 100px; }
      @media (min-width: 992px) {
        .who-we-hero .about-intro .about-slogan {
          padding-left: 100px; } }
  .who-we-hero.careers-hero {
    padding: 50px 0 100px 0; }
    @media (min-width: 768px) {
      .who-we-hero.careers-hero {
        padding: 150px 0; } }
    .who-we-hero.careers-hero .about-intro .about-slogan p {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 45px;
      line-height: 2.8125rem;
      font-weight: 200; }
      .who-we-hero.careers-hero .about-intro .about-slogan p span {
        font-weight: 300;
        color: #ff6600; }
      @media (min-width: 768px) {
        .who-we-hero.careers-hero .about-intro .about-slogan p {
          font-size: 55px;
          font-size: 3.4375rem;
          line-height: 75px;
          line-height: 4.6875rem; } }
    .who-we-hero.careers-hero .about-tagline::before {
      display: none; }
  .who-we-hero.careers-details {
    background: #191b30;
    height: auto;
    padding: 150px 0 100px 0; }
    @media (min-width: 768px) {
      .who-we-hero.careers-details {
        padding: 250px 0 100px 0; } }
    .who-we-hero.careers-details:before {
      display: none; }
    .who-we-hero.careers-details h2 {
      color: #ffcc00;
      text-align: left; }
    .who-we-hero.careers-details p {
      color: rgba(255, 255, 255, 0.7); }
    .who-we-hero.careers-details .about-slogan {
      padding-top: 0; }
    .who-we-hero.careers-details .button-animi a {
      color: white; }
      .who-we-hero.careers-details .button-animi a svg path {
        fill: white; }
      .who-we-hero.careers-details .button-animi a:hover {
        color: #191b30; }
        .who-we-hero.careers-details .button-animi a:hover:before {
          background: white; }
        .who-we-hero.careers-details .button-animi a:hover svg path {
          fill: #191b30; }

.cf-players {
  background-image: url("../images/cf-players.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 800px;
  position: relative;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%); }
  .cf-players:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); }
  .cf-players .video-caption {
    background: rgba(255, 255, 255, 0.8);
    width: 100%;
    padding: 20px;
    position: absolute;
    top: 0; }
    @media (min-width: 480px) {
      .cf-players .video-caption {
        background: white;
        width: 400px;
        padding: 50px 30px;
        top: 50%;
        right: 50px;
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); } }
    .cf-players .video-caption p {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 36px;
      line-height: 2.25rem;
      font-weight: 200;
      color: #191b30; }
      @media (min-width: 768px) {
        .cf-players .video-caption p {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 45px;
          line-height: 2.8125rem; } }

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

.about-video .container {
  position: relative; }
  @media (min-width: 1200px) {
    .about-video .container {
      max-width: 1300px; } }
  .about-video .container video {
    width: 100%; }
  .about-video .container .video-caption {
    background: white;
    width: 400px;
    padding: 50px;
    position: absolute;
    top: 50%;
    right: 50px;
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .about-video .container .video-caption p {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 45px;
      line-height: 2.8125rem;
      font-weight: 200;
      color: #191b30; }

.about-intro h2 {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 45px;
  line-height: 2.8125rem;
  letter-spacing: 1px;
  color: #191b30;
  font-weight: 400; }
  @media (min-width: 768px) {
    .about-intro h2 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 90px;
      line-height: 5.625rem; } }

.about-intro p {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem; }

.about-intro .container {
  position: relative; }
  @media (min-width: 1700px) {
    .about-intro .container {
      max-width: 1300px; } }

.about-image-block {
  padding-top: 10%;
  margin-bottom: -20%; }
  .about-image-block .parallax-image {
    padding: 100px; }
    .about-image-block .parallax-image.middle {
      margin-top: 100px; }
    .about-image-block .parallax-image img {
      max-width: 100%; }

.cf-images {
  position: relative;
  padding: 20px 0;
  background: #f1f1f1; }
  @media (min-width: 768px) {
    .cf-images {
      padding: 50px 5%; } }
  @media (min-width: 1200px) {
    .cf-images {
      padding: 100px 5% 50px 5%; } }
  .cf-images .cf-images-tagline {
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .cf-images .cf-images-tagline {
        margin-bottom: 100px; } }
    @media (min-width: 1300px) {
      .cf-images .cf-images-tagline {
        max-width: 1300px; } }
    .cf-images .cf-images-tagline h2 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 45px;
      line-height: 2.8125rem;
      letter-spacing: 1px;
      color: #191b30;
      font-weight: 400; }
      @media (min-width: 768px) {
        .cf-images .cf-images-tagline h2 {
          font-size: 80px;
          font-size: 5rem;
          line-height: 90px;
          line-height: 5.625rem; } }
      @media (min-width: 768px) {
        .cf-images .cf-images-tagline h2 span {
          display: block; } }
    .cf-images .cf-images-tagline p {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 30px;
      line-height: 1.875rem; }
  .cf-images .blob {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .cf-images .round {
    background: black;
    width: 300px;
    height: 300px;
    -webkit-background-clip: text;
    background-clip: text;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%; }
    .cf-images .round .big-text {
      font-size: 100px;
      font-size: 6.25rem;
      line-height: 120px;
      line-height: 7.5rem; }

.value-wrap {
  position: relative;
  padding: 0 0 100px 0; }
  @media (min-width: 1700px) {
    .value-wrap .container {
      max-width: 1300px; } }
  .value-wrap .cf-values-slogan {
    padding-bottom: 50px; }
    .value-wrap .cf-values-slogan h2 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 40px;
      line-height: 2.5rem;
      color: #191b30; }
      @media (min-width: 768px) {
        .value-wrap .cf-values-slogan h2 {
          font-size: 60px;
          font-size: 3.75rem;
          line-height: 70px;
          line-height: 4.375rem; } }
    .value-wrap .cf-values-slogan p {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 30px;
      line-height: 1.875rem; }
  @media (min-width: 992px) {
    .value-wrap .cf-info {
      padding: 0; } }
  .value-wrap .cf-info h2, .value-wrap .cf-info h3, .value-wrap .cf-info p {
    text-align: left; }
  .value-wrap .cf-info p {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 26px;
    line-height: 1.625rem;
    color: #666666; }
  .value-wrap .cf-info.about-info {
    padding-top: 25px; }
    @media (min-width: 992px) {
      .value-wrap .cf-info.about-info {
        padding-top: 100px; } }
  .value-wrap.value-wrap-careers {
    background: #191b30; }
    .value-wrap.value-wrap-careers .cf-values-slogan {
      padding-bottom: 50px; }
      .value-wrap.value-wrap-careers .cf-values-slogan h2 {
        color: white; }
    .value-wrap.value-wrap-careers .cf-info h2, .value-wrap.value-wrap-careers .cf-info h3, .value-wrap.value-wrap-careers .cf-info p {
      text-align: left;
      color: white; }
    .value-wrap.value-wrap-careers .cf-info p {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 26px;
      line-height: 1.625rem;
      color: rgba(255, 255, 255, 0.7); }

.values h3 {
  color: #191b30;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 40px;
  line-height: 2.5rem;
  font-weight: 300; }

.values p {
  font-weight: 300; }

.vision-2020 {
  background: #191b30;
  padding: 100px 0; }
  @media (min-width: 1200px) {
    .vision-2020 {
      height: 100vh; } }
  @media (min-width: 1200px) {
    .vision-2020 .container {
      position: relative;
      top: 50%;
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); } }
  .vision-2020 .big-2020 {
    color: white;
    margin-bottom: 25px; }
    @media (min-width: 768px) {
      .vision-2020 .big-2020 {
        margin-bottom: 0; } }
    .vision-2020 .big-2020 span {
      display: block;
      font-weight: 900;
      font-size: 150px;
      font-size: 9.375rem;
      line-height: 100px;
      line-height: 6.25rem; }
      @media (min-width: 768px) {
        .vision-2020 .big-2020 span {
          font-size: 200px;
          font-size: 12.5rem;
          line-height: 140px;
          line-height: 8.75rem; } }
      @media (min-width: 992px) {
        .vision-2020 .big-2020 span {
          font-size: 250px;
          font-size: 15.625rem;
          line-height: 150px;
          line-height: 9.375rem; } }
      .vision-2020 .big-2020 span.last-02 {
        margin-left: 30%;
        letter-spacing: -12px;
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white; }
        @media (min-width: 768px) {
          .vision-2020 .big-2020 span.last-02 {
            margin-left: 20%; } }
        @media (min-width: 992px) {
          .vision-2020 .big-2020 span.last-02 {
            margin-left: 40%; } }
    .vision-2020 .big-2020 p {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 30px;
      line-height: 1.875rem;
      font-weight: 200;
      padding-top: 20px; }
      @media (min-width: 1200px) {
        .vision-2020 .big-2020 p {
          letter-spacing: 10px; } }
  .vision-2020 .vision-view h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 40px;
    line-height: 2.5rem;
    color: white;
    margin-bottom: 20px; }
    @media (min-width: 1200px) {
      .vision-2020 .vision-view h2 {
        font-size: 60px;
        font-size: 3.75rem;
        line-height: 60px;
        line-height: 3.75rem; } }
  .vision-2020 .vision-view p {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 26px;
    line-height: 1.625rem;
    color: rgba(255, 255, 255, 0.7); }
    @media (min-width: 1200px) {
      .vision-2020 .vision-view p {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem; } }
  .vision-2020 .vision-view .case-style {
    color: #ff6600; }

.team-section {
  position: relative; }
  .team-section .about-banner-wrap {
    position: relative; }
  .team-section .team-info {
    position: relative;
    padding: 50px 0 100px 0; }
    .team-section .team-info h2 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 40px;
      line-height: 2.5rem;
      color: #191b30;
      margin-bottom: 25px;
      font-weight: 500; }
      @media (min-width: 768px) {
        .team-section .team-info h2 {
          font-size: 50px;
          font-size: 3.125rem;
          line-height: 60px;
          line-height: 3.75rem; } }
    .team-section .team-info p {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 34px;
      line-height: 2.125rem; }
  .team-section .fluild-container {
    position: relative;
    padding-top: 25px; }
    .team-section .fluild-container:before {
      content: "";
      width: 100%;
      position: absolute;
      top: 0;
      height: 60%;
      background: rgba(102, 102, 102, 0.1); }
  .team-section .team-list {
    padding-top: 100px; }
    @media (min-width: 1300px) {
      .team-section .team-list .container {
        max-width: 1300px; } }
  .team-section .team-section-wrap {
    margin-bottom: 50px;
    padding: 100px 0 50px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    @media (min-width: 992px) {
      .team-section .team-section-wrap {
        padding: 10% 0 0 0; } }
    .team-section .team-section-wrap h2 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 50px;
      line-height: 3.125rem; }
      @media (min-width: 768px) {
        .team-section .team-section-wrap h2 {
          font-size: 60px;
          font-size: 3.75rem;
          line-height: 60px;
          line-height: 3.75rem; } }
      @media (min-width: 992px) {
        .team-section .team-section-wrap h2 {
          font-size: 80px;
          font-size: 5rem;
          line-height: 70px;
          line-height: 4.375rem; } }
      .team-section .team-section-wrap h2 span {
        color: black;
        -webkit-text-fill-color: white;
        /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black; }
  .team-section .team-wrap {
    margin-bottom: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .team-section .team-wrap .container {
      padding-bottom: 25px; }
      @media (min-width: 992px) {
        .team-section .team-wrap .container {
          padding-top: 200px; } }
      @media (min-width: 1200px) {
        .team-section .team-wrap .container {
          max-width: 1300px;
          min-height: 402px;
          position: relative;
          top: 25%;
          padding-top: 0; } }
    .team-section .team-wrap .team-block {
      padding: 20px 25px;
      color: #191b30;
      text-align: center; }
      .team-section .team-wrap .team-block h2 {
        color: #ff6600;
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem;
        margin-bottom: 0; }
      .team-section .team-wrap .team-block .alp {
        font-size: 80px;
        font-size: 5rem;
        line-height: 50px;
        line-height: 3.125rem;
        font-weight: 200;
        margin-bottom: 20px;
        display: none;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out; }
        @media (min-width: 1200px) {
          .team-section .team-wrap .team-block .alp {
            display: block; } }
      .team-section .team-wrap .team-block .designation {
        font-size: 16px;
        font-size: 1rem;
        line-height: 18px;
        line-height: 1.125rem;
        color: #666666; }
    .team-section .team-wrap .team-thumb {
      margin: 0 auto;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      @media (min-width: 768px) {
        .team-section .team-wrap .team-thumb {
          width: 200px; } }
      @media (min-width: 1200px) {
        .team-section .team-wrap .team-thumb {
          width: 250px; } }
      .team-section .team-wrap .team-thumb img {
        max-width: 100%;
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0); }
    .team-section .team-wrap:hover .alp, .team-section .team-wrap:focus .alp, .team-section .team-wrap:active .alp {
      opacity: 0;
      height: 0;
      margin-bottom: 0; }
    .team-section .team-wrap:hover .team-thumb, .team-section .team-wrap:focus .team-thumb, .team-section .team-wrap:active .team-thumb {
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1); }
      .team-section .team-wrap:hover .team-thumb img, .team-section .team-wrap:focus .team-thumb img, .team-section .team-wrap:active .team-thumb img {
        -webkit-animation-name: hvr-buzz-out;
        animation-name: hvr-buzz-out;
        -webkit-animation-duration: 0.75s;
        animation-duration: 0.75s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        animation-delay: 0.5s; }
  .team-section .team-tagline {
    padding: 50px 0;
    margin-bottom: 50px; }
    @media (min-width: 1200px) {
      .team-section .team-tagline {
        padding: 50px 25px;
        animation: pulse 10s linear infinite alternate;
        width: 380px;
        -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
        -ms-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4); } }
    @media (min-width: 1200px) {
      .team-section .team-tagline {
        position: absolute;
        top: -10%;
        z-index: 1;
        min-height: 352px; } }
    .team-section .team-tagline h2 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 36px;
      line-height: 2.25rem;
      margin-bottom: 15px; }
      @media (min-width: 1200px) {
        .team-section .team-tagline h2 {
          color: white; } }
    @media (min-width: 1200px) {
      .team-section .team-tagline p {
        color: white; } }
  @media (min-width: 768px) {
    .team-section .about-image {
      max-width: 1024px;
      margin: 0 auto;
      padding-bottom: 100px; } }
  .team-section .about-image img {
    max-width: 100%; }

.team-list h2 {
  position: relative;
  z-index: 1;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 38px;
  line-height: 2.375rem;
  font-weight: 300;
  color: #ff6600;
  top: -10px; }
  @media (min-width: 768px) {
    .team-list h2 {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 28px;
      line-height: 1.75rem; } }
  @media (min-width: 992px) {
    .team-list h2 {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 30px;
      line-height: 1.875rem; } }
  .team-list h2 span {
    font-weight: 500;
    display: block;
    color: #191b30;
    position: absolute;
    left: 50%; }

.team-list .team-level {
  padding-bottom: 100px;
  text-align: center; }

@media (max-width: 767px) {
  .team-list .designation {
    margin-bottom: 35px;
    display: block; } }

.photo {
  position: relative;
  width: 100%;
  margin: 0 auto 20px auto;
  text-align: center; }
  @media (min-width: 992px) {
    .photo {
      width: 220px;
      height: 320px; } }
  .photo img {
    max-width: 100%;
    object-fit: cover;
    position: relative;
    filter: grayscale(100%) contrast(120%);
    -webkit-filter: grayscale(100%) contrast(120%);
    -moz-filter: grayscale(100%) contrast(120%);
    -ms-filter: grayscale(100%) contrast(120%);
    box-shadow: 10px 15px 25px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    top: -10px; }
  .photo:hover img {
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0);
    top: 0;
    filter: none;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none; }
  .photo:hover .glow {
    top: 0;
    transform: rotate(45deg) translate(450%, 0);
    transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .photo .glow-wrap {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    margin-top: -10px; }
  .photo .glow {
    display: block;
    position: absolute;
    width: 40%;
    height: 200%;
    background: rgba(255, 255, 255, 0.2);
    top: 0;
    filter: blur(5px);
    transform: rotate(45deg) translate(-450%, 0);
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1); }

.culture-slider {
  padding: 100px 0; }
  .culture-slider .slick-slide {
    margin: 0 20px; }
    @media (min-width: 768px) {
      .culture-slider .slick-slide:nth-child(odd) {
        padding-top: 120px; } }
    .culture-slider .slick-slide img {
      max-width: 100%; }
      @media (min-width: 1200px) {
        .culture-slider .slick-slide img {
          max-width: 400px; } }
  .culture-slider .slick-arrow {
    display: none !important; }

.cloudian-title {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 40px;
  line-height: 2.5rem;
  font-weight: 500;
  color: #191b30;
  margin-bottom: 100px;
  display: block; }
  @media (min-width: 768px) {
    .cloudian-title {
      font-size: 50px;
      font-size: 3.125rem;
      line-height: 80px;
      line-height: 5rem; } }

.cf-looking {
  padding: 50px 0; }
  @media (min-width: 768px) {
    .cf-looking {
      padding: 200px 0; } }
  @media (min-width: 1200px) {
    .cf-looking .container {
      max-width: 1300px; } }
  .cf-looking h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 30px;
    line-height: 1.875rem;
    color: #191b30;
    font-weight: 500;
    margin: 0px 0 30px 0;
    display: block;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    text-align: left; }
    @media (min-width: 768px) {
      .cf-looking h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 40px;
        line-height: 2.5rem; } }
    @media (min-width: 992px) {
      .cf-looking h2 {
        font-size: 60px;
        font-size: 3.75rem;
        line-height: 60px;
        line-height: 3.75rem; } }
  .cf-looking p {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 34px;
    line-height: 2.125rem;
    color: #666666;
    font-weight: 400; }
  .cf-looking ul {
    padding: 0;
    margin: 0; }
    .cf-looking ul li {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 24px;
      line-height: 1.5rem;
      font-weight: 200;
      color: #666666;
      cursor: pointer;
      margin: 0 0 1px 0;
      padding: 10px 20px 10px 0px;
      position: relative;
      list-style-type: disc;
      list-style-position: inside; }
      @media (min-width: 768px) {
        .cf-looking ul li {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 30px;
          line-height: 1.875rem;
          width: 45%;
          float: left; } }

.open-positions-wrap {
  padding: 50px 0; }
  @media (min-width: 768px) {
    .open-positions-wrap {
      padding: 100px 0 200px 0; } }
  .open-positions-wrap-details {
    background: rgba(102, 102, 102, 0.1); }
    @media (min-width: 768px) {
      .open-positions-wrap-details {
        padding: 150px 0; } }
  @media (min-width: 1440px) {
    .open-positions-wrap .container {
      max-width: 1300px; } }
  .open-positions-wrap h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 30px;
    line-height: 1.875rem;
    color: #191b30;
    font-weight: 500;
    margin: 0px 0 30px 0;
    display: block;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    text-align: left; }
    @media (min-width: 768px) {
      .open-positions-wrap h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 40px;
        line-height: 2.5rem;
        margin-bottom: 100px; } }
    @media (min-width: 992px) {
      .open-positions-wrap h2 {
        font-size: 60px;
        font-size: 3.75rem;
        line-height: 60px;
        line-height: 3.75rem; } }
  @media (min-width: 1200px) {
    .open-positions-wrap .open-positions {
      padding-left: 50px; } }
  .open-positions-wrap ul {
    padding: 0;
    margin: 0; }
    .open-positions-wrap ul li {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 24px;
      line-height: 1.5rem;
      font-weight: 200;
      color: #666666;
      margin: 0 0 1px 0;
      padding: 10px 20px 10px 0px;
      position: relative;
      list-style-type: none;
      border-bottom: 1px solid #666666; }
      @media (min-width: 768px) {
        .open-positions-wrap ul li {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 30px;
          line-height: 1.875rem; } }
      .open-positions-wrap ul li:last-child {
        border-bottom: none; }
      .open-positions-wrap ul li a {
        display: block;
        color: #191b30; }
        .open-positions-wrap ul li a:hover {
          text-decoration: none; }
          .open-positions-wrap ul li a:hover h4 {
            color: #ff6600; }
      .open-positions-wrap ul li h4 {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem;
        font-weight: 500;
        position: relative; }
        .open-positions-wrap ul li h4:after {
          content: "+" !important;
          content: "";
          display: block;
          position: absolute;
          font-size: 24px;
          font-size: 1.5rem;
          line-height: 40px;
          line-height: 2.5rem;
          font-weight: 200;
          top: 0;
          right: 0; }
        @media (min-width: 768px) {
          .open-positions-wrap ul li h4 {
            font-size: 30px;
            font-size: 1.875rem;
            line-height: 40px;
            line-height: 2.5rem; } }
      .open-positions-wrap ul li p {
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 24px;
        line-height: 1.5rem;
        color: #666666; }
  .open-positions-wrap .careers-video {
    display: none; }
    @media (min-width: 1200px) {
      .open-positions-wrap .careers-video {
        display: block; } }
    .open-positions-wrap .careers-video .block-media {
      position: relative;
      line-height: 0;
      height: auto; }
      .open-positions-wrap .careers-video .block-media:before {
        content: "";
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1; }
      .open-positions-wrap .careers-video .block-media video {
        max-width: 100%; }

.position-details {
  padding: 50px 0; }
  @media (min-width: 768px) {
    .position-details {
      padding: 100px 0; } }
  @media (min-width: 1440px) {
    .position-details .container {
      max-width: 1300px; } }
  @media (min-width: 768px) {
    .position-details p {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 30px;
      line-height: 1.875rem; } }
  .position-details .position-required {
    margin-top: 50px; }
    .position-details .position-required p {
      font-weight: 500;
      color: #191b30; }
  .position-details ul {
    padding: 0;
    margin: 25px 0 25px 20px; }
    .position-details ul li {
      list-style-type: disc;
      padding-left: 10px;
      margin-bottom: 15px;
      line-height: 24px; }

.b-hero-inner.service-hero {
  padding: 200px 0 150px 0;
  position: relative; }
  @media (min-width: 1200px) {
    .b-hero-inner.service-hero {
      padding: 12% 0 8% 0; } }
  .b-hero-inner.service-hero .container {
    position: relative; }
  .b-hero-inner.service-hero .tagline small {
    font-size: 16px;
    font-size: 1rem;
    line-height: 24px;
    line-height: 1.5rem;
    font-weight: 500; }
  .b-hero-inner.service-hero .tagline h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 40px;
    line-height: 2.5rem;
    color: #191b30;
    font-weight: 500; }
    @media (min-width: 768px) {
      .b-hero-inner.service-hero .tagline h2 {
        font-size: 45px;
        font-size: 2.8125rem;
        line-height: 55px;
        line-height: 3.4375rem; } }
    @media (min-width: 992px) {
      .b-hero-inner.service-hero .tagline h2 {
        font-size: 65px;
        font-size: 4.0625rem;
        line-height: 75px;
        line-height: 4.6875rem; } }
    .b-hero-inner.service-hero .tagline h2 span {
      margin: 20px 0;
      display: block; }
  .b-hero-inner.service-hero .tagline.service-tagline h3 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 26px;
    line-height: 1.625rem;
    color: #666666;
    font-weight: 300; }
    @media (min-width: 768px) {
      .b-hero-inner.service-hero .tagline.service-tagline h3 {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 36px;
        line-height: 2.25rem; } }
  .b-hero-inner.service-hero .service-scene {
    position: relative;
    opacity: 0; }

.service-info-block {
  padding: 100px 0 100px 0; }
  @media (min-width: 768px) {
    .service-info-block {
      padding: 250px 0 100px 0; } }
  .service-info-block .service-info-title {
    position: relative; }
    .service-info-block .service-info-title:before {
      content: "";
      width: 200px;
      height: 200px;
      background: #ff6600;
      position: absolute;
      top: -100px;
      left: -25%;
      z-index: -1; }
      @media (min-width: 768px) {
        .service-info-block .service-info-title:before {
          width: 400px;
          height: 400px;
          top: -300px; } }

.service-scene-together {
  width: 100%; }
  .service-scene-together img {
    max-width: 100%; }

@media (min-width: 1440px) {
  .quote-section.service-quote .container {
    max-width: 1300px; } }

.quote-section.service-quote blockquote {
  padding: 50px 0; }
  .quote-section.service-quote blockquote h2 {
    color: #ff6600;
    text-align: left; }

@media (min-width: 768px) {
  .service-home-info {
    margin-top: 70px; } }

.service-container {
  margin: 50px 0 50px 0;
  overflow: hidden; }
  @media (min-width: 768px) {
    .service-container {
      margin: 150px 0; } }
  @media (min-width: 1200px) {
    .service-container {
      margin: 100px 0; } }
  .service-container h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 40px;
    line-height: 2.5rem;
    color: #191b30;
    font-weight: 500;
    margin: 0px 0 50px 0;
    display: block;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    text-align: left; }
    @media (min-width: 768px) {
      .service-container h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 50px;
        line-height: 3.125rem;
        margin: 0px 0 100px 0;
        font-weight: 900; } }
    @media (min-width: 992px) {
      .service-container h2 {
        font-size: 50px;
        font-size: 3.125rem;
        line-height: 55px;
        line-height: 3.4375rem; } }
    @media (min-width: 1200px) {
      .service-container h2 {
        font-size: 60px;
        font-size: 3.75rem;
        line-height: 65px;
        line-height: 4.0625rem; } }
  .service-container h3 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 34px;
    line-height: 2.125rem;
    color: #191b30;
    font-weight: 400;
    margin: 0px 0 10px 0;
    display: block;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    @media (min-width: 768px) {
      .service-container h3 {
        text-align: right;
        margin: 0px 0 30px 0; } }
  .service-container .container-fluid {
    padding: 0 10%; }
  @media (min-width: 992px) {
    .service-container .service-detail-info {
      text-align: left; } }
  .service-container .service-detail-info h2 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 44px;
    line-height: 2.75rem;
    color: #191b30;
    font-weight: 400;
    margin: 0px 0 30px 0;
    display: block;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
  .service-container .service-detail-info p {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 30px;
    line-height: 1.875rem; }
  .service-container .service-detail-info a {
    color: #ff6600; }
    .service-container .service-detail-info a:hover {
      color: #004777;
      text-decoration: none; }
  .service-container .service-detail-info .big-text {
    font-size: 100px;
    font-size: 6.25rem;
    line-height: 80px;
    line-height: 5rem;
    color: rgba(102, 102, 102, 0.1);
    text-transform: uppercase;
    font-weight: 900;
    white-space: nowrap;
    position: absolute;
    top: 60px;
    letter-spacing: 5px; }
  .service-container .service-detail-info .align-middle {
    margin-bottom: 25px; }
    @media (min-width: 992px) {
      .service-container .service-detail-info .align-middle {
        margin-bottom: 100px; } }
  .service-container.app-opt .service-details .svg {
    width: 70%;
    height: 350px; }
    @media (min-width: 992px) {
      .service-container.app-opt .service-details .svg {
        height: auto; } }

.service-details {
  position: relative;
  text-align: center;
  padding-left: 0;
  padding-bottom: 50px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  @media (min-width: 992px) {
    .service-details {
      text-align: left;
      padding-bottom: 0; } }
  .service-details:last-child {
    margin-bottom: 0; }
  .service-details.mob-app-image .svg {
    width: 50%; }
    @media (min-width: 992px) {
      .service-details.mob-app-image .svg {
        width: 80%; } }
  @media (min-width: 992px) {
    .service-details.digital-market-image .svg, .service-details.devops-image .svg {
      width: 80%; } }
  .service-details .svg {
    width: 60%;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .service-details .svg {
        width: 100%; } }
  @media (min-width: 768px) {
    .service-details:nth-of-type(2n) .service-thumb {
      left: inherit;
      right: 0; }
    .service-details:nth-of-type(2n) .service-detail-info {
      padding-left: 0;
      padding-right: 200px; }
      .service-details:nth-of-type(2n) .service-detail-info::before {
        left: inherit;
        right: 120px; } }

.accordion_container {
  position: relative; }
  @media (min-width: 992px) {
    .accordion_container {
      width: 600px;
      float: right; } }

.accordion_head {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-weight: 200;
  color: #191b30;
  cursor: pointer;
  margin: 0 0 1px 0;
  padding: 15px 35px 15px 0px;
  position: relative; }
  @media (min-width: 768px) {
    .accordion_head {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 34px;
      line-height: 2.125rem; } }
  .accordion_head.open {
    color: #ff6600; }

.accordion_body p {
  padding: 18px 5px;
  margin: 0px; }

.plusminus {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 24px;
  line-height: 1.5rem;
  font-weight: 200;
  float: right;
  position: absolute;
  top: 25%;
  right: 0; }

.contact-wrapper {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  @media (min-width: 768px) {
    .contact-wrapper {
      min-height: calc(100vh - 100px); } }

.contact-block {
  width: 100%;
  position: relative;
  padding-bottom: 50px; }
  @media (min-width: 1200px) {
    .contact-block {
      position: absolute;
      top: 50%;
      padding-top: 100px;
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); } }
  .contact-block .container {
    position: relative;
    padding-top: 100px;
    z-index: 1; }
    @media (min-width: 1200px) {
      .contact-block .container {
        padding-top: 0; } }
    @media (min-width: 1440px) {
      .contact-block .container {
        max-width: 1300px; } }
  .contact-block h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 40px;
    line-height: 2.5rem;
    color: #191b30;
    margin: 100px 0 50px 0; }
    .contact-block h2 span {
      background: white;
      color: #ff6600;
      padding: 0 10px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      -ms-border-radius: 5px;
      border-radius: 5px; }
    @media (min-width: 768px) {
      .contact-block h2 {
        font-size: 80px;
        font-size: 5rem;
        line-height: 90px;
        line-height: 5.625rem; } }
  .contact-block .contact-row {
    margin: 40px 0;
    position: relative; }
    .contact-block .contact-row h3 {
      font-size: 24px;
      font-size: 1.5rem;
      line-height: 36px;
      line-height: 2.25rem;
      color: #191b30;
      font-weight: 400; }
    .contact-block .contact-row h4 {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      line-height: 1.5rem;
      font-weight: 500;
      color: #191b30;
      text-decoration: underline; }
    .contact-block .contact-row a {
      font-size: 16px;
      font-size: 1rem;
      line-height: 24px;
      line-height: 1.5rem;
      color: #ff6600;
      letter-spacing: 1px;
      font-weight: 400; }
      .contact-block .contact-row a:hover {
        color: #ffcc00;
        text-decoration: none; }
    .contact-block .contact-row p {
      color: #666666;
      margin: 0 0 5px 0; }
    .contact-block .contact-row.cw-address a {
      font-size: 16px;
      font-size: 1rem;
      line-height: 30px;
      line-height: 1.875rem;
      font-weight: 400; }
    .contact-block .contact-row .map-anchor {
      width: 34px;
      height: 60px;
      display: inline-block;
      position: relative;
      top: 25px; }
      @media (min-width: 1200px) {
        .contact-block .contact-row .map-anchor {
          position: absolute;
          right: 15%;
          top: 70%; } }
      .contact-block .contact-row .map-anchor:hover .pin {
        top: -10px; }
    .contact-block .contact-row .pin {
      background: url("../images/cloudymap-pin.svg") no-repeat center;
      width: 30px;
      height: 34px;
      animation-name: bounce;
      animation-fill-mode: both;
      animation-duration: 1s;
      -webkit-transition: all 0.5s ease-in-out 0s;
      -moz-transition: all 0.5s ease-in-out 0s;
      -ms-transition: all 0.5s ease-in-out 0s;
      transition: all 0.5s ease-in-out 0s; }
      @media (min-width: 480px) {
        .contact-block .contact-row .pin {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1; } }
    .contact-block .contact-row .pulse {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      height: 10px;
      width: 10px;
      position: absolute;
      left: 50%;
      top: 25%;
      margin: 11px 0 0 -4px;
      transform: rotateX(55deg);
      z-index: -2; }
      .contact-block .contact-row .pulse:after {
        content: "";
        border-radius: 50%;
        height: 30px;
        width: 30px;
        position: absolute;
        margin: -10px 0 0 -14px;
        animation: pulsate 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0.0;
        box-shadow: 0 0 1px 2px #89849b;
        animation-delay: 1.5s; }
  .contact-block .footer-block.contact-social {
    width: 100%;
    float: left; }
    .contact-block .footer-block.contact-social ul {
      padding: 0;
      margin-bottom: 10px;
      text-align: left; }
      .contact-block .footer-block.contact-social ul li {
        display: inline-block;
        margin: 0 5px;
        background: #ff6600;
        padding: 10px;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        -ms-border-radius: 100%;
        border-radius: 100%; }
        @media (min-width: 768px) {
          .contact-block .footer-block.contact-social ul li {
            margin: 0 5px 15px 5px; } }
        @media (min-width: 1200px) {
          .contact-block .footer-block.contact-social ul li {
            margin: 0 10px; } }
        .contact-block .footer-block.contact-social ul li .svg {
          width: 20px;
          height: 22px; }
        .contact-block .footer-block.contact-social ul li .job-white {
          width: 20px; }
  .contact-block .footer-block p {
    color: white; }

@media (min-width: 1440px) {
  .social-foot-wrap {
    max-width: 1300px; } }

.social-foot-wrap .copyright {
  width: 100%;
  float: left;
  border-top: none;
  padding: 0;
  margin: 10px 0 0 0; }
  @media (min-width: 992px) {
    .social-foot-wrap .copyright {
      margin: 20px 0 0 0; } }

.social-foot-wrap .footer-block.contact-social ul {
  padding: 0;
  margin-bottom: 10px;
  text-align: left; }
  .social-foot-wrap .footer-block.contact-social ul li {
    display: inline-block;
    margin: 0 5px;
    background: #ff6600;
    padding: 10px;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%; }
    @media (min-width: 768px) {
      .social-foot-wrap .footer-block.contact-social ul li {
        margin: 0 5px 15px 5px; } }
    @media (min-width: 1200px) {
      .social-foot-wrap .footer-block.contact-social ul li {
        margin: 0 10px; } }
    .social-foot-wrap .footer-block.contact-social ul li:first-child {
      margin-left: 0; }
    .social-foot-wrap .footer-block.contact-social ul li .svg {
      width: 20px;
      height: 22px; }
    .social-foot-wrap .footer-block.contact-social ul li .job-white {
      width: 20px; }

.social-foot-wrap .footer-block p {
  color: white; }

.case-text {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 0;
  z-index: 2;
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  line-height: 1.25rem;
  font-weight: 200;
  color: #ffcc00;
  max-width: 1300px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .case-text {
      top: 60px;
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 22px;
      line-height: 1.375rem; } }

.casestudy-wrap {
  position: relative;
  padding: 50px 0; }
  @media (min-width: 1200px) {
    .casestudy-wrap {
      padding: 250px 0 100px 0; } }
  .casestudy-wrap .casestudy-heading {
    text-align: left; }
    @media (min-width: 768px) {
      .casestudy-wrap .casestudy-heading {
        text-align: center; } }
  .casestudy-wrap small {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 18px;
    line-height: 1.125rem;
    color: #ff6600;
    text-transform: uppercase; }

.case-study-block {
  position: relative;
  padding: 50px 0;
  margin-bottom: 50px; }
  @media (min-width: 992px) {
    .case-study-block {
      padding: 100px 0; } }
  @media (min-width: 1200px) {
    .case-study-block {
      padding: 125px 0; } }
  .case-study-block:last-child {
    margin-bottom: 0; }
    @media (max-width: 479px) {
      .case-study-block:last-child {
        padding-bottom: 100px; } }
  .case-study-block img {
    max-width: 100%; }
  .case-study-block .desktop-shot-home {
    width: 805px;
    margin: 0 auto;
    border: 10px solid white;
    -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px; }
  .case-study-block .mobile-shot-home {
    width: 300px;
    position: absolute;
    bottom: -50px;
    border: 10px solid white;
    -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px; }
  .case-study-block.case-block-jobs .case-home-job {
    position: relative; }
    .case-study-block.case-block-jobs .case-home-job .desktop-shot-home {
      width: 100%;
      height: 350px;
      overflow: hidden;
      margin: 0 auto;
      border: 10px solid white;
      -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      -ms-border-radius: 10px;
      border-radius: 10px; }
      @media (min-width: 768px) {
        .case-study-block.case-block-jobs .case-home-job .desktop-shot-home {
          width: 80%;
          height: 450px;
          border: 20px solid white; } }
      @media (min-width: 992px) {
        .case-study-block.case-block-jobs .case-home-job .desktop-shot-home {
          width: 90%; } }
      @media (min-width: 1200px) {
        .case-study-block.case-block-jobs .case-home-job .desktop-shot-home {
          height: 500px; } }
      @media (min-width: 1300px) {
        .case-study-block.case-block-jobs .case-home-job .desktop-shot-home {
          height: 600px; } }
      @media (min-width: 1700px) {
        .case-study-block.case-block-jobs .case-home-job .desktop-shot-home {
          height: 700px; } }
    .case-study-block.case-block-jobs .case-home-job .mobile-shot-home {
      width: 30%;
      position: absolute;
      bottom: -50px;
      left: 20px;
      border: 10px solid white;
      -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      -ms-border-radius: 10px;
      border-radius: 10px;
      display: none; }
      @media (min-width: 768px) {
        .case-study-block.case-block-jobs .case-home-job .mobile-shot-home {
          display: block; } }
      @media (min-width: 992px) {
        .case-study-block.case-block-jobs .case-home-job .mobile-shot-home {
          width: 40%;
          left: -25px; } }
      @media (min-width: 1200px) {
        .case-study-block.case-block-jobs .case-home-job .mobile-shot-home {
          width: 30%;
          left: -10px; } }
  .case-study-block.case-block-99leave {
    overflow: hidden; }
    .case-study-block.case-block-99leave .desktop-shot-home {
      width: 100%;
      position: relative;
      top: 0;
      right: 0;
      border: none;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      -ms-box-shadow: none;
      box-shadow: none; }
      @media (min-width: 992px) {
        .case-study-block.case-block-99leave .desktop-shot-home {
          width: 80%; } }
  .case-study-block .circle-panel {
    width: 80%;
    position: relative;
    float: right;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }
    .case-study-block .circle-panel::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50px;
      left: 50px;
      border: 1px solid rgba(102, 102, 102, 0.2);
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
      -ms-border-radius: 20px;
      border-radius: 20px;
      -moz-transform: rotate(-5deg);
      -o-transform: rotate(-5deg);
      -ms-transform: rotate(-5deg);
      -webkit-transform: rotate(-5deg);
      transform: rotate(-5deg); }
    .case-study-block .circle-panel img {
      max-width: 100%;
      position: relative;
      z-index: 1;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
      -ms-border-radius: 20px;
      border-radius: 20px; }

.case-info h3 {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  color: #ff6600;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px; }
  @media (min-width: 768px) {
    .case-info h3 {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 36px;
      line-height: 2.25rem; } }

.case-info p {
  line-height: 24px;
  line-height: 1.5rem; }

.case-bg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 900px;
  background-position: top center; }

.p-container {
  margin-bottom: 45px; }
  @media (min-width: 480px) {
    .p-container {
      margin-bottom: 80px; } }
  @media (min-width: 992px) {
    .p-container {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin-bottom: 0;
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); } }

.b-hero-inner.casestudy-hero {
  padding: 100px 0;
  position: relative; }
  @media (min-width: 992px) {
    .b-hero-inner.casestudy-hero {
      padding: 0; } }
  .b-hero-inner.casestudy-hero .container {
    text-align: center; }
    @media (min-width: 992px) {
      .b-hero-inner.casestudy-hero .container {
        text-align: left;
        position: relative;
        top: 50%;
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); } }
    @media (min-width: 1300px) {
      .b-hero-inner.casestudy-hero .container {
        max-width: 1300px; } }
    .b-hero-inner.casestudy-hero .container .project-info h2 {
      font-size: 22px;
      font-size: 1.375rem;
      line-height: 30px;
      line-height: 1.875rem;
      color: #191b30;
      font-weight: 300; }
      @media (min-width: 768px) {
        .b-hero-inner.casestudy-hero .container .project-info h2 {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 40px;
          line-height: 2.5rem; } }
    @media (max-width: 767px) {
      .b-hero-inner.casestudy-hero .container .project-info .project-info-block {
        width: 50%; }
        .b-hero-inner.casestudy-hero .container .project-info .project-info-block:last-child {
          width: 100%;
          margin-top: 25px; } }
  .b-hero-inner.casestudy-hero .case-anchor {
    padding-left: 0;
    padding-right: 25px; }
    @media (min-width: 992px) {
      .b-hero-inner.casestudy-hero .case-anchor {
        text-align: left; } }
    .b-hero-inner.casestudy-hero .case-anchor .btn-site:after {
      content: "";
      display: block;
      position: absolute;
      animation: ripple-blue 0.7s linear infinite;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .b-hero-inner.casestudy-hero .case-anchor .btn-site:hover {
      text-decoration: none;
      opacity: 1; }
      .b-hero-inner.casestudy-hero .case-anchor .btn-site:hover::after {
        right: -15px; }

.b-hero-inner.casestudy-hero .container .project-info {
  padding-top: 0;
  padding-bottom: 0; }
  @media (max-width: 767px) {
    .b-hero-inner.casestudy-hero .container .project-info {
      position: absolute;
      top: 50%;
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); } }
  @media (min-width: 768px) {
    .b-hero-inner.casestudy-hero .container .project-info {
      padding-bottom: 100px;
      padding-top: 100px;
      text-align: left; } }
  @media (min-width: 992px) {
    .b-hero-inner.casestudy-hero .container .project-info {
      padding-bottom: 0;
      padding-top: 0; } }
  .b-hero-inner.casestudy-hero .container .project-info img {
    width: 100px;
    margin-bottom: 50px; }
    .b-hero-inner.casestudy-hero .container .project-info img.byzkit-logo {
      width: 200px; }
  .b-hero-inner.casestudy-hero .container .project-info h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 40px;
    line-height: 2.5rem;
    color: white; }
    @media (min-width: 768px) {
      .b-hero-inner.casestudy-hero .container .project-info h2 {
        font-size: 60px;
        font-size: 3.75rem;
        line-height: 75px;
        line-height: 4.6875rem; } }
  .b-hero-inner.casestudy-hero .container .project-info .case-style {
    color: white; }

.b-hero-inner.casestudy-hero.jobs-hero {
  background: black; }

.b-hero-inner.jobsstudios {
  overflow: hidden;
  height: 100vh; }
  @media (max-width: 991px) {
    .b-hero-inner.jobsstudios .container {
      padding-top: 50px;
      padding-bottom: 50px; } }
  .b-hero-inner.jobsstudios:before {
    content: "";
    display: block;
    position: absolute;
    background: #00c0ff;
    /* Old browsers */
    background: -moz-linear-gradient(left, #00c0ff 0%, #d200ff 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #00c0ff), color-stop(100%, #d200ff));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #00c0ff 0%, #d200ff 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #00c0ff 0%, #d200ff 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #00c0ff 0%, #d200ff 100%);
    /* IE10+ */
    background: linear-gradient(to right, #00c0ff 0%, #d200ff 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */
    top: -15%;
    right: -5%;
    left: inherit;
    width: 80%;
    height: 30%;
    animation: slideInRight 1s alternate;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%; }
    @media (min-width: 768px) {
      .b-hero-inner.jobsstudios:before {
        width: 60%;
        height: 60%; } }
    @media (min-width: 1200px) {
      .b-hero-inner.jobsstudios:before {
        width: 800px;
        height: 800px;
        top: -25%; } }
    @media (min-width: 1700px) {
      .b-hero-inner.jobsstudios:before {
        width: 1000px;
        height: 1000px; } }
  .b-hero-inner.jobsstudios .color-circle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    animation: slideInDown 1s linear; }
    .b-hero-inner.jobsstudios .color-circle:before {
      content: "";
      display: block;
      position: absolute;
      background: #005aff;
      /* Old browsers */
      background: -moz-linear-gradient(left, #005aff 0%, #ff00fc 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #005aff), color-stop(100%, #ff00fc));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left, #005aff 0%, #ff00fc 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left, #005aff 0%, #ff00fc 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(left, #005aff 0%, #ff00fc 100%);
      /* IE10+ */
      background: linear-gradient(to right, #005aff 0%, #ff00fc 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
      /* IE6-9 */
      top: 0%;
      right: -10%;
      width: 80%;
      height: 40%;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      -ms-border-radius: 100%;
      border-radius: 100%;
      opacity: 0.5;
      animation: nudge-up 5s infinite forwards; }
      @media (min-width: 768px) {
        .b-hero-inner.jobsstudios .color-circle:before {
          width: 40%;
          height: 70%;
          top: 20%; } }
    .b-hero-inner.jobsstudios .color-circle:after {
      content: "";
      display: block;
      position: absolute;
      background: #ff00fc;
      /* Old browsers */
      background: -moz-linear-gradient(left, #ff00fc 0%, #005aff 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff00fc), color-stop(100%, #005aff));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(left, #ff00fc 0%, #005aff 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(left, #ff00fc 0%, #005aff 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(left, #ff00fc 0%, #005aff 100%);
      /* IE10+ */
      background: linear-gradient(to right, #ff00fc 0%, #005aff 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
      /* IE6-9 */
      top: 0%;
      right: -10%;
      width: 80%;
      height: 30%;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      -ms-border-radius: 100%;
      border-radius: 100%;
      opacity: 0.5;
      animation: nudge 3s infinite linear; }
      @media (min-width: 768px) {
        .b-hero-inner.jobsstudios .color-circle:after {
          width: 40%;
          height: 65%;
          top: -20%;
          right: 20%; } }
  .b-hero-inner.jobsstudios .screen-scroll {
    width: 90%; }
    @media (max-width: 991px) {
      .b-hero-inner.jobsstudios .screen-scroll {
        background: white;
        width: 94%;
        overflow: hidden;
        padding: 10px;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        -ms-border-radius: 10px;
        border-radius: 10px;
        -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2); } }
    @media (min-width: 992px) {
      .b-hero-inner.jobsstudios .screen-scroll {
        width: 768px; } }
    @media (min-width: 1200px) {
      .b-hero-inner.jobsstudios .screen-scroll {
        width: 960px; } }
    @media (min-width: 1700px) {
      .b-hero-inner.jobsstudios .screen-scroll {
        width: 1200px; } }
    @media (min-width: 992px) {
      .b-hero-inner.jobsstudios .screen-scroll {
        position: absolute;
        top: 55%;
        right: -15%;
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%); } }
    .b-hero-inner.jobsstudios .screen-scroll img {
      display: none; }
      @media (min-width: 992px) {
        .b-hero-inner.jobsstudios .screen-scroll img {
          display: block; } }
    .b-hero-inner.jobsstudios .screen-scroll .shot-scroll {
      width: 100%;
      height: 300px;
      top: 0;
      position: relative; }
      @media (min-width: 768px) {
        .b-hero-inner.jobsstudios .screen-scroll .shot-scroll {
          height: 500px; } }
      @media (min-width: 992px) {
        .b-hero-inner.jobsstudios .screen-scroll .shot-scroll {
          width: 526px;
          height: 330px;
          position: absolute;
          top: 37px; } }
      @media (min-width: 1200px) {
        .b-hero-inner.jobsstudios .screen-scroll .shot-scroll {
          width: 660px;
          height: 412px;
          top: 46px; } }
      @media (min-width: 1700px) {
        .b-hero-inner.jobsstudios .screen-scroll .shot-scroll {
          width: 824px;
          height: 514px;
          top: 58px; } }
      .b-hero-inner.jobsstudios .screen-scroll .shot-scroll img {
        display: block; }

@media (min-width: 768px) {
  .parallax-slide {
    height: 500px; } }

@media (min-width: 1200px) {
  .parallax-slide {
    height: 100vh; } }

.content-slide {
  height: 100vh;
  background: #191b30;
  text-align: center;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  @media (min-width: 480px) {
    .content-slide {
      height: 90vh; } }

.content-slide-white {
  background: white; }

.parallax-leave {
  background: url("../images/byzkit-showcase.png") no-repeat top center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 55%; }
  @media (min-width: 1200px) {
    .parallax-leave {
      background-attachment: fixed; } }

.parallax-jobs {
  background: url("../images/jobs-showcase.png") no-repeat top center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 55%; }
  @media (min-width: 1200px) {
    .parallax-jobs {
      background-attachment: fixed; } }

.laptop-show {
  position: relative;
  text-align: center; }
  .laptop-show img {
    position: relative;
    right: -20%;
    top: -200px; }
  .laptop-show:before {
    content: "";
    display: block;
    position: absolute;
    top: -30%;
    left: 0;
    right: 0;
    width: 960px;
    height: 960px;
    margin: 0 auto;
    background: #004777;
    z-index: -1;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%; }

.case-layout {
  position: relative;
  padding-top: 80px;
  margin-bottom: 120px; }
  .case-layout img {
    max-width: 100%;
    margin-bottom: 50px; }
  .case-layout .button-animi {
    width: 240px;
    margin: 0 auto; }

.case-buildup {
  position: relative;
  padding-top: 50px; }
  @media (min-width: 768px) {
    .case-buildup {
      padding-top: 100px; } }
  @media (min-width: 1200px) {
    .case-buildup {
      padding-top: 250px; } }
  .case-buildup p {
    margin-bottom: 0;
    font-size: 16px;
    font-size: 1rem;
    line-height: 20px;
    line-height: 1.25rem; }
    .case-buildup p .case-style {
      color: #191b30; }
  .case-buildup .big-button {
    margin: 0 auto; }
  .case-buildup .case-information {
    padding: 50px 15px; }
    @media (min-width: 768px) {
      .case-buildup .case-information {
        padding: 20px 0 100px 0; } }
    .case-buildup .case-information p {
      color: #666666;
      margin: 0;
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 32px;
      line-height: 2rem;
      font-weight: 200; }
      @media (min-width: 768px) {
        .case-buildup .case-information p {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 48px;
          line-height: 3rem; } }
    .case-buildup .case-information ul {
      margin: 20px 0 0 30px;
      padding: 0; }
      .case-buildup .case-information ul li {
        list-style-type: decimal;
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 32px;
        line-height: 2rem;
        font-weight: 200;
        margin: 0 0 10px 0;
        color: #191b30; }
        @media (min-width: 768px) {
          .case-buildup .case-information ul li {
            font-size: 24px;
            font-size: 1.5rem;
            line-height: 34px;
            line-height: 2.125rem; } }
  .case-buildup.buildup-leave h2 {
    color: #191b30;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 24px;
    line-height: 1.5rem;
    font-weight: 500; }

.build-info {
  width: 100%;
  padding: 50px 15px;
  text-align: center; }
  @media (min-width: 768px) {
    .build-info {
      padding: 50px 0 100px 0; } }

.build .build-wrap {
  text-align: left; }
  @media (min-width: 480px) {
    .build .build-wrap {
      display: flex; } }
  .build .build-wrap .build-block {
    width: 100%;
    padding: 20px; }
    @media (min-width: 480px) {
      .build .build-wrap .build-block {
        width: 25%; } }
    @media (min-width: 480px) {
      .build .build-wrap .build-block.role {
        width: 50%;
        border-top: 0;
        border-bottom: 0; } }
    .build .build-wrap .build-block span {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 24px;
      line-height: 1.5rem;
      font-weight: 400;
      padding-right: 10px;
      color: #1ea807;
      display: block;
      margin-bottom: 15px; }
      @media (min-width: 768px) {
        .build .build-wrap .build-block span {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 28px;
          line-height: 1.75rem;
          font-weight: 500;
          text-decoration: underline; } }
  .build .build-wrap.buildup-bg .build-block p {
    color: #666666; }
  .build .build-wrap.buildup-bg .build-block span {
    color: #191b30; }

.build.build-top h2 {
  margin-bottom: 50px; }

.build.build-top .build-wrap {
  padding-bottom: 30px;
  border: none;
  border-bottom: 1px solid rgba(102, 102, 102, 0.1);
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none; }
  .build.build-top .build-wrap .build-block {
    padding: 10px 20px; }

.color-code h2 {
  text-align: center;
  margin-bottom: 50px; }

.color-code ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .color-code ul li {
    width: 80px;
    height: 80px;
    margin: 0 20px;
    background: #191b30;
    display: inline-block;
    -moz-border-radius: 80px;
    -webkit-border-radius: 80px;
    -ms-border-radius: 80px;
    border-radius: 80px; }
    .color-code ul li.blue {
      background: #24a2dc; }
    .color-code ul li.yellow {
      background: #ffcc00; }
    .color-code ul li.red {
      background: #f74d4d; }
    .color-code ul li.green {
      background: #28b779; }
    .color-code ul li.grey {
      background: #666666; }
    .color-code ul li.dark-blue {
      background: #2B3643; }
    .color-code ul li.black {
      background: black; }
    .color-code ul li.orange {
      background: #ff6600; }
    .color-code ul li.brown {
      background: #927450; }

.view-desktop-wrap {
  margin-bottom: 50px;
  padding: 0 15px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .view-desktop-wrap {
      margin-bottom: 100px; } }
  .view-desktop-wrap h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 40px;
    line-height: 2.5rem;
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .view-desktop-wrap h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 50px;
        line-height: 3.125rem; } }
  .view-desktop-wrap .view-desktop {
    position: relative;
    padding: 10px;
    margin-bottom: 25px; }
    @media (min-width: 768px) {
      .view-desktop-wrap .view-desktop {
        padding: 50px;
        margin-bottom: 0; } }
    .view-desktop-wrap .view-desktop img {
      max-width: 100%;
      -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      position: relative;
      z-index: 1; }
    .view-desktop-wrap .view-desktop::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 250px;
      background: #191b30;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      -ms-border-radius: 10px;
      border-radius: 10px; }
      @media (min-width: 768px) {
        .view-desktop-wrap .view-desktop::after {
          height: 600px;
          -moz-border-radius: 20px;
          -webkit-border-radius: 20px;
          -ms-border-radius: 20px;
          border-radius: 20px; } }
      @media (min-width: 1200px) {
        .view-desktop-wrap .view-desktop::after {
          height: 800px; } }

.mobile-view-wrap {
  position: relative;
  margin: 150px 0 100px 0; }
  .mobile-view-wrap.mobile-jobs .mobscreen-view {
    opacity: 0.5; }
  .mobile-view-wrap::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 50%;
    background: #db4471;
    /* Old browsers */
    background: -moz-linear-gradient(left, #db4471 0%, #ff7454 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #db4471), color-stop(100%, #ff7454));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left, #db4471 0%, #ff7454 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left, #db4471 0%, #ff7454 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(left, #db4471 0%, #ff7454 100%);
    /* IE10+ */
    background: linear-gradient(to right, #db4471 0%, #ff7454 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */
    top: 25%;
    left: 0;
    right: 0;
    bottom: 0; }
  .mobile-view-wrap .mobscreen-view {
    text-align: center; }
    @media (min-width: 480px) {
      .mobile-view-wrap .mobscreen-view {
        padding: 0 50px; } }
    .mobile-view-wrap .mobscreen-view img {
      max-width: 100%; }

.mobile-app {
  width: 100%;
  padding: 50px 15px;
  position: relative; }
  @media (min-width: 768px) {
    .mobile-app {
      padding: 100px 0; } }
  .mobile-app h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 40px;
    line-height: 2.5rem;
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .mobile-app h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 50px;
        line-height: 3.125rem;
        margin-bottom: 100px; } }
  .mobile-app .mobile-block {
    width: 100%;
    max-width: 300px;
    margin: 20px auto; }
    @media (min-width: 768px) {
      .mobile-app .mobile-block {
        width: 33%;
        max-width: inherit;
        float: left; } }
    .mobile-app .mobile-block img {
      width: 100%;
      max-width: 100%;
      -moz-border-radius: 20px;
      -webkit-border-radius: 20px;
      -ms-border-radius: 20px;
      border-radius: 20px;
      -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2); }
      @media (min-width: 768px) {
        .mobile-app .mobile-block img {
          width: 85%;
          margin: 30px 35px; } }
      @media (min-width: 1200px) {
        .mobile-app .mobile-block img {
          -moz-border-radius: 40px;
          -webkit-border-radius: 40px;
          -ms-border-radius: 40px;
          border-radius: 40px; } }
    @media (min-width: 768px) {
      .mobile-app .mobile-block.mobile-two {
        position: relative;
        top: 100px; } }

.case-anchor {
  padding-left: 0;
  text-align: center;
  padding-right: 25px; }
  .case-anchor a {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 34px;
    line-height: 2.125rem;
    color: #191b30;
    opacity: 0.7;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .case-anchor .btn-site {
    position: relative;
    padding-right: 120px; }
    .case-anchor .btn-site:before {
      content: "";
      display: block;
      position: absolute;
      width: 100px;
      height: 1px;
      background: #191b30;
      top: 12px;
      right: 0; }
    .case-anchor .btn-site:after {
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      right: 0;
      background-color: #191b30;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      animation: ripple-blue 0.7s linear infinite;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .case-anchor .btn-site:hover {
      text-decoration: none;
      opacity: 1; }
      .case-anchor .btn-site:hover::after {
        right: -15px; }

.b-hero-inner.case-page-banner {
  height: auto; }
  @media (min-width: 768px) {
    .b-hero-inner.case-page-banner {
      padding: 200px 0 100px 0; } }
  @media (min-width: 992px) {
    .b-hero-inner.case-page-banner {
      padding: 300px 0 300px 0; } }
  @media (min-width: 1200px) {
    .b-hero-inner.case-page-banner .container {
      max-width: 1300px; } }
  .b-hero-inner.case-page-banner h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 45px;
    line-height: 2.8125rem; }
    @media (min-width: 768px) {
      .b-hero-inner.case-page-banner h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 50px;
        line-height: 3.125rem; } }
    @media (min-width: 992px) {
      .b-hero-inner.case-page-banner h2 {
        font-size: 65px;
        font-size: 4.0625rem;
        line-height: 80px;
        line-height: 5rem; } }
    .b-hero-inner.case-page-banner h2 span {
      color: #ff6600;
      border-bottom: 5px solid #191b30; }

.case-study-list {
  padding: 50px 0;
  position: relative; }
  .case-study-list:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: black;
    opacity: 0.2;
    -webkit-transition: opacity 0.5s ease-in-out 0.2s;
    -moz-transition: opacity 0.5s ease-in-out 0.2s;
    -ms-transition: opacity 0.5s ease-in-out 0.2s;
    transition: opacity 0.5s ease-in-out 0.2s; }
  @media (min-width: 768px) {
    .case-study-list {
      padding: 100px 0; } }
  .case-study-list:hover:after {
    background: transparent;
    opacity: 0;
    z-index: -1; }
  .case-study-list.case-99leave {
    background: #2b509c;
    background-image: radial-gradient(#2d8cff 10%, #2b509c 80%);
    -webkit-background-image: radial-gradient(#2d8cff 10%, #2b509c 80%);
    filter: grayscale(80%);
    -webkit-filter: grayscale(80%);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .case-study-list.case-99leave:hover {
      filter: grayscale(0%);
      -webkit-filter: grayscale(0%); }
  .case-study-list.case-jobsstudios {
    background: #26ae61;
    background-image: radial-gradient(#26ae61 10%, #156125 90%);
    -webkit-background-image: radial-gradient(#26ae61 10%, #156125 90%);
    filter: grayscale(80%);
    -webkit-filter: grayscale(80%);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .case-study-list.case-jobsstudios:hover {
      filter: grayscale(0%);
      -webkit-filter: grayscale(0%); }
  @media (min-width: 1200px) {
    .case-study-list .container {
      max-width: 1300px; } }

.case-block-wrap .case-blocks {
  position: relative;
  min-height: 500px; }
  .case-block-wrap .case-blocks .case-thumb {
    width: 100%; }
    .case-block-wrap .case-blocks .case-thumb img {
      max-width: 100%;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
  .case-block-wrap .case-blocks .case-list-info {
    position: relative;
    padding-top: 80px; }
    .case-block-wrap .case-blocks .case-list-info h2 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 50px;
      line-height: 3.125rem;
      color: white;
      margin-bottom: 25px; }
      @media (min-width: 768px) {
        .case-block-wrap .case-blocks .case-list-info h2 {
          font-size: 50px;
          font-size: 3.125rem;
          line-height: 60px;
          line-height: 3.75rem; } }
    .case-block-wrap .case-blocks .case-list-info p {
      font-size: 20px;
      font-size: 1.25rem;
      line-height: 34px;
      line-height: 2.125rem;
      font-weight: 200;
      color: white; }
  @media (min-width: 768px) {
    .case-block-wrap .case-blocks.right-case-block .case-thumb {
      float: right; } }
  @media (min-width: 768px) {
    .case-block-wrap .case-blocks.right-case-block .case-list-info {
      left: inherit;
      right: 340px; } }
  @media (min-width: 992px) {
    .case-block-wrap .case-blocks.right-case-block .case-list-info {
      right: 430px; } }
  @media (min-width: 1200px) {
    .case-block-wrap .case-blocks.right-case-block .case-list-info {
      right: 450px; } }

.process-banner {
  padding: 100px 0;
  background-image: url("../images/process-fallback.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  @media (min-width: 1200px) {
    .process-banner {
      background-image: inherit; } }
  @media (min-width: 768px) {
    .process-banner {
      height: 100vh; } }
  @media (min-width: 1200px) {
    .process-banner {
      padding: 0; } }
  .process-banner:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: block; }
    @media (min-width: 1200px) {
      .process-banner:before {
        display: none; } }
  @media (min-width: 1200px) {
    .process-banner:after {
      content: "";
      display: block;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      top: 0; } }
  .process-banner p {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem; }
  @media (min-width: 768px) {
    .process-banner .container {
      padding-top: 0;
      padding-bottom: 50px;
      height: auto;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 0;
      right: 0;
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); } }
  @media (min-width: 1300px) {
    .process-banner .container {
      max-width: 1300px;
      padding-bottom: 0; } }
  .process-banner .block-media {
    position: relative;
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    display: none; }
    @media (min-width: 1200px) {
      .process-banner .block-media {
        display: block; } }
    .process-banner .block-media:before {
      content: "";
      background: rgba(25, 27, 48, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1; }
    .process-banner .block-media video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-filter: grayscale(70%);
      filter: grayscale(70%); }
  .process-banner .overlay-image-wrap {
    position: relative; }
    @media (min-width: 992px) {
      .process-banner .overlay-image-wrap {
        padding-right: 100px; } }
    @media (min-width: 1300px) {
      .process-banner .overlay-image-wrap {
        height: 700px;
        padding-right: 150px; } }
    .process-banner .overlay-image-wrap h2 {
      font-size: 70px;
      font-size: 4.375rem;
      line-height: 100px;
      line-height: 6.25rem;
      margin-left: auto;
      margin-right: auto;
      color: #ff6600;
      text-transform: uppercase;
      margin-bottom: 0;
      font-weight: 900;
      position: absolute;
      top: 0;
      bottom: 0;
      opacity: 0.2;
      text-align: right;
      display: none;
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg); }
      @media (min-width: 992px) {
        .process-banner .overlay-image-wrap h2 {
          display: block; } }
      @media (min-width: 1300px) {
        .process-banner .overlay-image-wrap h2 {
          font-size: 100px;
          font-size: 6.25rem;
          line-height: 110px;
          line-height: 6.875rem;
          padding-top: 35px;
          right: 0; } }
  .process-banner .overlay-image {
    background: #191b30;
    position: relative;
    height: 100%;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px; }
    @media (min-width: 992px) {
      .process-banner .overlay-image {
        -moz-box-shadow: 50px 10px 70px 10px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 50px 10px 70px 10px rgba(0, 0, 0, 0.2);
        -ms-box-shadow: 50px 10px 70px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 50px 10px 70px 10px rgba(0, 0, 0, 0.2); } }
    .process-banner .overlay-image img {
      max-width: 100%;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      -ms-border-radius: 5px;
      border-radius: 5px; }
  .process-banner .process-img {
    position: relative; }

.b-hero-inner.process-banner h2 {
  color: white; }

.b-hero-inner.process-banner p {
  color: rgba(255, 255, 255, 0.7); }

.expert-section {
  padding: 100px 0; }
  .expert-section h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 35px;
    line-height: 2.1875rem;
    color: #191b30;
    font-weight: 300;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .expert-section h2 {
        font-size: 50px;
        font-size: 3.125rem;
        line-height: 60px;
        line-height: 3.75rem;
        font-weight: 500; } }
    @media (min-width: 768px) {
      .expert-section h2 span {
        opacity: 0.2; } }
  .expert-section p {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 34px;
    line-height: 2.125rem; }

.web-tools {
  padding: 50px 0;
  position: relative; }
  @media (min-width: 768px) {
    .web-tools {
      padding: 100px 0 100px 0; } }
  .web-tools .cf-tools-tagline {
    margin-bottom: 120px;
    text-align: center; }
    .web-tools .cf-tools-tagline h2 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 50px;
      line-height: 3.125rem;
      color: white;
      font-weight: 400;
      margin: 0px 0 20px 0;
      display: block;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
      @media (min-width: 992px) {
        .web-tools .cf-tools-tagline h2 {
          font-size: 60px;
          font-size: 3.75rem;
          line-height: 70px;
          line-height: 4.375rem; } }
    .web-tools .cf-tools-tagline p {
      color: rgba(255, 255, 255, 0.7); }
  .web-tools.reveal-block::before {
    background: #191b30; }
  .web-tools .small {
    font-size: 16px;
    font-size: 1rem;
    line-height: 20px;
    line-height: 1.25rem; }
  .web-tools .octa-icon {
    width: 100px;
    height: 110px;
    display: inline-block;
    position: absolute;
    display: none; }
    @media (min-width: 768px) {
      .web-tools .octa-icon {
        display: block; } }
    .web-tools .octa-icon svg {
      width: 80px;
      height: 70px;
      margin: 0 auto; }
      .web-tools .octa-icon svg #octa,
      .web-tools .octa-icon svg #ocata1 {
        opacity: 0.3; }
  .web-tools .tool-box {
    position: relative;
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .web-tools .tool-box {
        margin-bottom: 100px; } }
    .web-tools .tool-box .tool-title h2 {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 34px;
      line-height: 2.125rem;
      text-transform: uppercase;
      color: white; }
      @media (min-width: 768px) {
        .web-tools .tool-box .tool-title h2 {
          font-size: 50px;
          font-size: 3.125rem;
          line-height: 34px;
          line-height: 2.125rem; } }
    .web-tools .tool-box .tool-title.tool-creative {
      position: absolute;
      left: 60px;
      top: -16px; }
      .web-tools .tool-box .tool-title.tool-creative h2 {
        color: white;
        animation: nudge 5s infinite linear; }
    @media (min-width: 768px) {
      .web-tools .tool-box .tool-title.tool-web {
        position: absolute;
        top: 155px;
        right: -155px;
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg); }
        .web-tools .tool-box .tool-title.tool-web h2 {
          font-size: 215px;
          font-size: 13.4375rem;
          line-height: 180px;
          line-height: 11.25rem;
          opacity: 0.2; } }
    .web-tools .tool-box .tool-title.tool-mobile {
      position: relative;
      padding-bottom: 100px; }
      .web-tools .tool-box .tool-title.tool-mobile::before {
        content: "";
        display: block;
        position: absolute;
        background: white;
        width: 1px;
        height: 80px;
        top: 50px; }
      .web-tools .tool-box .tool-title.tool-mobile h2 {
        font-size: 30px;
        font-size: 1.875rem;
        line-height: 40px;
        line-height: 2.5rem;
        font-weight: 300;
        margin: 0; }
    .web-tools .tool-box .tool-title.tool-manage h2 {
      font-size: 25px;
      font-size: 1.5625rem;
      line-height: 30px;
      line-height: 1.875rem;
      color: white;
      margin: 0; }
      @media (min-width: 992px) {
        .web-tools .tool-box .tool-title.tool-manage h2 {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 35px;
          line-height: 2.1875rem; } }
      @media (min-width: 1200px) {
        .web-tools .tool-box .tool-title.tool-manage h2 {
          font-size: 40px;
          font-size: 2.5rem;
          line-height: 40px;
          line-height: 2.5rem; } }
    .web-tools .tool-box .tool-title.tool-data h2 {
      font-size: 25px;
      font-size: 1.5625rem;
      line-height: 30px;
      line-height: 1.875rem;
      margin: 0; }
      @media (min-width: 992px) {
        .web-tools .tool-box .tool-title.tool-data h2 {
          font-size: 30px;
          font-size: 1.875rem;
          line-height: 35px;
          line-height: 2.1875rem; } }
      @media (min-width: 1200px) {
        .web-tools .tool-box .tool-title.tool-data h2 {
          font-size: 40px;
          font-size: 2.5rem;
          line-height: 40px;
          line-height: 2.5rem; } }
    .web-tools .tool-box ul {
      list-style-type: none;
      text-align: left;
      padding: 0; }
      .web-tools .tool-box ul li {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 38px;
        line-height: 2.375rem;
        color: #ff6600; }
    .web-tools .tool-box.creative {
      background: #ff6600;
      width: 200px;
      height: 200px;
      padding-top: 50px;
      padding-left: 200px; }
      .web-tools .tool-box.creative .octa-icon {
        left: 0px; }
        .web-tools .tool-box.creative .octa-icon svg #octa {
          animation: rotation 15s infinite linear; }
      .web-tools .tool-box.creative ul {
        padding: 0 0 0 10px; }
    .web-tools .tool-box.web {
      padding-top: 10px;
      text-align: center; }
      @media (min-width: 768px) {
        .web-tools .tool-box.web {
          text-align: right; } }
      .web-tools .tool-box.web .octa-icon {
        left: 0px;
        top: 0;
        opacity: 0.3; }
        @media (min-width: 768px) {
          .web-tools .tool-box.web .octa-icon {
            opacity: 1;
            left: -50px; } }
        .web-tools .tool-box.web .octa-icon svg {
          position: absolute; }
          .web-tools .tool-box.web .octa-icon svg:nth-of-type(1) {
            left: 40px; }
            @media (min-width: 768px) {
              .web-tools .tool-box.web .octa-icon svg:nth-of-type(1) {
                left: -100px; } }
            .web-tools .tool-box.web .octa-icon svg:nth-of-type(1) #octa {
              animation: rotation 10s infinite linear; }
          .web-tools .tool-box.web .octa-icon svg:nth-of-type(2) {
            top: 200px;
            left: 200px; }
            @media (min-width: 768px) {
              .web-tools .tool-box.web .octa-icon svg:nth-of-type(2) {
                left: -250px; } }
          .web-tools .tool-box.web .octa-icon svg:nth-of-type(3) {
            top: 350px;
            left: 150px; }
            @media (min-width: 768px) {
              .web-tools .tool-box.web .octa-icon svg:nth-of-type(3) {
                left: -150px; } }
            .web-tools .tool-box.web .octa-icon svg:nth-of-type(3) #ocata1 {
              animation: rotation-zoom 5s infinite linear; }
          .web-tools .tool-box.web .octa-icon svg:nth-of-type(4) {
            top: 250px; }
            @media (min-width: 768px) {
              .web-tools .tool-box.web .octa-icon svg:nth-of-type(4) {
                top: 150px; } }
            .web-tools .tool-box.web .octa-icon svg:nth-of-type(4) #ocata1 {
              animation: rotation 10s infinite linear; }
      @media (min-width: 768px) {
        .web-tools .tool-box.web ul {
          padding-right: 180px; } }
      .web-tools .tool-box.web ul li {
        display: block;
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem;
        margin: 10px 0;
        text-align: center;
        color: #ff6600; }
        @media (min-width: 768px) {
          .web-tools .tool-box.web ul li {
            text-align: right; } }
        .web-tools .tool-box.web ul li.tagline {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 30px;
          line-height: 1.875rem;
          color: #cccccc;
          padding: 20px 0; }
          @media (min-width: 768px) {
            .web-tools .tool-box.web ul li.tagline {
              text-align: right; } }
          .web-tools .tool-box.web ul li.tagline .case-style {
            font-size: 30px;
            font-size: 1.875rem;
            line-height: 40px;
            line-height: 2.5rem;
            color: white; }
    .web-tools .tool-box.mobile {
      margin-top: 100px; }
      @media (min-width: 768px) {
        .web-tools .tool-box.mobile {
          margin-top: 200px; } }
      .web-tools .tool-box.mobile .octa-icon {
        top: 0; }
        .web-tools .tool-box.mobile .octa-icon svg {
          position: absolute; }
          .web-tools .tool-box.mobile .octa-icon svg:nth-of-type(1) {
            top: -100px;
            left: 50px; }
            @media (min-width: 768px) {
              .web-tools .tool-box.mobile .octa-icon svg:nth-of-type(1) {
                top: -200px; } }
            .web-tools .tool-box.mobile .octa-icon svg:nth-of-type(1) #octa {
              animation: rotation 10s infinite linear; }
          .web-tools .tool-box.mobile .octa-icon svg:nth-of-type(2) {
            top: 100px;
            left: 200px; }
      .web-tools .tool-box.mobile ul li {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem;
        color: #cccccc; }
    .web-tools .tool-box.javascript .tool-title h2 {
      text-align: right; }
      @media (min-width: 992px) {
        .web-tools .tool-box.javascript .tool-title h2 {
          text-align: left; } }
    @media (min-width: 1200px) {
      .web-tools .tool-box.javascript {
        padding-left: 500px; } }
    .web-tools .tool-box.javascript .octa-icon {
      left: 0px; }
      .web-tools .tool-box.javascript .octa-icon svg {
        position: absolute; }
        .web-tools .tool-box.javascript .octa-icon svg:nth-of-type(1) {
          left: 200px;
          top: 120px; }
          .web-tools .tool-box.javascript .octa-icon svg:nth-of-type(1) #octa {
            animation: rotation-zoom 5s infinite linear; }
          .web-tools .tool-box.javascript .octa-icon svg:nth-of-type(1) #ocata1 {
            animation: rotation 10s infinite linear; }
        .web-tools .tool-box.javascript .octa-icon svg:nth-of-type(2) {
          top: 10px;
          left: 400px; }
          @media (min-width: 1200px) {
            .web-tools .tool-box.javascript .octa-icon svg:nth-of-type(2) {
              left: 500px; } }
          .web-tools .tool-box.javascript .octa-icon svg:nth-of-type(2) #octa {
            animation: rotation 10s infinite linear; }
    .web-tools .tool-box.javascript ul {
      text-align: right; }
      @media (min-width: 992px) {
        .web-tools .tool-box.javascript ul {
          text-align: left; } }
      .web-tools .tool-box.javascript ul li {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem;
        color: #cccccc; }
    .web-tools .tool-box.data {
      margin-bottom: 50px; }
      @media (min-width: 768px) {
        .web-tools .tool-box.data {
          text-align: right;
          margin-bottom: 0; } }
      .web-tools .tool-box.data ul {
        text-align: left;
        padding-left: 20px; }
        @media (min-width: 768px) {
          .web-tools .tool-box.data ul {
            text-align: right;
            padding-left: 0; } }
        .web-tools .tool-box.data ul li {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 30px;
          line-height: 1.875rem;
          color: #cccccc;
          position: relative; }
          .web-tools .tool-box.data ul li:before {
            content: "";
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border: 1px solid #ff6600;
            left: -20px;
            top: 10px;
            -moz-border-radius: 10px;
            -webkit-border-radius: 10px;
            -ms-border-radius: 10px;
            border-radius: 10px; }
            @media (min-width: 768px) {
              .web-tools .tool-box.data ul li:before {
                right: -20px;
                left: inherit; } }
    .web-tools .tool-box.manage ul {
      padding-left: 20px; }
      @media (min-width: 768px) {
        .web-tools .tool-box.manage ul {
          padding-left: 0; } }
      .web-tools .tool-box.manage ul li {
        font-size: 20px;
        font-size: 1.25rem;
        line-height: 30px;
        line-height: 1.875rem;
        color: #cccccc;
        position: relative; }
        .web-tools .tool-box.manage ul li:before {
          content: "";
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          border: 1px solid #ff6600;
          left: -20px;
          top: 10px;
          -moz-border-radius: 10px;
          -webkit-border-radius: 10px;
          -ms-border-radius: 10px;
          border-radius: 10px;
          display: block; }
          @media (min-width: 768px) {
            .web-tools .tool-box.manage ul li:before {
              display: none; } }
  .web-tools .javascript-wrap {
    position: relative; }
    .web-tools .javascript-wrap:before {
      content: "";
      display: block;
      position: absolute;
      width: 500px;
      height: 500px;
      top: -210px;
      left: -150px;
      opacity: 0.5;
      animation: nudge 10s infinite linear;
      display: none; }
      @media (min-width: 1200px) {
        .web-tools .javascript-wrap:before {
          display: block; } }
    .web-tools .javascript-wrap .tool-img {
      width: 480px;
      position: absolute;
      top: 50px;
      right: 0;
      -moz-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
      -ms-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
      display: none; }
      @media (min-width: 992px) {
        .web-tools .javascript-wrap .tool-img {
          display: block; } }
      @media (min-width: 1200px) {
        .web-tools .javascript-wrap .tool-img {
          left: 0;
          top: 0; } }
      .web-tools .javascript-wrap .tool-img:before {
        content: "";
        display: block;
        position: absolute;
        background: rgba(25, 27, 48, 0.5);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .web-tools .javascript-wrap .tool-img video {
        max-width: 100%; }
  .web-tools .tool-list {
    margin: 15px 0 100px 0;
    padding: 0;
    list-style-type: none; }
    .web-tools .tool-list li {
      display: inline-block;
      margin: 5px; }
      .web-tools .tool-list li img {
        width: 60px; }

.process-list {
  position: relative; }

.approach-banner {
  margin-top: 20px; }
  .approach-banner img {
    max-width: 100%; }

.approach-title {
  position: relative; }

.approach-info {
  padding: 0 15px; }
  .approach-info h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 40px;
    line-height: 2.5rem;
    margin-bottom: 50px; }
    @media (min-width: 768px) {
      .approach-info h2 {
        font-size: 50px;
        font-size: 3.125rem;
        line-height: 60px;
        line-height: 3.75rem;
        margin-bottom: 100px; } }

.quote-section blockquote {
  position: relative;
  padding: 100px 0 50px 0; }
  @media (min-width: 1200px) {
    .quote-section blockquote {
      padding: 150px 0 50px 0; } }
  .quote-section blockquote h2 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 40px;
    line-height: 2.5rem;
    color: #191b30;
    text-align: left; }
    @media (min-width: 768px) {
      .quote-section blockquote h2 {
        font-size: 40px;
        font-size: 2.5rem;
        line-height: 50px;
        line-height: 3.125rem; } }

.day-to-day .activities {
  position: relative; }
  @media (min-width: 992px) {
    .day-to-day .activities {
      top: 50%;
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); } }
  .day-to-day .activities .activities-info p {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 28px;
    line-height: 1.75rem;
    color: #191b30; }
  .day-to-day .activities .reg-day {
    width: 100%;
    padding: 10px 0;
    display: block;
    text-align: left; }
    .day-to-day .activities .reg-day .numbers {
      font-size: 30px;
      font-size: 1.875rem;
      line-height: 35px;
      line-height: 2.1875rem;
      font-weight: 400;
      color: #ff6600; }
    .day-to-day .activities .reg-day p {
      margin: 0;
      color: #191b30; }

.expert-team-wrap {
  padding: 0 0 100px 0; }
  @media (min-width: 768px) {
    .expert-team-wrap {
      padding: 0 0 20px 0; } }
  @media (min-width: 992px) {
    .expert-team-wrap {
      padding: 100px 0 130px 0; } }
  @media (min-width: 1200px) {
    .expert-team-wrap {
      padding: 100px 0 150px 0; } }
  .expert-team-wrap .agile-team p {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem;
    color: white; }
  .expert-team-wrap .expert-team-block {
    position: relative; }
    .expert-team-wrap .expert-team-block:after {
      content: "";
      display: block;
      position: absolute;
      background: #ff6600;
      width: 100%;
      height: 100%;
      top: 100px;
      z-index: -1; }
      @media (min-width: 768px) {
        .expert-team-wrap .expert-team-block:after {
          height: 80%;
          top: 150px; } }
      @media (min-width: 992px) {
        .expert-team-wrap .expert-team-block:after {
          height: 100%; } }
      @media (min-width: 1200px) {
        .expert-team-wrap .expert-team-block:after {
          top: 160px; } }
    .expert-team-wrap .expert-team-block h2 {
      font-size: 40px;
      text-align: center;
      text-transform: uppercase;
      margin: 0;
      font-weight: 900; }
      @media (min-width: 768px) {
        .expert-team-wrap .expert-team-block h2 {
          font-size: 6vw; } }
      .expert-team-wrap .expert-team-block h2 span {
        opacity: 0.2; }
    .expert-team-wrap .expert-team-block .team-image {
      padding-bottom: 50px; }
      .expert-team-wrap .expert-team-block .team-image div {
        padding: 0; }
      .expert-team-wrap .expert-team-block .team-image img {
        max-width: 100%; }
    .expert-team-wrap .expert-team-block .big-button {
      margin-top: 25px; }
      .expert-team-wrap .expert-team-block .big-button a {
        color: white;
        border-color: white; }
        .expert-team-wrap .expert-team-block .big-button a span {
          color: white; }

.circle {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  box-shadow: 0 1px 0 0 rgba(25, 27, 48, 0.25), 0 -1px 0 0 rgba(25, 27, 48, 0.25), 1px 0 0 0 rgba(25, 27, 48, 0.25), -1px 0 0 0 rgba(25, 27, 48, 0.25), 1px -1px 0 0 rgba(25, 27, 48, 0.5), -1px 1px 0 0 rgba(25, 27, 48, 0.5), 1px 1px 0 0 rgba(25, 27, 48, 0.75), -1px -1px 0 0 rgba(25, 27, 48, 0.75); }
  .circle:nth-of-type(2) {
    box-shadow: 0 1px 0 0 rgba(25, 27, 48, 0.25), 0 -1px 0 0 rgba(25, 27, 48, 0.25), 1px 0 0 0 rgba(25, 27, 48, 0.25), -1px 0 0 0 rgba(25, 27, 48, 0.25), 1px -1px 0 0 rgba(25, 27, 48, 0.5), -1px 1px 0 0 rgba(25, 27, 48, 0.5), 1px 1px 0 0 rgba(25, 27, 48, 0.75), -1px -1px 0 0 rgba(25, 27, 48, 0.75);
    animation: rotateThis 3s linear infinite; }
  .circle:nth-of-type(3) {
    box-shadow: 0 1px 0 0 rgba(25, 27, 48, 0.25), 0 -1px 0 0 rgba(25, 27, 48, 0.25), 1px 0 0 0 rgba(25, 27, 48, 0.25), -1px 0 0 0 rgba(25, 27, 48, 0.25), 1px -1px 0 0 rgba(25, 27, 48, 0.5), -1px 1px 0 0 rgba(25, 27, 48, 0.5), 1px 1px 0 0 rgba(25, 27, 48, 0.75), -1px -1px 0 0 rgba(25, 27, 48, 0.75); }
  .circle-multiple {
    width: 100px;
    height: 100px; }
  .circle-multiple .circle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
    .circle-multiple .circle:nth-of-type(1) {
      animation: psycho 4s linear infinite; }
    .circle-multiple .circle:nth-of-type(2) {
      animation: psycho 4s linear .1s infinite; }
    .circle-multiple .circle:nth-of-type(3) {
      animation: psycho 4s linear .25s infinite; }

.circles > * {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0; }

.circles {
  transform: translateZ(0);
  margin-bottom: 20px; }

.fixbody .the-process {
  z-index: inherit; }

.the-process {
  margin: 100px 0 100px 0;
  overflow: hidden;
  position: relative;
  z-index: 9; }
  @media (min-width: 768px) {
    .the-process {
      margin: 200px 0 100px 0;
      padding-bottom: 200px; } }
  .the-process img {
    max-width: 100%;
    height: auto; }
  .the-process .process-thumb-slider {
    margin: 0 -5px 5px -5px; }
    .the-process .process-thumb-slider .slick-track {
      transform: translate3d(0px, 0px, 0px) !important; }
  .the-process .slick-slider {
    margin-bottom: 0px; }
  .the-process .process-count {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 14px;
    line-height: 0.875rem;
    font-weight: 500;
    color: #191b30;
    position: relative;
    display: block; }
  @media (min-width: 1200px) {
    .the-process .process-info-block .count-container {
      max-width: 960px; } }
  @media (min-width: 1440px) {
    .the-process .process-info-block .count-container {
      max-width: 1100px; } }
  @media (min-width: 1700px) {
    .the-process .process-info-block .count-container {
      max-width: 1300px; } }
  .the-process .process-info-block .controls {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 20px;
    line-height: 1.25rem;
    font-weight: 300;
    padding: 10px 15px;
    float: right; }
    @media (min-width: 768px) {
      .the-process .process-info-block .controls {
        display: none; } }
  .the-process .process-info-block .process-thumb-slider .slick-slider-item {
    padding: 5px; }
    .the-process .process-info-block .process-thumb-slider .slick-slider-item.slick-current h2 {
      color: #ff6600; }
    .the-process .process-info-block .process-thumb-slider .slick-slider-item.slick-current .process-count {
      color: #ff6600; }
    .the-process .process-info-block .process-thumb-slider .slick-slider-item h2 {
      font-size: 8px;
      font-size: 0.5rem;
      line-height: 14px;
      line-height: 0.875rem;
      font-weight: 300;
      letter-spacing: 0; }
      @media (min-width: 480px) {
        .the-process .process-info-block .process-thumb-slider .slick-slider-item h2 {
          font-size: 12px;
          font-size: 0.75rem;
          line-height: 14px;
          line-height: 0.875rem; } }
      @media (min-width: 768px) {
        .the-process .process-info-block .process-thumb-slider .slick-slider-item h2 {
          font-size: 20px;
          font-size: 1.25rem;
          line-height: 20px;
          line-height: 1.25rem; } }
  .the-process .process-info-block h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 35px;
    line-height: 2.1875rem;
    font-weight: 500;
    color: #191b30; }
    @media (min-width: 768px) {
      .the-process .process-info-block h2 {
        font-size: 70px;
        font-size: 4.375rem;
        line-height: 60px;
        line-height: 3.75rem;
        letter-spacing: 5px;
        text-transform: uppercase; } }
    @media (min-width: 1200px) {
      .the-process .process-info-block h2 {
        font-size: 100px;
        font-size: 6.25rem;
        line-height: 80px;
        line-height: 5rem;
        letter-spacing: 20px; } }
  .the-process .process-info-block p {
    font-size: 16px;
    font-size: 1rem;
    line-height: 28px;
    line-height: 1.75rem; }
    @media (min-width: 768px) {
      .the-process .process-info-block p {
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 32px;
        line-height: 2rem; } }
  .the-process .process-info-block .process-info-content {
    display: block;
    padding-top: 10px; }
    @media (min-width: 992px) {
      .the-process .process-info-block .process-info-content {
        padding-top: 50px; } }
  @media (min-width: 1200px) {
    .the-process .process-info-block .process-item-slider .slick-list {
      max-width: 960px;
      margin: 0 auto;
      overflow: inherit; } }
  @media (min-width: 1440px) {
    .the-process .process-info-block .process-item-slider .slick-list {
      max-width: 1100px; } }
  @media (min-width: 1700px) {
    .the-process .process-info-block .process-item-slider .slick-list {
      max-width: 1300px;
      position: relative; }
      .the-process .process-info-block .process-item-slider .slick-list:before {
        content: "";
        display: block;
        position: absolute;
        background: red;
        top: 0;
        left: 0;
        width: 50%; } }
  .the-process .process-info-block .process-item-slider .slick-slider-item {
    opacity: 0.1; }
    .the-process .process-info-block .process-item-slider .slick-slider-item h2 {
      color: #ff6600;
      -webkit-text-fill-color: #fff;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #ff6600; }
    .the-process .process-info-block .process-item-slider .slick-slider-item.slick-current {
      opacity: 1; }
      .the-process .process-info-block .process-item-slider .slick-slider-item.slick-current h2 {
        color: #ff6600;
        -webkit-text-fill-color: inherit;
        -webkit-text-stroke-width: inherit;
        -webkit-text-stroke-color: inherit; }
  .the-process .process-info-block .process-item-slider .slick-arrow {
    position: absolute;
    bottom: -45px;
    left: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: white;
    font-size: 0;
    border: none;
    padding: 0; }
    @media (min-width: 768px) {
      .the-process .process-info-block .process-item-slider .slick-arrow {
        width: 50px;
        height: 50px; } }
    .the-process .process-info-block .process-item-slider .slick-arrow.slick-prev {
      background: url("../images/left-arrow.svg") no-repeat;
      display: block;
      left: 15px; }
      @media (min-width: 768px) {
        .the-process .process-info-block .process-item-slider .slick-arrow.slick-prev {
          left: 10%; } }
      .the-process .process-info-block .process-item-slider .slick-arrow.slick-prev.slick-disabled {
        display: none; }
    .the-process .process-info-block .process-item-slider .slick-arrow.slick-next {
      background: url("../images/right-arrow.svg") no-repeat;
      display: block;
      left: 80px; }
      @media (min-width: 768px) {
        .the-process .process-info-block .process-item-slider .slick-arrow.slick-next {
          left: 15%; } }
      .the-process .process-info-block .process-item-slider .slick-arrow.slick-next.slick-disabled {
        display: none; }
  .the-process .process-quote blockquote {
    position: relative;
    padding: 20px 0 20px 0;
    text-align: center;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 26px;
    line-height: 1.625rem;
    color: #ff6600; }
    @media (min-width: 768px) {
      .the-process .process-quote blockquote {
        font-size: 25px;
        font-size: 1.5625rem;
        line-height: 35px;
        line-height: 2.1875rem;
        padding: 50px 0 50px 0; } }

.process {
  padding-top: 50px; }
  @media (min-width: 768px) {
    .process {
      padding-top: 100px; } }
  .process.process-one .circle {
    width: 50px;
    height: 50px; }
    .process.process-one .circle-multiple {
      width: 50px;
      height: 50px; }
  .process.process-two .circle {
    width: 25px;
    height: 50px; }
    .process.process-two .circle-multiple {
      width: 30px;
      height: 50px; }

footer {
  position: relative;
  overflow: hidden; }
  footer:before {
    content: '';
    position: absolute;
    top: -10%;
    left: -12%;
    width: 600px;
    height: 600px;
    z-index: 1;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%; }
  footer:after {
    content: '';
    position: absolute;
    top: -12%;
    right: -5%;
    width: 500px;
    height: 500px;
    background: transparent;
    z-index: 1;
    border: 1px solid rgba(255, 255, 255, 0.2);
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%; }
  @media (min-width: 1200px) {
    footer {
      width: 100%; } }
  @media (min-width: 1440px) {
    footer .container {
      max-width: 1300px; } }

.footer-info p {
  margin: 5px 0;
  color: white;
  font-weight: 200; }

.footer-info h2 {
  font-size: 30px;
  font-size: 1.875rem;
  color: white;
  margin-bottom: 10px;
  font-weight: 400; }
  @media (min-width: 768px) {
    .footer-info h2 {
      font-size: 40px;
      font-size: 2.5rem; } }
  @media (min-width: 992px) {
    .footer-info h2 {
      font-size: 50px;
      font-size: 3.125rem; } }
  .footer-info h2 span {
    display: block; }

.footer-info ul {
  list-style-type: none;
  margin: 0 0 10px 0;
  padding: 0; }
  @media (min-width: 1200px) {
    .footer-info ul {
      text-align: left; } }
  .footer-info ul li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 15px; }
    @media (min-width: 1200px) {
      .footer-info ul li {
        margin-right: 15px;
        margin-bottom: 0; } }

.footer-main-wrap {
  position: relative; }
  @media (min-width: 992px) {
    .footer-main-wrap {
      height: 100vh; } }
  .footer-main-wrap.reveal-block::before {
    background: #151515; }

.footer-wrapper {
  padding: 50px 0; }
  @media (min-width: 768px) {
    .footer-wrapper {
      padding: 100px 0; } }
  @media (min-width: 992px) {
    .footer-wrapper {
      padding: 12.5% 0; } }

@media (max-width: 767px) {
  .footer-block {
    text-align: center; } }

.footer-block.start-section {
  text-align: left;
  margin: 25px 0;
  position: relative; }

@media (min-width: 768px) {
  .footer-block.address-section .row .col-md-4:nth-child(2n) {
    display: flex;
    justify-content: center; } }

@media (min-width: 768px) {
  .footer-block.address-section .row .col-md-4:nth-child(3n) {
    display: flex;
    justify-content: flex-end; } }

.footer-block.address-section .foot-box {
  margin-bottom: 35px; }
  .footer-block.address-section .foot-box h3 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    line-height: 1.5rem;
    color: #fff09a;
    font-weight: 400;
    margin-bottom: 10px; }
  .footer-block.address-section .foot-box h5 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 24px;
    line-height: 1.5rem;
    color: white;
    font-weight: 400; }
  .footer-block.address-section .foot-box a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 18px;
    line-height: 1.125rem;
    color: #ff6600;
    display: inline-block; }
    @media (min-width: 768px) {
      .footer-block.address-section .foot-box a {
        font-size: 16px;
        font-size: 1rem;
        line-height: 24px;
        line-height: 1.5rem; } }
    .footer-block.address-section .foot-box a:hover {
      color: #ffcc00;
      opacity: 1; }

@media (min-width: 1200px) {
  .social {
    text-align: right; } }

.social li .svg {
  width: 20px;
  height: 22px;
  fill: white; }

.social li:last-child {
  margin-right: 0; }

.button-animi.for-footer a {
  color: white; }
  .button-animi.for-footer a svg path {
    fill: white; }
  .button-animi.for-footer a:before {
    background: rgba(255, 102, 0, 0.3); }
  .button-animi.for-footer a:hover::before {
    background: #ff6600; }

.footer-info.bottom-footer {
  text-align: center;
  padding: 50px 0 15px 0; }
  @media (min-width: 768px) {
    .footer-info.bottom-footer {
      text-align: left;
      padding: 100px 0 30px 0; } }
  @media (min-width: 992px) {
    .footer-info.bottom-footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto; } }
  .footer-info.bottom-footer a {
    color: white;
    font-weight: 300;
    display: block;
    border-bottom: 1px solid #ff6600; }
    .footer-info.bottom-footer a:hover {
      opacity: 0.7;
      text-decoration: none; }

.footer-info .copyright-wrap {
  margin: 10px 0; }
  @media (min-width: 1200px) {
    .footer-info .copyright-wrap {
      text-align: right; } }

.footer-info .love-np {
  margin-top: 20px; }
  @media (min-width: 1200px) {
    .footer-info .love-np {
      text-align: right; } }
  .footer-info .love-np span {
    color: #ff6600;
    display: inline-block; }

.dots-block {
  position: absolute;
  top: 20%;
  left: 0%; }
  .dots-block svg {
    width: 500px; }
    .dots-block svg #dots {
      fill: rgba(255, 255, 255, 0.3); }

@media (min-width: 768px) {
  .social-row {
    margin-top: 20px; } }

@media (min-width: 768px) {
  .copyright-dark {
    text-align: right; } }

.copyright-dark .copyright-wrap, .copyright-dark .love-np {
  display: inline-block;
  margin: 0 0 10px 0; }

@media (min-width: 768px) {
  .copyright-dark .love-np {
    margin-left: 5px; } }

.about-section {
   background: white;
    .header {
        .container {
            position: relative;
            @include respond-to(x-large) {
                max-width: 1300px;
            }
        }
    }
}

.who-we-hero {
    padding:150px 0 150px 0;
    position: relative;
    @include respond-to(small){
        height: 100vh;
    }
    &:before{
        content: "";
        position: absolute;
        top:0;
        left:0;
        right: 0;
        bottom: -200px;
        background: white;
        -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
    .about-intro{
        position: relative;
        @include respond-to(small){
            top:50%;
            @include transform(translateY(-50%));
        }
        .about-tagline{
            position: relative;
            &:before{
                @include pseudo;
                width:300px;
                height:300px;
                background: white;
                top:-40%;
                right: 0%;
                left:0;
                bottom:0;
                z-index: -1;
                margin:0 auto;
                @include border-radius(100%);
                @include box-shadow(0 40px 30px 0 rgba(black,0.2));
                -webkit-animation: slide-in-bottom 02s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: slide-in-bottom 02s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                @include respond-to(small){
                    width:400px;
                    height:400px;
                }
                @include respond-to(medium){
                    display: block;
                    
                    right: -10%;
                    left: inherit;
                }
                @include respond-to(large){
                    top:-30%;
                    width:500px;
                    height:500px;
                }
            }
        }
        .big-text{
            @include rhythm(120,180);
            @include font-weight(bold);
            position: absolute;
    top: -50%;
    color:#f1f1f1;
    text-align: right;
    opacity: 0.5;
    z-index: -2;
    display: none;
        }
        h2{
            @include rhythm(30,40);
            @include font-weight(medium);
            
            @include respond-to(medium){
                @include rhythm(40,60);
                text-align: right;
            }
            @include respond-to(large){
                @include rhythm(50,80);
            }
            span{
                display: block;
                
                color: $orange;
                @include respond-to(small){
                  @include rhythm(80,80);  
                }
            }
        }
        .about-slogan{
            padding-top:100px;
            
            @include respond-to(medium){
              padding-left: 100px;  
            }
        }
    }
    &.careers-hero{
        padding:50px 0 100px 0;
        @include respond-to(small){
            padding:150px 0;
        }
        .about-intro{
            .about-slogan{
                p{
                    @include rhythm(30,45);
                    @include font-weight(light);
                    span{
                        @include font-weight(book);
                        color: $orange;
                    }
                    @include respond-to(small){
                        @include rhythm(55,75);
                    }
                }
            }
        }
        .about-tagline{
            &::before{
                display: none;
            }
        }
    }
    &.careers-details{
        background: $dark-blue;
        height: auto;
        padding:150px 0 100px 0;
        @include respond-to(small){
            padding:250px 0 100px 0;
        }
        &:before{
            display: none;
        }
        h2{
            color: $lemon-green;
            text-align: left;
        }
        p{
            color: rgba(white,0.7);
        }
        .about-slogan{
            padding-top:0;
        }
        .button-animi{
            a{
                color: white;
                svg{
                    path{
                        fill:white;
                    }
                }
                &:hover{
                    color: $dark-blue;
                    &:before{
                        background:white;
                    }
                    svg{
                        path{
                            fill:$dark-blue;
                        }
                    }
                }
            }
        }
    }
}
.cf-players{
    background-image:url('../images/cf-players.jpg');
    @include background;
    width:100%;
    height: 800px;
    position: relative;
    -webkit-filter: grayscale(50%); 
		filter: grayscale(50%);
    &:before{
        @include pseudo;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        background:rgba(black, 0.4);
    }
    .video-caption{
        background:rgba(white,0.8);
        width:100%;
        padding:20px;
        position: absolute;
        top:0;
        @include respond-to(small-xs){
            background: white;
            width:400px;
            padding:50px 30px;
            top:50%;
            right: 50px;
            @include transform(translateY(-50%));
        }
        p{
            @include rhythm(24, 36);
            @include font-weight(light);
            color: $dark-blue;
            @include respond-to(small){
                @include rhythm(30, 45);
            }
        }
    }
}
/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
.about-video{
    .container{
        position: relative;
        @include respond-to(large){
            max-width: 1300px;
        }
        video{
            width:100%;
        }
        .video-caption{
            background:white;
            width:400px;
            padding:50px;
            position: absolute;
            top:50%;
            right: 50px;
            @include transform(translateY(-50%));
            p{
                @include rhythm(30, 45);
                @include font-weight(light);
                color: $dark-blue;
            }
        }
    }
}

.about-intro {

    h2 {
        @include rhythm($large, 45);
        letter-spacing: 1px;
        color: $dark-blue;
        @include font-weight(regular);

        @include respond-to(small) {
            @include rhythm($x-large, 90);
        }
    }
    p{
        @include rhythm($medium, 30);
    }

    .container {
        position: relative;

        @include respond-to(xx-large) {
            max-width: 1300px;
        }
    }


}

.about-image-block{
    padding-top:10%;
    margin-bottom: -20%;
    .parallax-image{
        padding:100px;
        &.middle{
            margin-top: 100px;
        }
        img{
            max-width: 100%;
        }
    }
}

//Images floating
.cf-images {
    position: relative;
    padding: 20px 0;
    background: #f1f1f1;
    @include respond-to(small) {
        padding: 50px 5%;
    }

    @include respond-to(large) {
        padding: 100px 5% 50px 5%;
    }
.cf-images-tagline{
    margin-bottom: 10px;
    @include respond-to(small){
        margin-bottom: 100px;  
    }
    @include respond-to(x-large){
        max-width: 1300px;
    }
    h2 {
        @include rhythm($large, 45);
        letter-spacing: 1px;
        color: $dark-blue;
        @include font-weight(regular);

        @include respond-to(small) {
            @include rhythm($xx-large, 90);
        }
        span{
            @include respond-to(small){
                display: block;
            }
        }
    }
    p{
        @include rhythm($medium, 30);
    }
}
    .blob {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .round{
        background: black;
        width: 300px;
        height: 300px;
        -webkit-background-clip: text;
        background-clip: text;
        @include border-radius(100%);
        .big-text{
            @include rhythm(100,120);
           
        }
    }
}
.value-wrap{
    position: relative;
   padding:0 0 100px 0;
    .container{
        @include respond-to(xx-large) {
            max-width: 1300px;
        }
    }
    .cf-values-slogan{
        padding-bottom:50px;
         h2{
             @include rhythm(30, 40);
             color: $dark-blue;
             @include respond-to(small){
                @include rhythm(60, 70);
             }
         }
         p{
             @include rhythm(20,30);
         }
     }
    .cf-info{
       @include respond-to(medium){
        padding:0;
       }
        h2, h3, p{
            text-align: left;
        }
        p{
            @include rhythm(18,26);
            color: $body-color;
        }
        &.about-info{
            padding-top:25px;
            @include respond-to(medium){
                padding-top:100px;
            }
        }
       
    }
   &.value-wrap-careers{
       background:$dark-blue;
       .cf-values-slogan{
        padding-bottom:50px;
         h2{
             color: white;
            //  @include respond-to(medium){
            //     @include rhythm(40, 70);
            //  }
            //  @include respond-to(large){
            //     @include rhythm(60, 70);
            //  }
         }
     }
     .cf-info{
         h2, h3, p{
             text-align: left;
             color: white;
         }
         p{
             @include rhythm(18,26);
             color: rgba(white,0.7);
         }
        
     }
   }
    
}
.values {

    h3 {
        color: $dark-blue;
        @include rhythm(30, 40);
        @include font-weight(book);
    }

    p {
        @include font-weight(book);
    }
}


//vision 
.vision-2020{
    background: $dark-blue;
    padding:100px 0;
    @include respond-to(large){
        height: 100vh;
    }
    .container{
        @include respond-to(large){
            position: relative;
            top:50%;
            @include transform(translateY(-50%));
        }
    }
    .big-2020{
        color: white;
        margin-bottom: 25px;
        @include respond-to(small){
            margin-bottom: 0;
        }
        span{
            display: block;
            @include font-weight(bold);
            @include rhythm(150,100);
            @include respond-to(small){
                @include rhythm(200,140);
            }
            @include respond-to(medium){
                @include rhythm(250,150);
            }
            &.last-02{
                margin-left: 30%;
                letter-spacing: -12px;
                -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    @include respond-to(small){
        margin-left: 20%;
    }
    @include respond-to(medium){
        margin-left: 40%;
    }
            }
        }
        p{
            @include rhythm(30,30);
            @include font-weight(light); 
            padding-top: 20px;
            @include respond-to(large){
                letter-spacing: 10px;  
            }
        }
    }
    .vision-view{
        h2{
            @include rhythm(40,40);
            color: white;
            margin-bottom: 20px;
            @include respond-to(large){
                @include rhythm(60,60);
            }
        }
        p{
            @include rhythm(18,26);
            color:rgba(white,0.7);
            @include respond-to(large){
                @include rhythm(20,30);
            }
        }
        .case-style{
            color:$orange;
        }
    }
}

//team
.team-section {
    position: relative;
 
.about-banner-wrap{
    position: relative;
}
.team-info{
    position: relative;
    padding:50px 0 100px 0;
    h2{
        @include rhythm(30,40); 
        color: $dark-blue;
        margin-bottom: 25px;
        @include font-weight(medium);
        @include respond-to(small){
            @include rhythm(50,60);  
        }
        }
    p{
        @include rhythm(22,34);
    }
}
.fluild-container{
    position: relative;
    padding-top: 25px;
    &:before{
        content: "";
        width: 100%;
        position: absolute;
        top:0;
        height: 60%;
        background: rgba($body-color,0.1);
    }
}
.team-list{
    // background: $dark-blue;
    padding-top: 100px;
    .container{
        @include respond-to(x-large){
            max-width: 1300px;
        }
    }
}
.team-section-wrap {
    margin-bottom: 50px;
    padding: 100px 0 50px 0;
    @include transition(all 0.3s ease-in-out);
    @include respond-to(medium){
        padding: 10% 0 0 0;
    }
    h2{
        @include rhythm(40,50);
        @include respond-to(small){
            @include rhythm(60,60); 
        }
        @include respond-to(medium){
            @include rhythm(80,70); 
        }
        span{
            color: black;
        -webkit-text-fill-color: white; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
        }
    }
}
    .team-wrap {
        margin-bottom: 50px;
        @include transition(all 0.3s ease-in-out);
    .container {
        padding-bottom: 25px;

        @include respond-to(medium) {
            padding-top: 200px;
        }

        @include respond-to(large) {
            max-width: 1300px;
            min-height: 402px;
            position: relative;
            top: 25%;
            padding-top: 0;
        }
    }

    .team-block {
        padding: 20px 25px;
        color: $dark-blue;
        text-align: center;

        h2 {
            color: $orange;
            @include rhythm($medium, 30);
            margin-bottom: 0;
        }

        .alp {
            @include rhythm($xx-large, 50);
            @include font-weight(light);
            margin-bottom: 20px;
            display: none;
            @include transition(all 0.3s ease-in-out);

            @include respond-to(large) {
                display: block;
            }
        }

        .designation {
            @include rhythm($regular, 18);
            color: $body-color;
        }
    }

    .team-thumb {
        margin: 0 auto;
        @include transition(all 0.3s ease-in-out);
        @include respond-to(small){
            width:200px;
        }
        @include respond-to(large) {
            //@include transform(scale(0));
            width: 250px;
        }

        img {
            max-width: 100%;
            display: inline-block;
            vertical-align: middle;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        }

    }

    &:hover,
    &:focus,
    &:active {
        .alp {
            opacity: 0;
            height: 0;
            margin-bottom: 0;
        }

        .team-thumb {
            @include transform(scale(1));

            img {
                -webkit-animation-name: hvr-buzz-out;
                animation-name: hvr-buzz-out;
                -webkit-animation-duration: 0.75s;
                animation-duration: 0.75s;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
                animation-delay: 0.5s;
            }
        }
    }
    }

    .team-tagline {
        padding: 50px 0;
        margin-bottom: 50px;
        @include respond-to(large) {
            padding: 50px 25px;
            animation: pulse 10s linear infinite alternate;
            width: 380px;
            @include box-shadow(0 5px 15px rgba(0, 0, 0, 0.4));
        }

        @include respond-to(large) {
            position: absolute;
            top: -10%;
            z-index: 1;
            min-height: 352px;
        }

        h2 {
            @include rhythm($large, 36);
            
            margin-bottom: 15px;
            @include respond-to(large){
                color: white;
            }
        }

        p {
            
            @include respond-to(large){
                color: white;
            }
        }
    }

    .about-image {
        @include respond-to(small) {
            max-width: 1024px;
            margin:0 auto;
            padding-bottom: 100px;
        }

        img {
            max-width: 100%;
        }
    }
}
//team
  .team-list{
    h2{
        position: relative;
        z-index: 1;
        @include rhythm(30, 38);
        @include font-weight(book);
        color:$orange;
        top:-10px;
        @include respond-to(small){
            @include rhythm(20, 28);
        }
        @include respond-to(medium){
            @include rhythm(24, 30); 
        }
        span{
            @include font-weight(medium);
            display: block;
            color: $dark-blue;
            position: absolute;
            left:50%;
        }
      }
      .team-level{
        padding-bottom: 100px;
        text-align: center;
      }
      .designation{
          @include respond-to(767px){
              margin-bottom: 35px;
              display: block;
          }
      }
  }
  .photo{
    position: relative;
    width:100%;
    margin:0 auto 20px auto;
    text-align: center;
   @include respond-to(medium){
    width:220px;
    height: 320px;
   }
      img{
        max-width: 100%;
        object-fit: cover;
        position: relative;
        filter: grayscale(100%) contrast(120%);
        -webkit-filter: grayscale(100%) contrast(120%);
        -moz-filter: grayscale(100%) contrast(120%);
        -ms-filter: grayscale(100%) contrast(120%);
        box-shadow: 10px 15px 25px 0 rgba(0,0,0,.2);
        //display: block;
        transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);
        top: -10px;
      }
      &:hover{
        img{
            box-shadow: 1px 1px 10px 0 rgba(0,0,0,0);
            top: 0;
            filter: none;
        -webkit-filter: none;
        -moz-filter: none;
        -ms-filter: none;
          }
          .glow{
            top: 0;
            transform: rotate(45deg) translate(450%, 0);
            transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
      }
      
      .glow-wrap{
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        margin-top: -10px;
      }
      .glow{
        display: block;
        position:absolute;
        width: 40%;
        height: 200%;
        background: rgba(255,255,255,.2);
        top: 0;
        filter: blur(5px);
        transform: rotate(45deg) translate(-450%, 0);
        transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
  }

//culuture
.culture-slider{
    padding:100px 0;
    .slick-slide{
        margin:0 20px;
        &:nth-child(odd){
            @include respond-to(small){
                padding-top:120px;
            }
        }
        img{
            max-width:100%;
            @include respond-to(large){
                max-width:400px;
            }
        }
    }
    .slick-arrow{
        display: none!important;
    }
}
.cloudian-title{
    @include rhythm(30,40);
            @include font-weight(medium);
            color: $dark-blue;
            margin-bottom: 100px;
            display: block;
            @include respond-to(small){
                @include rhythm(50,80);
            }
}
//careers
.cf-looking{
    padding:50px 0;
    @include respond-to(small){
        padding:200px 0;
    }
    .container{
        @include respond-to(large){
            max-width: 1300px;
        }
    }
    h2{
        @include rhythm(30, 30);
        color: $dark-blue;
        @include font-weight(medium);
        margin: 0px 0 30px 0;
        display: block;
        @include transition(all 0.5s ease-in-out);
        text-align: left;
        @include respond-to(small){
            @include rhythm(40, 40);
		}
        @include respond-to(medium){
			@include rhythm(60, 60);
		}
    }
    p{
        @include rhythm(24, 34);
        color: $body-color;
        @include font-weight(regular);
    }
    ul{
        padding: 0;
        margin:0;
        li{
            @include rhythm(18,24);
            @include font-weight(light);
            color: $body-color;
            cursor: pointer;
            margin: 0 0 1px 0; 
            padding: 10px 20px 10px 0px;
            position: relative;
            list-style-type: disc;
            list-style-position: inside;
            @include respond-to(small){
                @include rhythm(20,30);
                width:45%;
                float: left;
            }
        }
    }
}
.open-positions-wrap{
    padding: 50px 0;
    @include respond-to(small){
        padding:100px 0 200px 0;
    }
    &-details{
        background:rgba($body-color,0.1);
        @include respond-to(small){
            padding: 150px 0;
        }
    }
    .container{
        @include respond-to(mid-large){
            max-width: 1300px;
        }
    }
    h2{
        @include rhythm(30, 30);
        color: $dark-blue;
        @include font-weight(medium);
        margin: 0px 0 30px 0;
        display: block;
        @include transition(all 0.5s ease-in-out);
        text-align: left;
        @include respond-to(small){
            @include rhythm(40, 40);
            margin-bottom: 100px;
		}
        @include respond-to(medium){
			@include rhythm(60, 60);
		}
    }
    .open-positions{
        @include respond-to(large){
            padding-left: 50px;
        }
    }
    ul{
        padding: 0;
        margin:0;
        li{
            @include rhythm(18,24);
            @include font-weight(light);
            color: $body-color;
            margin: 0 0 1px 0; 
            padding: 10px 20px 10px 0px;
            position: relative;
            list-style-type: none;
            border-bottom: 1px solid $body-color;
            @include respond-to(small){
                @include rhythm(20,30);
            }
            &:last-child{
                border-bottom: none;
            }
            a{
                display: block;
                color:$dark-blue;
                &:hover{
                    text-decoration: none;
                    h4{
                        color:$orange;
                    }
                }
            }
            h4{
                @include rhythm(20,30);
                @include font-weight(medium);
                position: relative;
                &:after{
                    content:"+"!important;
                    @include pseudo;
                    @include rhythm(24,40);
                    @include font-weight(light);
                    top:0;
                    right:0;
                }
                @include respond-to(small){
                    @include rhythm(30,40);
                }
            }
            p{
                @include rhythm(18,24);
                color: $body-color;
            }
        }
    }
    .careers-video{
        display: none;
        @include respond-to(large){
            display: block;
        }
        .block-media{
            position: relative;
            line-height: 0;
            height: auto;
            &:before{
                content:"";
                @include pseudo;
                top:0;
                left:0;
                right: 0;
                bottom: 0;
                background:rgba(black,0.5);
                z-index: 1;
            }
            video{
                max-width: 100%;
            }
        }
    }
}
//careers details
.position-details{
    padding:50px 0;
    @include respond-to(small){
        padding:100px 0;
    }
    .container{
        @include respond-to(mid-large){
            max-width: 1300px;
        }
    }
    p{
        @include respond-to(small){
            @include rhythm(20,30);
        }
    }
    .position-required{
        margin-top: 50px;
        p{
            @include font-weight(medium);
            color: $dark-blue;
        }
    }
    ul{
        padding:0;
        margin:25px 0 25px 20px;
        li{
            list-style-type: disc;
            padding-left:10px;
            margin-bottom: 15px;
            line-height: 24px;
        }
    }
}
*{
	@include respond-to(large){
        cursor: none;
    }
}
body,
html {
 height: 100%;
}
:focus{
    outline: none;
}
body {
    background: white;
    font-family: $stolzl;
    @include font-size($regular);
    color: $body-color;
    @include font-weight(book);
    margin:0;
    padding:0;
    transition: all 200ms;
    will-change: background;
    &.fixbody{
        overflow: hidden;
    }
}

p {
    font-family: $stolzl;
    @include line-height(24);
}

section {
    &:after {
        @include overflow;
    }
}

button {
    &:focus {
        border: none;
        outline: none;
    }
}

//buton
.button-animi {
    position: relative;
    margin-top: 40px;
    button{
        background: none;
        border:none;
        padding:0;
    }
    a, button {
        @include rhythm($small, 35);
        @include font-weight(medium);
        color: $dark-blue;
        letter-spacing: 0px;
        position: relative;
        @include transition(all 0.3s ease-in-out 0s);

        @include respond-to(small-xs) {
            @include rhythm($regular, 35);
           letter-spacing: 1px;
        }
        &:focus{
           outline:none;
        }
        &:before {
            @include pseudo;
            background: rgba($body-color, 0.3);
            width: 35px;
            height: 35px;
            @include border-radius(35px);
            @include transition(width 0.5s ease-in-out);
            -webkit-animation-name: shake;
            animation-name: shake;
            -webkit-animation-duration: 8s;
            animation-duration: 8s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            -webkit-animation-iteration-count: infinite;
        }

        span {
            position: relative;
            z-index: 2;
            padding-left: 37px;
        }

        svg {
            width: 35px;
            left: 10px;
            position: relative;
            z-index: 2;
        }

        &:hover {
            padding: 0 37px 0 0px;
            text-decoration: none;
            color: white;
            display: inline-block;

            &:before {
                background: $dark-blue;
                width: 100%;
                @include transition(width 0.5s ease-in-out);
                animation-name: none;
                -webkit-animation-name: none;
            }

            svg {
                left: 20px;
                @include transition (all 0.8s ease-in-out 0s);

                path {
                    fill: white;
                }
            }
        }
    }
}
//pages link
.pages-link{
    padding:50px 0 60px 0;
    .container{
        @include respond-to(mid-large){
            max-width: 1300px;
        }
        .col-md-12{
            p{
                color: $dark-blue;
                @include font-weight(regular);
            }
        }
        .col-md-6{
            &:nth-child(1n){
                @include respond-to(767px){
                    margin:15px 0;
                }
            }
        }
    }
    .big-button-text{
        a{
           @include rhythm(20, 30);
        color: $body-color; 
        @include font-weight(book);
        span{
            position: relative;
            &:before{
                content: "";
                position: absolute;
                bottom: -10px;
                left:0;
                width:30px;
                height: 5px;
                background: $body-color;
                margin:0 auto;
                @include border-radius(5px);
                @include transition(all 0.3s ease-in-out);
            }
        }
        &:hover{
            text-decoration: none;
            color:$orange;
            span{
                &:before{
                    width:100%;
                    background: $orange;
                    @include transition(all 0.3s ease-in-out);
                }
            }
        }
        }
        
    }
}
.heading-2 {
    @include rhythm($medium, 30);
    color: $dark-blue;
    margin-bottom: 20px;
@include respond-to(small-xs){
    @include rhythm($large, 36);
}
    @include respond-to(small) {
        @include rhythm($x-large, 48);
    }
}

.highlight {
    padding: 20px 0;
    color: $dark-blue;
    @include rhythm(30, 45);
    display: block;
}

//big button
.big-button {
    padding: 50px 0 10px 0;
    display: block;
    text-align: center;
@include respond-to(large){
    padding: 100px 0 10px 0;
}
    a {
        @include rhythm($medium, 30);
        color: $dark-blue;
        border: 5px solid $dark-blue;
        padding: 20px 20px 25px 20px;
        display: inline-block;
        @include transition(all 0.3s ease-in-out);

        @include respond-to(small-xs) {
            padding: 20px 30px 25px 30px;
        }

        @include respond-to(large) {
        }

        span {
            width:40px;
            svg{
                width:40px;
            }
        }

        &:hover {
            background: $dark-blue;
            text-decoration: none;
            color: white;

            span {
                text-decoration: none;
                color: white;
            }
        }
    }
}
.main-front{
   @include respond-to(large){
       //background:white;
    position: relative;
    //z-index: 2;
   }
}
.footer-transparent-space{
    @include respond-to(large){
       height: 100%; 
    }
}
.hidden{
    display: none;
}
.case-style {
    @include font-weight(medium);
    color:$dark-blue;
}
@include respond-to(1199px){
    .my-cursor, 
    .follow{display:none;}
}
@include respond-to(large){
.my-cursor {
	position: absolute;
	background-color: $orange;
	width: 10px;
    height: 10px;
    text-align: center;
	border-radius: 100%;
	z-index: 1;
	transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
	user-select: none;
	pointer-events: none;
	z-index: 10000;
    transform: scale(1);

}
.my-cursor.active {
	opacity: 0.5;
	transform: scale(0);
}
.my-cursor.hovered {
	opacity: 0.08;
}

.follow {
	position: absolute;
    background-color:transparent;
    border:1px solid $body-color;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	z-index: 1;
	transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
	user-select: none;
	pointer-events: none;
	z-index: 10000;
    transform: translate(2px, 2px);

}
.follow.active {
    background: rgba($body-color, 0.1);
    border-color: rgba($body-color, 0.2);
	opacity: 0.7;
	transform: scale(3);
}
.follow.hovered {
	opacity: 0.08;
}
}
//banne innher hero
.b-hero-inner {
    padding: 150px 0 50px 0;
    position: relative;

    @include respond-to(small) {
        padding: 15% 0 7% 0;
    }

    h2 {
        @include rhythm(40, 50);
        color: $dark-blue;
        margin-bottom: 20px;

        @include respond-to(small-xs) {
            @include rhythm(60, 80);
            margin-bottom: 40px;
        }

        @include respond-to(large) {
            @include rhythm($xx-large, 100);
        }

        @include respond-to(x-large) {
            @include rhythm($xxx-large, 120);
        }
    }

}

// shapes animations
.line {
    width: 100%;
    padding: 1px 0px;
}
.circle {
    border-radius: 50%;
}

.triangle {
    pointer-events: none;
    position: relative;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 36px 46px 36px;
    border-color: transparent transparent currentColor transparent;
}


//back arrow button
.button-back {
    width: 100%;
    margin: 50px 0;
    text-align: center;

    a {
        color: $dark-blue;
        @include font-weight(bold);
        padding-left: 50px;
        position: relative;

        img {
            position: absolute;
            left: 0;
            top: -16px;
            width: 50px;
            @include transition(all 0.3s ease-in-out);
        }

        &:hover {
            text-decoration: none;

            img {
                left: -10px;
            }
        }
    }
}


// //loader
.loaded{
    .loader {
        -webkit-animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
        animation: animLoadedHeader 1s cubic-bezier(0.7,0,0.3,1) forwards;
        animation-delay: 3s;
        -webkit-animation-delay: 3s;
    }
    
    @-webkit-keyframes animLoadedHeader {
        to { -webkit-transform: translate3d(0,-100%,0); }
    }
    
    @keyframes animLoadedHeader {
        to { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
    }
}
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;

    .loader-fill {
        max-width: 600px;
        height: 280px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        @include transform(translateY(-50%));

        h2 {
            color: white;
            @include rhythm(24, 30);
            @include font-weight(book);
            padding:25px 15px 0 15px;
            margin:0;
            opacity: 0;
            position: absolute;
            bottom: -60px;
            left:0;
            right:0;
            &.slideDown {
                animation: slideInDown 3s alternate;
                opacity: 1;
            }
        }
    }
}


//expanding load text
.loader {
    position: fixed;
    left: 0;
    top: 0;
    background: $orange;
    width: 100%;
    height: 100vh;
    &.loaded{
        opacity: 0;
        z-index: -1;
        @include transition(all 0.4s ease-in-out);
        
    }
  }
  
  .load-text {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: fit-content;
    display: flex;
    
  }
  .loaded-text {
    transform: scale(1.5);
    animation: scaleText .5s linear .5s forwards;
    svg{
        width:65px;
    }
  }
  
  .loading-text {
    width: 0;
    overflow: hidden;
    margin:12px 0 0 10px;
    animation: expand .5s linear 1.5s forwards;
    svg{
        width: 190px;
    }
   
  }
  .abrand{
      width: 300px;
      position: absolute;
      top:60px;
      left:0;
      font-size: 20px;
      opacity: 0;
    display: block;
    margin-top:20px;
    overflow: hidden;
    text-align: center;
    color: white;
    //position: relative;
    transition: all 1s ease-in-out ;
    //animation-delay: 1s;
    animation: expands 1s linear 2.5s forwards;
    span{
        @include font-weight(bold);
    }
}
  
  @keyframes scaleText {
    from {transform: scale(1.5)}
    to {transform: scale(1)}
  }
  
  @keyframes expand {
    from {width: 0;}
    to {width:190px;}
  }
  @keyframes expands {
    from {transform: translateY(-100%);opacity: 0;}
    to {transform: translateY(0);opacity: 1;}
  }
  
  @keyframes backgroundChange {
    from {background-color: black}
    to {background-color: black}
  }
  
  @keyframes colorChange {
    from {color: black}
    to {color: white}
  }
  
  @keyframes fadeOut {
    from {opacity: 1}
    to {
      opacity: 0; 
      z-index: -1;
    }
  }
  //test
  .puff-in-center {
	-webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
	        animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
 @-webkit-keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  //bulb animation
  .blub-animation{
	
		@include respond-to(small){
            align-content: center;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin: auto;
	background-color: rgba(white,0.2);
	//background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
	box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
	animation: morphing 10s infinite;
	overflow: hidden;
        }
  }
	

	@keyframes morphing {
		0% {
			border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
			box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
		}
		25% { 
			border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
		}
		50% {
			border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
			box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
		}
		75% {
			border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
		}
    }
    
    .heartbeat {
        -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
                animation: heartbeat 1.5s ease-in-out infinite both;
    }





  
 
.mainContent{
    width: 100%;
  position: relative  ;
}

  
.sidebar{
    
    @include respond-to(medium){
        max-width: 400px;
        position: absolute;
    }
  &.fixed{
    @include respond-to(medium){
        position: fixed;
        top: 0   ;
    }
  }
     .cf-slogan-block{
        padding:80px 0 0px 0;
     }
}
  

.content{
  float: right;
  width: 70%;
  background: #666c82;
  height: 1200px;
  padding: 45px 15px  ;
}
  
//careers block
.join-career{
    background:#f2f2f2;
    padding:50px 0;
    @include respond-to(small-xs){
     text-align: center;   
    }
    .button-animi{
        
        @include respond-to(small-xs){
          display: inline-block;  
        }
    }
    .container{
        @include respond-to(mid-large){
            max-width: 1300px;
        }
    }
    h2{
        @include rhythm(25,35);
        font-weight: 300;
        color: $dark-blue;
        @include respond-to(medium){
            @include rhythm(30,40);
        }
        @include respond-to(large){
            @include rhythm(40,50);
        }
    }
}
//back button
.back-btn {
    position: relative;
    margin-top: -20px;
    margin-bottom: 30px;
    @include respond-to(small){
        margin-top: -50px;
    margin-bottom: 30px;
    }
    a {
        @include rhythm($small, 35);
        @include font-weight(medium);
        color: $dark-blue;
        letter-spacing: 0px;
        position: relative;
        @include transition(all 0.3s ease-in-out 0s);

        @include respond-to(small-xs) {
            @include rhythm($regular, 35);
           letter-spacing: 1px;
        }
        &:focus{
           outline:none;
        }

        span {
            position: relative;
            z-index: 2;
            padding-left: 5px;
        }

        svg {
            width: 35px;
            left:-5px;
            top: -2px;
            position: relative;
            z-index: 2;
            @include transform(rotate(-180deg));
        }

        &:hover {
            padding: 0 37px 0 0px;
            text-decoration: none;
            color: $orange;
            display: inline-block;
            svg{
                path{
                    fill:$orange;
                }
            }
        }
    }
}
//slick slider style
/* Slider */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	&:focus {
		outline: none;
	}
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		//cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
	&:focus{
		outline: none;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	overflow:hidden;
	[dir="rtl"] & {
		float: right;
	}

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
		overflow:hidden;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}
//slick dots
.slick-dots {
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		list-style-type: none;
		display: inline-block;
		margin: 5px 20px 5px 0;

		button {
			width: 15px;
			height: 15px;
			position: relative;
			@include rhythm(0, 0);
			color: rgba(white,0.3);
			border:none;
			@include border-radius(100%);
			opacity: 0.5;
		}

		&.slick-active {
			button {
				opacity: 1;
				background-color: black;
				box-shadow: 0 5px 15px 0 rgba(0,0,0,0.7);
			}
		}
	}
}
//home
.bg {
	width: 100%;
	min-height:568px;
	@include background;
	position: relative;

	@include respond-to(small){
		min-height: 650px;
	}
	@include respond-to(large){
		min-height:auto;
	}
	@include respond-to(large){
		height: 100vh;
	}
	@include respond-to(xx-large){
		height: auto;
	}
	&1{
		@include linearGradient( #2c9dd7, #54c4fe);
		
	}
	&2 {
		
		@include respond-to(1199px){
			@include linearGradient(#8b3fae,#3f02a8);
		}
	}
	&3{
		
		@include respond-to(1199px){
			@include linearGradient(#300094, #b012e8);
		}
	}
	svg{
		height: 100%;
		display: none;
		@include respond-to(large){
			display: block;
		}
	}
}

.block-media {
	width: 100%;
	height: 100%;
	position: relative;

	.slick-arrow {
		position: absolute;
		display: none;
		top: 40%;
	}
	.slick-dots{
		position:absolute;
		height:3px;
		background:rgba(255,255,255,.20);
		bottom:0px;
		width:100%;
		top:0;
		left:0px;
		padding:0px;
		margin:0px;
		list-style-type:none;
	  }
	  .slick-dots li button{display:none;}
	  .slick-dots li{
		float:left;
		width:0px;
		height:3px;
		background:rgba($dark-blue,0.6);
		position:absolute;
		left:0px;
		top:0px;
	  }
	  
	  .slick-dots li.slick-active{
		width:100%;
		animation:ProgressDots 11s both;
	  }
}

.block-text {
	width: 200px;
	background-color: #2c3e50;
	position: absolute;
	top: 30%;
	right: 20%;
	margin: 0 auto;
	z-index: 3;
}

// Content
.carousel-bg {
	.slick-list {
		padding: 0 !important;
	}
}

.carousel-text {
	font-weight: 100;
	padding: 20px;

	.title {
		color: #bdc3c7;
		font-size: 1.05em;
		font-weight: 300;
		text-transform: uppercase;
	}

	.headline {
		color: #fff;
		text-align: justify;
	}
}

//home slider
.banner-wrapper {
	width: 100%;
	@include respond-to(medium){
		height:100vh;
		min-height: auto;
		padding-bottom: 0;
	}
	@include respond-to(large){
		height:100%;
	}
}

//hero banner
.hero-banner {
	height: 100vh;
	position:relative;
	overflow: hidden;
	// padding-bottom: 200px;
	@include respond-to(medium){
		padding-bottom: 0;
	}
	.block-media{
		position: relative;
		display: none;
		@include respond-to(large){
			display:block;
		}
		&:before{
			content: "";
			background: rgba(black,0.7);
			position: absolute;
			top:0;
			left: 0;
			right: 0;
			bottom: 0;
			//z-index: 1;
		}
		video{
			width:100%;
			height:100%;
			object-fit: cover;
			// filter: grayscale(0.8);
			// -webkit-filter: grayscale(0.8);
		}
	}
	.container {
		position:absolute;
			top:50%;
			left:0;
			right:0;
			margin:0 auto;
			z-index: 1;
			@include transform(translateY(-50%));
		@include respond-to(x-large) {
			max-width: 1300px;
		}
	}

	.cta {
		padding-top: 50px;
		padding-left:0;
		padding-right: 0;
		@include respond-to(small-xs){
			padding-top: 5%;
		}
		@include respond-to(medium){
			padding-top: 0;
		}
		.we {
			@include rhythm(20, 30);
			color: #fc0;
			padding-bottom: 5px;
			@include font-weight(book);
			//text-transform: uppercase;
			display: block;
			@include respond-to(small-xs){
				@include rhythm(30, 40);
				@include font-weight(medium);
			}
			@include respond-to(small){
				@include rhythm(40, 40);
			}
		}

		h2 {
			@include rhythm(35, 40);
			color: white;
			@include font-weight(medium);
			margin-bottom:20px;
			@include respond-to(small-xs){
				@include rhythm(60, 70);
				margin-bottom:40px;
			}
			@include respond-to(large){
				@include rhythm(65, 70);
			}
			@include respond-to(xx-large){
				@include rhythm($xx-large, 90);
			}
		}
		&.caseshape{
			h2{
				@include respond-to(medium){
					@include rhythm(58, 70);
				}
			}
		}
	}
}

.hero-content-wrap {
	width:95%;
	height: auto;
	//border:20px solid rgba(white,0.2);
	position: absolute;
	top: 20%;
	left: 0;
	right: 0;
	margin:0 auto;
	@include transform(translateY(-50%));
	@include respond-to(small){
		width:500px;
	height: 500px;
	text-align: center;
	}
	@include respond-to(large){
		width:560px;
	height: 560px;
		top: 25%;
	}
	&::before{
		content:"";
		width:560px;
	height: 560px;
	display: block;
		animation: vanishIn 2s ease-in-out;
    //animation-iteration-count: alternative; 
	}
	h1{
		@include rhythm(40,50);
		@include font-weight(regular);
		color:rgba(white,0.8);
		margin-bottom: 0;
		@include respond-to(small){
			@include rhythm(50,70);
		}
		span{
			text-decoration: underline;
			@include font-weight(bold);
			color: white;
		}
	}
	.slogan{
		@include rhythm(18,26);
		@include font-weight(book);
		color:rgba(white,0.7);
		margin-top: 15px;
		@include respond-to(small){
			@include rhythm(20,30);
		}
	}
	.hero-content{
		position: absolute;
		top:52%;
		left:0;
		right: 0;
		padding:0 15px;
		@include transform(translateY(-50%));
		@include respond-to(small){
			padding:0 45px;
		}
		.hero-content-block{
			position: absolute;
		top:50%;
		left:0;
		right: 0;
		padding:0 15px;
		@include transform(translateY(-50%));
		@include respond-to(small){
			padding:0 45px;
		}
		}
		.button-animi{
			margin:10px 0;
			a{
				&:before{
				//display: none;
				background: transparent;
			}
			&:hover{
				&::before{
					width: 75px;
					background: rgba($orange, 0.5);
				}
			}
			svg{
				path{
					fill:white;
				}
			}
			}
			
		}
	}
	.slick-active {
		.cta{
			.we,
			h1{
				animation:fadeInDown 1s both 1s;
			}
		}
		.shapes {
			transform: scale(1);
			opacity: 1;
			@include respond-to(small-xs){
				width:100%;
			}
		}
		.button-animi{
			animation:fadeInUp 1s both;
			animation-delay: 1s;
		}
	}
}

.image-section {
	height: 100%;
	display: none;
	@include respond-to(medium){
		display: block;
	}
	@include respond-to(767px){
		position: relative;
		height: auto;
	}
	.w-100 {
		height: 100%;
		@include respond-to(medium){
			padding-left:10%;
		}
	}
	&.case-shapes{
		svg{
			.circle{
				@include respond-to(991px){
					display: none;
				}
				path{
					animation:bulb 3s infinite linear;
				}
			}
			.dots{
				path{
					&:nth-of-type(1n){
						animation:nudge-up 5s infinite alternate;
					}
				}
			}
			.head{
				animation:shake-h 8s infinite alternate;
			}
			.rajan{
				animation:nudge 7s infinite;
			}
		}
	}
	&.service-banner{
		svg{
			.head{
				animation:shake-h 8s infinite alternate;
			}
			.highlight{
				animation: nudge-up 5s infinite linear;
			}
			.code-line{
				path{
					&:nth-of-type(2n){
						animation: nudge 5s infinite linear;
					}
					&:nth-of-type(1){
						animation: nudge 5s infinite linear;
					}
				}
			}
		}
	}
	&.impact{
		svg{
			width: 90%;
			.block-2{
				animation: nudge-up 5s infinite linear;
			}
			.head{
				animation:shake-h 8s infinite alternate;
			}
		}
	}
}

.slider-image {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	.slick-list {
		height: 100%;

		.slick-track {
			height: 100%;
		}
	}
}


.screens {
	position: absolute;
	top: 65%;
	left: 0%;
	width: 90%;
	right:0;
	margin:0 auto;
	transform: translateY(-60%);
	@include respond-to(small-xs){
		width: 80%;
	}
	@include respond-to(medium){
		width:100%;
		right:inherit;
	}
	img {
		max-width:100%;
		@include box-shadow(0 0 15px rgba(black, 0.2));
		@include border-radius(10px);
		display: inline-block;
		transition: all 500ms ease-in-out;
		transform: scale(1.25, 1.25);
		animation-delay: 5s;
	}

	.screen-slide {
		max-width: 250px;
		background: black;
		overflow: hidden;
		position: absolute;
		top: -150px;
		z-index: 1;
		left: 25%;
		padding:55% 40%;
		left:0;
		right:0;
		margin:0 auto;
		@include box-shadow(0 0 15px rgba(black, 0.2));
		@include border-radius(10px);
		transform: translate3d(0, 0, 0);
		@include respond-to(small-xs){
			max-width: 280px;
			top: -10%;
			padding: 30% 24%;
		}
		@include respond-to(medium){
			padding:38% 25%;
			max-width: 300px;
		}
		@include respond-to(large){
			max-width: 320px;
			padding:31% 20%;
		}
		@include respond-to(xx-large){
			max-width: 375px;
			padding:31% 20%;
		}
		>div {
			width: 100%;
			position: absolute;
			top: 0%;
			left: 0;
			transform: translate3d(0, 0, 0);
		}
		.mover {
			//background: url('../images/job-m.jpg') repeat-Y;
			@include background;
			//background-size: cover 950px;
			animation: moveSlideshow 7s linear infinite alternate; 
			animation-delay:1s;
			img{
				max-width:100%;
			}
		}
	}
	//screens
	.screen-thumb1{
		width:35%;
		position:relative;
		left:0%;
		top:50px;
		display:none;
		@include respond-to(small-xs){
			width:30%;
			top:35px;
			display:inline-block;
		}
		@include respond-to(medium){
			width:35%;
			top:50px;
		}
		@include respond-to(large){
			width:30%;
			left:10%;
		}
	}
	.screen-thumb2{
		width:35%;
		position:absolute;
		right:0;
		top:-30%;
		display:none;
		@include respond-to(small-xs){
			width:30%;
			display:inline-block;
		}
		@include respond-to(medium){
			width: 35%;
			right:7%;
		}
		@include respond-to(large){
			width:30%;
			right:15%;
		}
	}


	.screen99 {
		width: 200px;
		margin: 0 auto;
		position: relative;
		left: 0%;
		top:50px;
		@include respond-to(small-xs){
			width:220px;
			top:35px;
		}
		@include respond-to(medium){
			width:250px;
		}
		@include respond-to(large){
			width:280px;
			top:0;
		}
		@include respond-to(xx-large){
			width:350px;
			left:-5%;
		}
		img {
			max-width: 100%;
			@include border-radius(30px);
		}
	}
}

.slick-slide {
	&.slick-active {
		.screens {
			img {
				transform: scale(1, 1);
			}
		}
	}
}
//slider for product
.product-slider-wrapper{
	background:$orange;
	padding:100px 0;
	overflow: hidden;
	position: relative;
	@include respond-to(large){
		height: 100vh;
	}
	h2{
		color:white;
		display: block;
		&.big-text{
			@include rhythm(50,50);
			@include font-weight(bold);
			color:rgba(white,0.2);
			position: relative;
			left:0;
			top:10px;
			@include respond-to(small){
				@include rhythm(70,60);
			}
			@include respond-to(large){
				@include rhythm(80,70);
			}
			@include respond-to(mid-large){
				@include rhythm(130,120);
				left:-14%;
			}
			@include respond-to(xx-large){
				@include rhythm(200,160);
				top:-10px;
				left:-20%;
			}
			br{
				display: none;
				@include respond-to(medium){
					display: block;
				}
			}
		}
	}
	small{
		@include rhythm(24,20);
		@include font-weight(light);
		text-transform: uppercase;
		color: white;
		width: 100%;
	}
	.slick-initialized .slick-slide{
		@include respond-to(large){
		overflow: inherit;	
		}
	}
	.slick-slider{
		.slick-list{
			@include respond-to(large){
				max-width: 1170px;
				margin:0 auto;
				overflow: inherit;
				padding:0 15px!important;
			}
		}
	}
	.container{
		&.container-product{
			@include respond-to(large){
				max-width: 100%;
			}
		}
	}
	.product-slider{
	padding-top: 30px;
	.slick-dots{
		max-width: 1170px;
		margin:25px auto 0 auto;
		display: none;
		opacity: 0;
	}
		.thumb-img{
			@include background;
			width:100%;
			height: 200px;
			margin:0 5px;
			display: block;
			position: relative;
		
			filter: grayscale(0.5);
			-webkit-filter: grayscale(0.7);
			@include respond-to(small){
				height: 380px;
			}
			@include respond-to(medium){
				width:540px;
			height: 330px;
			margin:0;
			box-shadow:0 0px 20px 0 rgba(0,0,0,0.4);
			}
			&:before{
				content: "";
				background-color: rgba($dark-blue,0.6);
				position: absolute;
				top:0;
				left:0;
				right: 0;
				bottom: 0;
				@include transition(background-color 0.3s ease-in-out);
			}
		}
		figcaption{
			color:White;
			margin-top: -80px;
			@include respond-to(medium){
				margin-left:15px;
				margin-top: 0;
			}
			h2{
				position: relative;
				@include rhythm(50,60);
				margin-top: 20px;
				@include respond-to(small){
					@include rhythm(80,70);
					letter-spacing: 10px;
				}
				@include respond-to(medium){
					@include rhythm(80,70);
					letter-spacing: 10px;
					left:-55%;
					margin-top: 0;
					text-shadow: 0px 2px 20px rgba(0,0,0,0.4);
					-webkit-text-shadow: 0px 2px 20px rgba(0,0,0,0.4);
				}
			}
		}
	}
	figure{
		&:hover{
			.thumb-img{
				filter: grayscale(0);
			-webkit-filter: grayscale(0);
				&:before{
					background-color: transparent;
				}
			}
		}
	}
}
//slider for casestudy
.case-slider-wrapper{
	background:rgba($body-color,0.05);
	padding:100px 0;
	overflow: hidden;
	position: relative;
	.slick-initialized .slick-slide{
		@include respond-to(large){
		overflow: inherit;	
		}
	}
	.slick-slider{
		.slick-list{
			@include respond-to(large){
				max-width: 1170px;
				margin:0 auto;
				overflow: inherit;
				padding:0 15px!important;
			}
		}
	}
	.container{
		&.container-casestudy{
			@include respond-to(large){
				max-width: 100%;
			}
		}
	}
	.case-slider{
	padding-top: 30px;
	.slick-dots{
		max-width: 1170px;
		margin:25px auto 0 auto;
	}
		.thumb-img{
			//box-shadow:0 0px 20px 0 rgba(0,0,0,0.4);
			margin:0 auto;
			img{
				max-width: 100%;
			}
		}
	}
	.slick-dots li button{
		background:grey;
	}
}
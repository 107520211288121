//hamburger animation
@-webkit-keyframes intervalHamburgerBorder {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    80% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
    }
}

@keyframes intervalHamburgerBorder {
    0% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    80% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
    }
}

//animation for buttons
@-webkit-keyframes shake {

    0%,
    100% {
        -webkit-transform: translateX(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translateX(-3px);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translateX(3px);
    }
}

@keyframes shake {

    0%,
    100% {
        transform: translate(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate(-3px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate(3px);
    }
}

//head-shake
@-webkit-keyframes shake-h {

    0%,
    100% {
        -webkit-transform: translate(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate(-1px);
    }

    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate(0px);
    }
}

@keyframes shake-h {

    0%,
    100% {
        transform: translate(0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate(-1px);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(0px);
    }
}
//loader wave animation
@keyframes wave-front {
    100% {
        transform: translate(-50%, 0);
    }
}
@-webkit-keyframes wave-front {
    100% {
        transform: translate(-50%, 0);
    }
}
@-moz-keyframes wave-front {
    100% {
        transform: translate(-50%, 0);
    }
}
@-ms-keyframes wave-front {
    100% {
        transform: translate(-50%, 0);
    }
}

@keyframes wave-back {
    100% {
        transform: translate(50%, 0);
    }
}
@-webkit-keyframes wave-back {
    100% {
        transform: translate(50%, 0);
    }
}
@-moz-keyframes wave-back {
    100% {
        transform: translate(50%, 0);
    }
}
@-ms-keyframes wave-back {
    100% {
        transform: translate(50%, 0);
    }
}


//shape animations
@keyframes pulse {

    0%,
    100% {
        background-color: $orange;
    }

    50% {
        background-color: $green;
    }
}

@keyframes pulse-1 {

    0%,
    100% {
        background-color: $blue;
    }

    50% {
        background-color: $sea;
    }
}

@keyframes nudge {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(10px, 0);
    }

    80% {
        transform: translate(-10px, 0);
    }
}

@keyframes nudge-up {

    0%,
    100% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 10px);
    }

    80% {
        transform: translate(0, -10px);
    }
}


//ripple
@keyframes ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(white, 0.3),
            0 0 0 7px rgba(white, 0.3),
            0 0 0 9px rgba(white, 0.3),
            0 0 0 11px rgba(white, 0.3);
    }

    100% {
        box-shadow: 0 0 0 7px rgba(white, 0.3),
            0 0 0 9px rgba(white, 0.3),
            0 0 0 11px rgba(white, 0.3),
            0 0 0 13px rgba(white, 0);
    }
}

//ripple blue
@keyframes ripple-blue {
    0% {
        box-shadow: 0 0 0 0 rgba($blue, 0.3),
            0 0 0 7px rgba($blue, 0.3),
            0 0 0 9px rgba($blue, 0.3),
            0 0 0 11px rgba($blue, 0.3);
    }

    100% {
        box-shadow: 0 0 0 7px rgba($blue, 0.3),
            0 0 0 9px rgba($blue, 0.3),
            0 0 0 11px rgba($blue, 0.3),
            0 0 0 13px rgba($blue, 0);
    }
}

//rippble green
@keyframes ripple-green {
    0% {
        box-shadow: 0 0 0 0 rgba($green, 0.3),
            0 0 0 7px rgba($green, 0.3),
            0 0 0 9px rgba($green, 0.3),
            0 0 0 11px rgba($green, 0.3);
    }

    100% {
        box-shadow: 0 0 0 7px rgba($green, 0.3),
            0 0 0 9px rgba($green, 0.3),
            0 0 0 11px rgba($green, 0.3),
            0 0 0 13px rgba($green, 0);
    }
}

//sparkle

@-moz-keyframes sparkle {
    100% {
        fill: powderblue;
    }
}

@-webkit-keyframes sparkle {
    100% {
        fill: powderblue;
    }
}

@keyframes sparkle {
    100% {
        fill: powderblue;
    }
}

/*bulb*/
@-moz-keyframes bulb {
    100% {
        fill: $light-yellow;
    }
}

@-webkit-keyframes bulb {
    100% {
        fill: $light-yellow;
    }
}

@keyframes bulb {
    100% {
        fill: $light-yellow;
    }
}


//image scroll
@keyframes desktopscroll {

    10% {
        -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
        transform: translateY(0%);
    }

    50% {
        -webkit-transform: translateY(calc(-50% + 257px));
        -ms-transform: translateY(calc(-50% + 257px));
        transform: translateY(calc(-50% + 257px));
    }

    100% {
        -webkit-transform: translateY(calc(-100% + 514px));
        -ms-transform: translateY(calc(-100% + 514px));
        transform: translateY(calc(-100% + 514px));
    }

}

@-webkit-keyframes scrollCoverBrowser {
    10% {
        -webkit-transform: translateY(0%);
        -ms-transform: translateY(0%);
        transform: translateY(0%);
    }

    50% {
        -webkit-transform: translateY(calc(-50% + 250px));
        -ms-transform: translateY(calc(-50% + 250px));
        transform: translateY(calc(-50% + 250px));
    }

    100% {
        -webkit-transform: translateY(calc(-100% + 900px));
        -ms-transform: translateY(calc(-100% + 900px));
        transform: translateY(calc(-100% + 900px));
    }

}

/*topToBottom Animation*/
@-moz-keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -moz-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -moz-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -webkit-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -ms-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -ms-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -webkit-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes c-fill {

    0%,
    100% {
        fill: $orange;
    }

    50% {
        fill: $green;
    }
}

//fade in down
@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

//fadeInUp
@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

//progress
@-webkit-keyframes ProgressDots {
    from {
        width: 0px;
    }

    to {
        width: 100%;
    }
}

@keyframes ProgressDots {
    from {
        width: 0px;
    }

    to {
        width: 100%;
    }
}

//blink
@keyframes blink {
    90% {
        transform: none;
        animation-timing-function: ease-in;
    }

    93% {
        transform: translate(0px) scaleY(0.8)
    }

    100% {
        animation-timing-function: ease-out;
    }
}

//rotate
@-webkit-keyframes rotation {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform-origin: center center;
    }

    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform-origin: center center;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform-origin: center center;
    }

    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform-origin: center center;
    }
}

//rotate and zoom
@-webkit-keyframes rotation-zoom {
    from {
        transform: rotate(0deg) scale(0);
        -webkit-transform: rotate(0deg) scale(0);
        transform-origin: center center;
        transition: all 1s ease-in-out;
    }

    to {
        transform: rotate(360deg) scale(1);
        -webkit-transform: rotate(360deg) scale(1);
        transform-origin: center center;
        transition: all 1s ease-in-out;
    }
}

@keyframes rotation-zoom {
    from {
        transform: rotate(0deg) scale(0);
        -webkit-transform: rotate(0deg) scale(0);
        transform-origin: center center;
        transition: all 1s ease-in-out;
    }

    to {
        transform: rotate(360deg) scale(1);
        -webkit-transform: rotate(360deg) scale(1);
        transform-origin: center center;
        transition: all 1s ease-in-out;
    }
}

//slide keyframe
//slidedown
@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

//slideleft
@-webkit-keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

//slideright
@-webkit-keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

//slideup
@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes animate-arrow-1 {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    50%,
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@-webkit-keyframes animate-arrow-1 {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    50%,
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@-moz-keyframes animate-arrow-1 {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    50%,
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes animate-arrow-2 {
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }

    80%,
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

//filtered
@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0.0;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }

}


@keyframes bounce {
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(0);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(0);
    }

    80% {
        transform: translateY(-10px) rotate(0);
    }

    100% {
        transform: translateY(0) rotate(0);
    }

}
/* Buzz Out */
@-webkit-keyframes hvr-buzz-out {
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }

    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }

    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }

    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }

    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }

    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }

    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }

    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }

    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0);
    }

    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0);
    }
}

@keyframes hvr-buzz-out {
    10% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }

    20% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }

    30% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }

    40% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }

    50% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }

    60% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }

    70% {
        -webkit-transform: translateX(2px) rotate(1deg);
        transform: translateX(2px) rotate(1deg);
    }

    80% {
        -webkit-transform: translateX(-2px) rotate(-1deg);
        transform: translateX(-2px) rotate(-1deg);
    }

    90% {
        -webkit-transform: translateX(1px) rotate(0);
        transform: translateX(1px) rotate(0);
    }

    100% {
        -webkit-transform: translateX(-1px) rotate(0);
        transform: translateX(-1px) rotate(0);
    }
}

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
  /**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  //border rotate animation
  @keyframes rotateThis {
	from {
	  transform: rotate(0deg) scale(1);
	}
	
	to {
	  transform: rotate(360deg) scale(1);
	}
  }
  
  @keyframes psycho {
	0% {
	  transform: rotate(0deg) scale(1) translate(0, 0);
	}
	
	33% {
	  transform: rotate(360deg) scale(1) translate(5px, 5px);
	}
	
	66% {
	  transform: rotate(720deg) scale(1) translate(-5px, -5px);
	}
	
	100% {
	  transform: rotate(1080deg) scale(1) translate(0, 0);
	}
  }

  /**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
              transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
              transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
              transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  
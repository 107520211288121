
.contact-wrapper{
	@include background;
	position: relative;
	@include respond-to(small){
	min-height: calc(100vh - 100px);	
	}
}
.contact-block{
	width: 100%;
	//height: 100%;
	position: relative;
	padding-bottom: 50px;
	@include respond-to(large){
		position: absolute;
		top:50%;
		padding-top: 100px;
		@include transform(translateY(-50%));
	}
	.container{
		position: relative;
		padding-top: 100px;
		z-index: 1;
		@include respond-to(large){
			padding-top: 0;
		}
		@include respond-to(mid-large){
			max-width: 1300px;
			
		}
	}
	.contact-info{
		
	}
	h2{
		@include rhythm(30, 40);
		color:$dark-blue;
		margin:100px 0 50px 0;
		span{
			background: white;
			color: $orange;
			padding:0 10px;
			@include border-radius(5px);
		}
		@include respond-to(small){
			@include rhythm(80, 90);
		}
	}
	.contact-row{
		margin:40px 0;
		position: relative;
		h3{
			@include rhythm(24, 36);
			color: $dark-blue;
			@include font-weight(regular);
		}
		h4{
			@include rhythm(20,24);
		@include font-weight(medium);
		color:$dark-blue;
		text-decoration: underline;
		}
		a{
			@include rhythm($regular, 24);
			color:$orange;
			letter-spacing: 1px;
			@include font-weight(regular);
			&:hover{
				color: $lemon-green;
				text-decoration: none;
			}
		}
		p{
			color:$body-color;
			margin: 0 0 5px 0;
		}
		&.cw-address{
			a{
				@include rhythm($regular, 30);
				@include font-weight(regular);
			}
		}
		.map-anchor{
			width: 34px;
			height: 60px;
			display: inline-block;
			position: relative;
			top:25px;
			@include respond-to(large){
				position: absolute;
			right: 15%;
			top:70%;
			}
			&:hover{
				
				.pin{
					top:-10px;
				}
			}
		}
		.pin{
			background: url('../images/cloudymap-pin.svg') no-repeat center;
			width: 30px;
			height: 34px;
		  animation-name: bounce;
		  animation-fill-mode: both;
		  animation-duration: 1s;
		  @include transition(all 0.5s ease-in-out 0s);
			@include respond-to(small-xs){
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
			}
		}
			
		.pulse{
			background: rgba(white,0.2);
		  border-radius: 50%;
		  height: 10px;
		  width: 10px;
		  position: absolute;
		  left: 50%;
		  top: 25%;
		  margin: 11px 0 0 -4px;
		  transform: rotateX(55deg);
		  z-index: -2;
		  &:after{
			content: "";
			border-radius: 50%;
			height: 30px;
			width: 30px;
			position: absolute;
			margin :-10px 0 0 -14px;
			animation: pulsate 1s ease-out;
			animation-iteration-count: infinite;
			opacity: 0.0;
			box-shadow: 0 0 1px 2px #89849b;
			animation-delay: 1.5s;  
		  }
			
		}
	}
	.footer-block{
		//padding-top: 50px;
		&.contact-social{
			width: 100%;
			float: left;
			ul{
				padding:0;
				margin-bottom: 10px;
				text-align: left;
				li{
					display:inline-block;
					margin:0 5px;
					background:$orange;
						padding:10px;
						@include border-radius(100%);
						@include respond-to(small){
							margin:0 5px 15px 5px;
						}
						@include respond-to(large){
							margin:0 10px;
						}
					.svg{
						width:20px;
						height:22px;
					  }
					.job-white{
						width:20px;
					}
				}
			}
		}
		p{
			color: white;
		}
	}
}
.social-foot-wrap{
	@include respond-to(mid-large){
		max-width: 1300px;
	}
	.copyright{
		width: 100%;
		float: left;
		border-top:none;
		padding:0;
		margin:10px 0 0 0;
		@include respond-to(medium){
			margin:20px 0 0 0;
		}
	}
	.footer-block{
		//padding-top: 50px;
		&.contact-social{
			ul{
				padding:0;
				margin-bottom: 10px;
				text-align: left;
				li{
					display:inline-block;
					margin:0 5px;
					background:$orange;
						padding:10px;
						@include border-radius(100%);
						@include respond-to(small){
							margin:0 5px 15px 5px;
						}
						@include respond-to(large){
							margin:0 10px;
						}
						&:first-child{
							margin-left: 0;
						}
					.svg{
						width:20px;
						height:22px;
					  }
					.job-white{
						width:20px;
					}
				}
			}
		}
		p{
			color: white;
		}
	}
}
//header
header{
	padding:15px 0 0 0;
	@include respond-to(small){
	padding:20px 0;	
	}
	&.light{
		//@include transition(all 0.5s ease-in-out);
		.cf-logo{
			svg{
				path{
					fill:$orange;
				}
			}
		}
	}
	&.dark{
		//@include transition(all 0.5s ease-in-out);
		.cf-logo{
			svg{
				path{
					fill:white;
				}
			}
		}
	}
	.container-fliud{
		position: relative;
		padding-left:15px;
		padding-right: 15px;
		@include respond-to(large){
			padding-left:5%;
			padding-right: 5%;
		}
	}
}
#logo{
	position: absolute;
	left:15px;
	z-index: 9;
	@include respond-to(small){
		position: fixed;
		left: inherit;
	}
}
.logo-slidedown{
	animation:fadeInDown 1s alternate;
}
.slide-in-top {
	-webkit-animation: slide-in-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.c-block{
	width:24px;
	float: left;
}
.l-block{
	width:14px;
	float: left;
	margin:0 -1px 0 3px;
}
.o-block{
	width:23px;
	float: left;
	margin-top: 6px;
}
.u-block{
	width:21px;
	float: left;
	margin-top: 6px;
	margin-left:2px;
}
.d-block{
	width:23px;
	float: left;
	margin-left:3px;
}
.y-block{
	width:22px;
	float: left;
	margin-left:4px;
	position: relative;
	top:9px;
}
.f-block{
	width:19px;
	float: left;
	margin-left:2px;
}
.oo-block{
	width:23px;
	float: left;
	margin-top: 6px;
}
.x-block{
	width:26px;
	float: left;
	margin-top: 6px;
}
.cf-logo{
	display: inline-block;
	svg{
		path{
			fill: white;
		}
	}
	&.cf-icon-block{
		@include respond-to(767px){
			transform: translateX(0px) translateZ(0px)!important;
			-webit-transform: translateX(0px) translateZ(0px)!important;
			opacity: 1!important;
			width:40px;
		}
		width:60px;
		position: absolute;
		left:0;
		@include transition(all 0.3s ease-in-out 0s);
		-webkit-transition-delay: 1s;
		-moz-transition-delay: 1s;
		-ms-transition-delay: 1s;
		transition-delay: 1s;
		&.join{
			@include transition(all 0.2s ease-in-out 0s);
			-webkit-transition-delay: 0s;
			-moz-transition-delay: 0s;
			-ms-transition-delay: 0s;
			transition-delay: 0s;
		}
	}
	&.cf-cloudy-block{
		@include respond-to(767px){
			display: none!important;
		}
		div{
			@include transition(all 0.3s ease-in-out);
			&:nth-child(1){
				-moz-transition-delay: 0.1s;
				-webkit-transition-delay: 0.1s;
				-ms-transition-delay: 0.1s;
				transition-delay: 0.1s;
			}
			&:nth-child(2){
				-webkit-transition-delay: 0.2s;
				-moz-transition-delay: 0.2s;
				-ms-transition-delay: 0.2s;
				transition-delay: 0.2s;
			}
			&:nth-child(3){
				-webkit-transition-delay: 0.3s;
				-moz-transition-delay: 0.3s;
				-ms-transition-delay: 0.3s;
				transition-delay: 0.3s;
			}
			&:nth-child(4){
				-webkit-transition-delay: 0.4s;
				-moz-transition-delay: 0.4s;
				-ms-transition-delay: 0.4s;
				transition-delay: 0.4s;
			}
			&:nth-child(5){
				-webkit-transition-delay: 0.5s;
				-moz-transition-delay: 0.5s;
				-ms-transition-delay: 0.5s;
				transition-delay: 0.5s;
			}
			&:nth-child(6){
				-webkit-transition-delay: 0.6s;
				-moz-transition-delay: 0.6s;
				-ms-transition-delay: 0.6s;
				transition-delay: 0.6s;
			}
			&:nth-child(7){
				-webkit-transition-delay: 0.7s;
				-moz-transition-delay: 0.7s;
				-ms-transition-delay: 0.7s;
				transition-delay: 0.7s;
			}
			&:nth-child(8){
				-webkit-transition-delay: 0.8s;
				-moz-transition-delay: 0.8s;
				-ms-transition-delay: 0.8s;
				transition-delay: 0.8s;
			}
			&:nth-child(9){
				-webkit-transition-delay: 0.9s;
				-moz-transition-delay: 0.9s;
				-ms-transition-delay: 0.9s;
				transition-delay: 0.9s;
			}
		}
		svg{
		margin-top:5px;
			path, polygon{
				fill: #ffffff;
			}
		}
	}
}
.cf-light{
	.cf-logo{
		display: inline-block;
		svg{
			path{
				fill: $orange;
			}
		}
		&.cf-cloudy-block{
			svg{
			margin-top:5px;
				path, polygon{
					fill: $blue;
				}
			}
		}
	}
}
.cf-dark{
	.cf-logo{
		display: inline-block;
		svg{
			path{
				fill: $orange;
			}
		}
		&.cf-cloudy-block{
			svg{
			margin-top:5px;
				path, polygon{
					fill: white;
				}
			}
		}
	}
}
	header{
		position:absolute;
	//z-index: 9;
	left:0;
	top:0;
	right:0;
	.container{
		@include respond-to(x-large){
			max-width:1300px;
		}
	}
	}
// }
.fixbody{
	header{
		#logo{
			//position: relative;
			z-index: 9;
			svg{
				path, polygon{
					fill:$orange;
				}
				.cf_icon{
					display: block;
				}
				.cf_text{
					display: none;
				}
			}
		}
	}
}
//logo hidden
.casestudy-detail{
	#logo{
		display: none;
	}
}

.index-fallback{
    background-image: url('../images/home-fallback.jpg');
    @include background;
    position: relative;
    &:before{
        @include pseudo;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        background:rgba(black,0.8);
    }
}
.about-wrap {
    padding:100px 0;
  
    .container{
        @include respond-to(mid-large){
            max-width: 1300px;
        }
    }
    .cf-info-front{
        h2{
            @include rhythm(20, 30);
            @include font-weight(book);
            color: $dark-blue;
            margin-bottom: 20px;
            span{
                color: $orange;
                @include font-weight(medium);
            }
        }
        p{
            @include rhythm(22,35);
            @include font-weight(light);
            @include respond-to(small){
              @include rhythm(35,55);  
            }
        }
    }
    &.about-inner {
        background: white;
    }
}

.cf-info {
    padding: 50px 15px 0 15px;
    @include respond-to(small) {
        padding: 50px 0 0 0;
    }

    @include respond-to(medium) {
        padding: 120px 0 0 15px;
    }

    small {
        @include rhythm($small, 18);
        color: $orange;
        text-transform: uppercase;
    }

    h2 {
        font-family: $stolzl;
        @include rhythm(20, 24);
        color: $dark-blue;
        margin: 5px 0 40px 0;
        span {
            @include font-weight(book);
            strong{
                color: $orange;
                text-decoration: underline;
            @include font-weight(bold);
            text-transform: uppercase;
            }
        }
        @include respond-to(small-xs) {
            @include rhythm(24, 28);
        }
        @include respond-to(small) {
            @include rhythm(30, 36);
        }
    }

    &.about-info {
        text-align: left;
        padding:0 15px;
        h2 {
            margin-bottom: 40px;
            @include rhythm(30, 40);
            @include respond-to(small){
                @include rhythm(50, 60);
            }
        }
        p{
            @include rhythm(18, 30);
        }
        .tagline {
            @include rhythm(18, 24);
            margin-bottom: 50px;

        }
    }
    &.service-info{
        @include respond-to(small){
          padding:0;  
        }
        h2 {
            margin-bottom: 20px;
            @include rhythm(45, 45);
            @include respond-to(medium){
                @include rhythm(80, 80);
            }
            span{
                display: block;
                @include rhythm($x-large, 50);
            }
        }
        p{
            @include rhythm(18, 26);
            margin-bottom: 30px;
            @include respond-to(small){
                @include rhythm(20, 30);
            }
        }
    }

    .info-style {
        padding-left: 0;
        position: relative;
        padding-bottom: 25px;
        .button-animi{
            @include respond-to(large){
                text-align: left;
                margin-right: 2px;
            }
        }
    }
}
.cf-studio-slide{
    @include respond-to(large){
        padding-right:75px;
    }
    .gif-block{
        margin-top:-30px;
        img{
            max-width: 100%;
        }
    }
    .studio-text{
        @include rhythm(80,80);
        color:$dark-blue;
        @include font-weight(bold);
        position: relative;
        top:-50px;
        right:-35%;
    }
}
//services
.service-wrap {
    position: relative;
    padding: 50px 0 100px 0;
    @include respond-to(large){
        padding: 0 0 50px 0;
    }
    &.service-wrap-home{
        
        @include respond-to(large){
          height: 100vh;  
        }
        .service-info{
            text-align: left;
            padding:0 15px;
            
            @include respond-to(large){
             position: relative;
            top:50%;
            @include transform(translateY(-50%));   
            }
            .service-title{
               small{
                @include rhythm(30,30);
                @include font-weight(light);
            } 
            h2{
                @include rhythm(40, 50);
                margin-bottom: 25px;
                @include respond-to(small){
                    @include rhythm(80, 80);
                    margin-bottom: 0;
                }
            }
            }
            p{
                margin-bottom: 30px;
                @include respond-to(small){
                    @include rhythm(20,30);
                }
                strong{
                    color: $dark-blue;
                }
            }
        }
    }
    @include respond-to(767px) {
        overflow: hidden;
    }

    .service-info{
        text-align: center;
        padding-top: 50px;
        .service-title{
              small{
            @include rhythm(40,40);
            @include font-weight(medium);
        }
        }
        h2{
            @include rhythm(60,60);
            @include font-weight(medium);
            margin-bottom: 50px;
            span{
                color: $orange;
            }
        }
        p{
            @include respond-to(large){
                @include rhythm(22,34);
            }
            margin-bottom: 70px;
        }
        .triangle-big-shape{
           position: absolute;
            left:0%;
            top:0%;
            z-index: -1;   
            display: block;
            width:300px;
            @include respond-to(small){
                width: auto;
                top:-90%;
            }
            img{
                max-width: 100%;
            }
        }
        .triangle-big-shape2{
            display: none;
            position: absolute;
            left:70%;
            bottom: 20%;
            z-index: -1;
            @include respond-to(mid-large){
                display: block;
            }
        }
    }
}

.services-shapes {
    width: 96%;
    height: 550px;
    position: absolute;
    top: 25%;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: none;
    @include respond-to(small) {
        display: block;
        top: -15%;
        height: 550px;
        left:0;
        right: 0;
    }

    @include respond-to(large) {
        left: -2%;
        top: -30%;
        right: inherit;
    }

    @include respond-to(large) {
        left: inherit;
        top: -6%;
    }

    @include respond-to(xx-large) {
        width: 80%;
    }
}



.service-list {
    position: relative;
    small{
        @include rhythm(24,30);
        color: #292929;
    }
    ul{
        margin:20px 0 0 0;
        padding:0;
        list-style-type: none;
        li{
            @include rhythm(24,30);
            @include font-weight(medium);
            color: #292929;
            margin:20px 0;
           float: left;
            img{
                width:40px;
                margin-right: 10px;
            }
        }
    }
}

.service-block {
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 15px 0 20px 0;
    text-align: center;
    padding: 20px 10px;
    @include border-radius(5px);
    @include box-shadow(0px 0px 15px rgba(0, 0, 0, 0.2));
    @include transition(all 0.3s ease-in-out 0s);

    @include respond-to(small) {
        width: 47%;
    }

    @include respond-to(large) {
        //width: 320px;
        margin: 15px 0 20px 0;
        padding: 35px 20px;
    }

    @include respond-to(xx-large) {
        //width: 336px;
        margin: 15px 0 35px 0;
        padding-right: 5%;
    }

    &:nth-of-type(2n) {
        position: relative;

        @include respond-to(small) {
            margin-left: 5%;
            top: 30px;
        }

        @include respond-to(large) {
            margin-left: 20px;
        }

        @include respond-to(xx-large) {
            margin-left: 45px;
        }
    }

    h3 {
        @include rhythm($medium, 24);
        @include font-weight(book);
        color: white;
    }

    &.svc-1 {
        @include linearGradient(#10bfeb, #10b5eb)
    }

    &.svc-2 {
        @include linearGradient(#e810eb, #f82bfa);
    }

    &.svc-3 {
        @include linearGradient(#edaf1a, #fcc133);
    }

    &.svc-4 {
        @include linearGradient(#34bc0a, #298f09);
    }

    .svg {
        width: 100px;
        height: 60px;
        margin-bottom: 15px;
    }

}


//work with
.work-with {
    position: relative;
    padding: 50px 0;
    overflow: hidden;
    @include respond-to(large) {
        padding: 200px 0 150px 0;
    }
.container{
    @include respond-to(large){
        max-width: 1300px;
    }
}
    h2 {
        color: $dark-blue;
        margin: 20px 0;
    }


    .triangles {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        .svg {
            max-width: 100%;
            opacity: 0.03;
        }
    }
    .whowe {
        text-align: center;
        position: relative;
        margin-bottom: 20px;
        @include respond-to(small){
            margin-bottom: 80px;
        }
        @include respond-to(medium) {
            padding: 0 10%;
        }
    
    }
    .partner-list {
        text-align: center;
    }
    .partner {
        width:150px;
        text-align: center;
        display: inline-block;
        margin: 10px 0;
        padding: 20px;
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        @include respond-to(small){
            width:200px;
        }
        &:before {
            pointer-events: none;
            position: absolute;
            z-index: -1;
            content: '';
            top: 100%;
            left: 5%;
            height: 10px;
            width: 90%;
            opacity: 0.5;
            background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
            background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-property: transform, opacity;
            transition-property: transform, opacity;
            -webkit-transform: translateY(5px);
            transform: translateY(5px);
        }
        @include respond-to(small) {
            margin: 20px 50px;
            padding: 15px 10px 10px 10px;
        }
        @include respond-to(large) {
            //width: 220px;
            padding: 20px 15px 10px 15px;
        }
    
        img {
            max-width: 100%;
        }
    }
}

div#parallax-bg-4 {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin-top: 92%;

    @include respond-to(xx-large) {
        margin-top: 110%;
    }
}


.arrow-cog {
    position: absolute;
    top: 40%;
    right: 0;
    width: 100%;
    overflow: hidden;

    @include respond-to(small) {
        top: 20%;
    }

    svg {
        height: 300px;
        margin-left: -150px;

        @include respond-to(small) {
            height: 500px;
        }

        @include respond-to(medium) {
            height: auto;
        }
    }

    path {
        fill: $blue;
    }

    .dashes {
        animation: bulb 5s infinite;
    }

    .smalldot {
        animation: bulb 5s infinite;
    }

    .circle-border {
        -webkit-animation: sparkle 7s linear infinite;
        animation: sparkle 7s linear infinite;
    }

    .small-circle {
        animation: nudge 5s infinite;
    }

    .small-circle1 {
        animation: bulb 5s infinite;
    }

    .small-circle2 {
        -webkit-animation: sparkle 5s infinite;
        animation: sparkle 5s infinite;
    }

}

//testimonials

.testimonial-wrap {
    background: $orange;
    position: relative;
    @include respond-to(medium){
        height: 100vh;
    }
    h2{
        @include rhythm(40,40);
        @include font-weight(bold);
        color: white;
       margin-bottom: 40px;
       @include respond-to(small){
        @include rhythm(80,80);
       }
        @include respond-to(medium){
            @include rhythm(100,100);
            text-align: right;
            margin-bottom: 0;
        }
    }

    .testimonial-content {
        padding: 100px 0 50px 0;
        @include respond-to(medium){
          position: relative;
        top:50%;
        @include transform(translateY(-50%));  
        }
        .testimonial-title{
            position: relative;
        }
    }
}


.testimonial {
    position: relative;
    p {
        @include rhythm(16, 26);
        color: white;
    }

    .testimonial-post {

        position: relative;

        @include respond-to(small) {
            margin: 0px 0 40px 0;
        }

        .slick-list {
            padding: 0 !important;
        }

        button {
            position: absolute;
            border: none;

            &.slick-prev {
                left: -50px;
                top: 20px;
                opacity: 0.5;
                background: url('../images/left-arrow.svg') no-repeat;
                display: block;
                width: 50px;
                height: 50px;
                font-size: 0;

                @include respond-to(medium) {
                    left: -170px;
                }

                &:hover {
                    opacity: 1;
                }
            }

            &.slick-next {
                right: -50px;
                top: 20px;
                opacity: 0.5;
                background: url('../images/right-arrow.svg') no-repeat;
                display: block;
                width: 50px;
                height: 50px;
                font-size: 0;

                @include respond-to(medium) {
                    right: -170px;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }
        .company-name {
            @include rhythm(30, 30);
            @include font-weight(medium);
            display: block;
            color: white;
            margin: 20px 0 20px 0;
        }
        .client-name {
            @include rhythm(18, 20);
            @include font-weight(medium);
            display: block;
            color: black;
            margin: 50px 0 5px 0;
        }

        .designation {
            @include rhythm(18, 24);
            font-weight: $regular-weight;
            opacity: 0.9;
            color: rgba(black,0.9);
        }
    }

    .slick-dots {
        margin: 25px 0 0 0;
        padding: 0;
        list-style-type: none;

        li {
            list-style-type: none;
            display: inline-block;
            margin: 5px;

            button {
                width: 15px;
                height: 15px;
                position: relative;
                @include rhythm(0, 0);
                color: white;
                @include border-radius(100%);
                opacity: 0.5;
            }

            &.slick-active {
                button {
                    opacity: 1;
                }
            }
        }
    }
}
.reveal-block {
    position: relative;
    background:white;
  }
  
  .reveal-block::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $orange;
    transition-property: transform;
    transition-duration: 0.5s;
  }
.reveal-block.right::before {
    transform: scaleX(0);
    transform-origin: 0% 100%;
  }
  
  .reveal-block.right.aos-animate::before {
    transform: scaleX(1);
  }